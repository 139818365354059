export const MIME_TYPES = {
  MP4: 'video/mp4',
  MPEG: 'video/mpeg',
  AVI: 'video/x-msvideo',
  OGV: 'video/ogg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  BMP: 'image/bmp',
  APNG: 'image/apng',
  AVIF: 'image/avif',
  ICO: 'image/vnd.microsoft.icon',
};
