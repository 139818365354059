import z from "zod";

export interface I_AD_CustomerAccountDetail {
  id: string;
  account: {
    id: string;
    email: string | null;
    fullName: string;
    dateOfBirth: string | null;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    role: string;
    avatar: string;
    gender: string;
    expoPushToken: string;
    isActive: boolean;
    isVerified: boolean;
    isCompany: boolean;
    applicationSignature: string;
    freelancerSignature: string | null;
  };
  customerRank: string;
  memberPoint: number;
}
export const SCHEMA_AD_CustomerAccountDetail = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    email: z.string().nullable(),
    fullName: z.string(),
    dateOfBirth: z.string().nullable(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    role: z.string(),
    avatar: z.string(),
    gender: z.string(),
    expoPushToken: z.string(),
    isActive: z.boolean(),
    isVerified: z.boolean(),
    isCompany: z.boolean(),
    applicationSignature: z.string(),
    freelancerSignature: z.string().nullable(),
  }),
  customerRank: z.string(),
  memberPoint: z.number(),
});

export interface I_AD_FreelancerAccountDetail {
  id: string;
  account: {
    id: string;
    email: null;
    fullName: string;
    dateOfBirth: string | null;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    role: string;
    avatar: string;
    gender: string;
    expoPushToken: string;
    isActive: boolean;
    isVerified: boolean;
    isCompany: boolean;
    applicationSignature: string;
    freelancerSignature: string;
  };
  rating: number;
  balance: number;
  systemBalance: number;
  orderCount: number;
  loveCount: number;
  positiveReviewCount: number;
  totalReviewCount: number;
  address: {
    id: string;
    lat: number;
    lon: number;
    addressLine: string;
    ward: string;
    district: string;
    province: string;
    country: string;
  };
  identityNumber: string;
  identityCardImage: string;
  identityCardImageBack: string;
  description: string;
  activeTime: string;
  isVerified: boolean;
  companyName: null;
  taxIdentificationNumber: null;
}
export const SCHEMA_AD_FreelancerAccountDetail = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    email: z.string().nullable(),
    fullName: z.string(),
    dateOfBirth: z.string().nullable(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    role: z.string(),
    avatar: z.string(),
    gender: z.string(),
    expoPushToken: z.string(),
    isActive: z.boolean(),
    isVerified: z.boolean(),
    isCompany: z.boolean(),
    applicationSignature: z.string(),
    freelancerSignature: z.string(),
  }),
  rating: z.number(),
  balance: z.number(),
  systemBalance: z.number(),
  orderCount: z.number(),
  loveCount: z.number(),
  positiveReviewCount: z.number(),
  totalReviewCount: z.number(),
  address: z.object({
    id: z.string(),
    lat: z.number(),
    lon: z.number(),
    addressLine: z.string(),
    ward: z.string(),
    district: z.string(),
    province: z.string(),
    country: z.string(),
  }),
  identityNumber: z.string(),
  identityCardImage: z.string(),
  identityCardImageBack: z.string(),
  description: z.string(),
  activeTime: z.string(),
  isVerified: z.boolean(),
  companyName: z.null(),
  taxIdentificationNumber: z.null(),
});

export interface I_AD_BusinessAccountDetail {
  id: string;
  account: {
    id: string;
    email: null;
    fullName: string;
    dateOfBirth: string | null;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    role: string;
    avatar: string;
    gender: string;
    expoPushToken: string;
    isActive: boolean;
    isVerified: boolean;
    isCompany: boolean;
    applicationSignature: string;
    freelancerSignature: string;
  };
  rating: number;
  balance: number;
  systemBalance: number;
  orderCount: number;
  loveCount: number;
  positiveReviewCount: number;
  totalReviewCount: number;
  address: {
    id: string;
    lat: number;
    lon: number;
    addressLine: string;
    ward: string;
    district: string;
    province: string;
    country: string;
  };
  identityNumber: string;
  identityCardImage: string;
  identityCardImageBack: string;
  description: string;
  activeTime: string;
  isVerified: boolean;
  companyName: string;
  taxIdentificationNumber: string;
}
export const SCHEMA_AD_BusinessAccountDetail = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    email: z.string().nullable(),
    fullName: z.string(),
    dateOfBirth: z.string().nullable(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    role: z.string(),
    avatar: z.string(),
    gender: z.string(),
    expoPushToken: z.string(),
    isActive: z.boolean(),
    isVerified: z.boolean(),
    isCompany: z.boolean(),
    applicationSignature: z.string(),
    freelancerSignature: z.string(),
  }),
  rating: z.number(),
  balance: z.number(),
  systemBalance: z.number(),
  orderCount: z.number(),
  loveCount: z.number(),
  positiveReviewCount: z.number(),
  totalReviewCount: z.number(),
  address: z.object({
    id: z.string(),
    lat: z.number(),
    lon: z.number(),
    addressLine: z.string(),
    ward: z.string(),
    district: z.string(),
    province: z.string(),
    country: z.string(),
  }),
  identityNumber: z.string(),
  identityCardImage: z.string(),
  identityCardImageBack: z.string(),
  description: z.string(),
  activeTime: z.string(),
  isVerified: z.boolean(),
  companyName: z.string(),
  taxIdentificationNumber: z.string(),
});

export interface I_AD_FreelancerApproveRequest {
  id: string;
  account: {
    id: string;
    email: string | null;
    fullName: string;
    gender: string;
    avatar: string;
    dateOfBirth: string;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    isCompany: boolean;
  };
  address: {
    id: string;
    lat: number;
    lon: number;
    addressLine: string;
    ward: string;
    district: string;
    province: string;
    country: string;
  };
  identityNumber: string;
  identityCardImage: string;
  identityCardImageBack: string;
  companyName: null;
  taxIdentificationNumber: null;
}
export const SCHEMA_AD_FreelancerApproveRequest = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    email: z.string().nullable(),
    fullName: z.string(),
    gender: z.string(),
    avatar: z.string(),
    dateOfBirth: z.string(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    isCompany: z.boolean(),
  }),
  address: z.object({
    id: z.string(),
    lat: z.number(),
    lon: z.number(),
    addressLine: z.string(),
    ward: z.string(),
    district: z.string(),
    province: z.string(),
    country: z.string(),
  }),
  identityNumber: z.string(),
  identityCardImage: z.string(),
  identityCardImageBack: z.string(),
  companyName: z.null(),
  taxIdentificationNumber: z.null(),
});

export interface I_AD_BusinessApproveRequest {
  id: string;
  account: {
    id: string;
    email: string | null;
    fullName: string;
    gender: string;
    avatar: string;
    dateOfBirth: string;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    isCompany: boolean;
  };
  address: {
    id: string;
    lat: number;
    lon: number;
    addressLine: string;
    ward: string;
    district: string;
    province: string;
    country: string;
  };
  identityNumber: string;
  identityCardImage: string;
  identityCardImageBack: string;
  companyName: string;
  taxIdentificationNumber: string;
}
export const SCHEMA_AD_BusinessApproveRequest = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    email: z.string().nullable(),
    fullName: z.string(),
    gender: z.string(),
    avatar: z.string(),
    dateOfBirth: z.string(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    isCompany: z.boolean(),
  }),
  address: z.object({
    id: z.string(),
    lat: z.number(),
    lon: z.number(),
    addressLine: z.string(),
    ward: z.string(),
    district: z.string(),
    province: z.string(),
    country: z.string(),
  }),
  identityNumber: z.string(),
  identityCardImage: z.string(),
  identityCardImageBack: z.string(),
  companyName: z.string(),
  taxIdentificationNumber: z.string(),
});

export interface I_AD_AdminAccountDetail {
  id: string;
  account: {
    id: string;
    fullName: string;
    avatar: string;
    dateOfBirth: string;
    countryCode: string;
    phone: string;
    combinedPhone: string;
    role: string;
    gender: string;
    expoPushToken: string;
    applicationSignature: string;
    freelancerSignature: string;
    isCompany: boolean;
  };
}
export const SCHEMA_AD_AdminAccountDetail = z.object({
  id: z.string(),
  account: z.object({
    id: z.string(),
    fullName: z.string(),
    avatar: z.string(),
    dateOfBirth: z.string(),
    countryCode: z.string(),
    phone: z.string(),
    combinedPhone: z.string(),
    role: z.string(),
    gender: z.string(),
    expoPushToken: z.string(),
    applicationSignature: z.string(),
    freelancerSignature: z.string(),
    isCompany: z.boolean(),
  }),
});
