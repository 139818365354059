/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { CellProps, Column } from "react-table";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import CustomTable from "./CustomTable";
import { CommonLoader } from "@presentational/atoms/Loading";
import { I_AD_CustomerAccountDetail } from "@business-layer/services/adminEntities/accounts";
import {
  useAdGetAllCustomer,
  useBanAccount,
  useUnbanAccount,
} from "@business-layer/business-logic/lib/admin";
import { ad_getAllCustomerResponseType } from "@business-layer/services";

const PAGE_SIZE = 5;
const normalizeString = (str: string) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const ManageCustomer = () => {
  const { onBan } = useBanAccount();
  const { onUnBan } = useUnbanAccount();
  const toast = useToast();
  const [pageCurrent, setPageCurrent] = useState(0);
  const {
    data: rawData,
    refetch,
    isRefetching,
  } = useAdGetAllCustomer(pageCurrent + 1, PAGE_SIZE);
  const [customerList, setCustomerList] = useState<
    ad_getAllCustomerResponseType | undefined
  >(undefined);

  useEffect(() => {
    setCustomerList(rawData);
  }, [rawData]);
  useEffect(() => {
    if (typeof rawData !== "undefined") {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent]);

  const [searchQuery, setSearchQuery] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [state, setState] = useState({
    selectedRow: null,
    selectedCustomer: null as I_AD_CustomerAccountDetail | null,
    actionType: "",
  });
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const filteredCustomerList = useMemo(() => {
    const normalizedQuery = normalizeString(searchQuery);
    return customerList
      ? customerList.data.filter(
          (customer) =>
            normalizeString(customer.account.fullName).includes(
              normalizedQuery
            ) || customer.id.includes(normalizedQuery)
        )
      : null;
  }, [customerList, searchQuery]);

  const columns: Column<I_AD_CustomerAccountDetail>[] = useMemo(
    () => [
      {
        Header: "Fullname",
        accessor: (row) => row.account.fullName,
        width: "20%",
        Cell: ({ row }: CellProps<I_AD_CustomerAccountDetail>) => (
          <Text fontSize={"0.65rem"} whiteSpace={"normal"}>
            {row.original.account.fullName}
          </Text>
        ),
      },
      {
        Header: "Gender",
        accessor: (row) => row.account.gender,
        width: "15%",
      },
      {
        Header: "Date of birth",
        accessor: (row) => row.account.dateOfBirth,
        width: "15%",
        Cell: ({ row }: CellProps<I_AD_CustomerAccountDetail>) => (
          <Text fontSize={"0.65rem"} whiteSpace={"normal"}>
            {row.original.account.dateOfBirth ?? "Chưa cập nhật"}
          </Text>
        ),
      },
      {
        Header: "Contact",
        accessor: (row) => row.account.combinedPhone,
        width: "20%",
        Cell: ({ row }: CellProps<I_AD_CustomerAccountDetail>) => (
          <Text fontSize={"0.65rem"} whiteSpace={"normal"}>
            (+{row.original.account.countryCode}) {row.original.account.phone}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: (row) => row.account.isActive,
        width: "15%",
        Cell: ({ row }: CellProps<I_AD_CustomerAccountDetail>) => (
          <Text
            color={row.original.account.isActive ? "dt_primary" : "dt_rose"}
            fontSize={"0.65rem"}
            fontWeight={"bold"}
          >
            <i
              className={
                row.original.account.isActive
                  ? "fi fi-sr-shield-check"
                  : "fi fi-sr-shield-exclamation"
              }
            />{" "}
            {row.original.account.isActive ? "Normal" : "Banned"}
          </Text>
        ),
      },
      {
        Header: "",
        accessor: "id",
        width: "15%",
        Cell: ({ row }: CellProps<I_AD_CustomerAccountDetail>) => (
          <Menu>
            <MenuButton
              as={Button}
              background={"transparent"}
              onClick={() => handleMenuOpen(row)}
            >
              <i className="fi fi-br-angle-small-down"></i>
            </MenuButton>
            <MenuList>
              <MenuItem
                display="flex"
                justifyContent="center"
                onClick={() => {
                  handleOpenConfirmBox(
                    row.original,
                    row.original.account.isActive ? "Ban" : "Unban"
                  );
                }}
              >
                <Text
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                >
                  {row.original.account.isActive
                    ? "Ban this customer"
                    : "Unban this customer"}
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
  );

  const handleMenuOpen = (index: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: index === prevState.selectedRow ? null : index,
    }));
  };

  const handleOpenConfirmBox = (
    customer: I_AD_CustomerAccountDetail,
    type: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedCustomer: customer,
      actionType: type,
    }));
    setIsAlertOpen(true);
  };

  const handleConfirmAction = async () => {
    const { actionType, selectedCustomer } = state;
    if (!selectedCustomer || (actionType !== "Ban" && actionType !== "Unban")) {
      return;
    }
    const isBanned = actionType === "Ban";
    const promise = isBanned
      ? onBan(selectedCustomer.id)
      : onUnBan(selectedCustomer.id);

    const toastConfig = {
      success: {
        title: isBanned
          ? "Cấm tài khoản thành công"
          : "Bỏ cấm tài khoản thành công",
      },
      error: {
        title: "Mé lỗi rồi!",
        description: "Hãy thử lại sau hoặc liên hệ team dev nhé",
      },
      loading: {
        title: "Đang thực thi",
        description: "Vui lòng chờ 10 năm...",
      },
    };

    toast.promise(promise, toastConfig);

    setIsAlertOpen(false);
    setState((prevState) => ({
      ...prevState,
      selectedCompany: null,
    }));

    await promise;
    setCustomerList((prev) =>
      prev
        ? {
            ...prev,
            data: prev.data.map((p) => {
              if (p.id === selectedCustomer.id) {
                return {
                  ...p,
                  account: {
                    ...p.account,
                    isActive: !isBanned,
                  },
                };
              }
              return p;
            }),
          }
        : prev
    );
  };

  useEffect(() => {
    console.log(customerList);
  }, [customerList]);

  return (
    <Box width={"100%"}>
      {filteredCustomerList && customerList && !isRefetching ? (
        <CustomTable
          columns={columns}
          data={filteredCustomerList}
          totalPage={customerList.totalPages}
          pageSize={PAGE_SIZE}
          pageCurrent={pageCurrent}
          onChangePage={(page) => {
            setPageCurrent(page);
          }}
          children={
            <Flex justifyContent={"space-between"}>
              <InputGroup mb={4} bg="dt_soft">
                <InputLeftElement pointerEvents="none" fontSize={"0.75rem"}>
                  <i className="fi fi-br-search"></i>
                </InputLeftElement>
                <Input
                  placeholder="Search by name or id..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    "::placeholder": {
                      color: "dt_gray",
                    },
                    border: "none",
                    fontSize: "0.75rem",
                  }}
                />
              </InputGroup>
            </Flex>
          }
        />
      ) : (
        <CommonLoader />
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        isCentered={true}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {state.actionType === "Ban"
                ? "Ban Account Customer"
                : "Unban Account Customer"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to{" "}
              {state.actionType === "Ban"
                ? "ban this customer"
                : "unban this customer"}
              ?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleConfirmAction} colorScheme="blue" ml={3}>
                {state.actionType === "Ban" ? "Ban" : "Unban"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
