import { useState } from "react";
import { ManageCustomer } from "@presentational/molecules/ManageCustomer";
import { ManageCompany } from "@presentational/molecules/ManageCompany";
import { ManageFreelancer } from "@presentational/molecules/ManageFreelancer";
import { Box, Button, Flex } from "@chakra-ui/react";

const accountType = ["Customer", "Freelancer", "Company"];
export const Accounts = () => {
  const [typeSelected, setTypeSelected] = useState("Customer");

  return (
    <Box padding={"1rem"} w={"100%"}>
      <Flex gap={4} marginBottom={5}>
        {accountType.map((accountType) => (
          <Button
            rounded={"full"}
            paddingY={4}
            paddingX={6}
            bg={accountType === typeSelected ? "dt_primary" : "dt_soft"}
            color={accountType === typeSelected ? "dt_white" : "dt_gray"}
            onClick={() => {
              setTypeSelected(accountType);
            }}
            fontSize={"0.75rem"}
            key={accountType}
          >
            {accountType}
          </Button>
        ))}
      </Flex>
      <Box flex={1}>
        {typeSelected === "Customer" ? <ManageCustomer /> : <></>}
        {typeSelected === "Freelancer" ? <ManageFreelancer /> : <></>}
        {typeSelected === "Company" ? <ManageCompany /> : <></>}
      </Box>
    </Box>
  );
};
