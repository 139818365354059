import {
  SCHEMA_AD_AdminAccountDetail,
  SCHEMA_AD_BusinessAccountDetail,
  SCHEMA_AD_BusinessApproveRequest,
  SCHEMA_AD_CustomerAccountDetail,
  SCHEMA_AD_FreelancerAccountDetail,
  SCHEMA_AD_FreelancerApproveRequest,
} from "@business-layer/services/adminEntities/accounts";
import z from "zod";

const approveFreelancerResponseSchema = z.object({
  message: z.string(),
});

const ad_getAllCustomerResponseSchema = z.object({
  data: z.array(SCHEMA_AD_CustomerAccountDetail),
  currentPage: z.number(),
  totalPages: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  hasPrevious: z.boolean(),
  hasNext: z.boolean(),
});
const ad_getCustomerDetailResponseSchema = SCHEMA_AD_CustomerAccountDetail;

const ad_getAllFreelancerResponseSchema = z.object({
  data: z.array(SCHEMA_AD_FreelancerAccountDetail),
  currentPage: z.number(),
  totalPages: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  hasPrevious: z.boolean(),
  hasNext: z.boolean(),
});
const ad_getFreelancerDetailResponseSchema = SCHEMA_AD_FreelancerAccountDetail;

const ad_getAllBusinessResponseSchema = z.object({
  data: z.array(SCHEMA_AD_BusinessAccountDetail),
  currentPage: z.number(),
  totalPages: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  hasPrevious: z.boolean(),
  hasNext: z.boolean(),
});

const ad_getAllFreelancerApproveRequestResponseSchema = z.object({
  data: z.array(SCHEMA_AD_FreelancerApproveRequest),
  currentPage: z.number(),
  totalPages: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  hasPrevious: z.boolean(),
  hasNext: z.boolean(),
});
const ad_getAllBusinessApproveRequestResponseSchema = z.object({
  data: z.array(SCHEMA_AD_BusinessApproveRequest),
  currentPage: z.number(),
  totalPages: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  hasPrevious: z.boolean(),
  hasNext: z.boolean(),
});

const ad_getAdminCurrentAccountDetailResponseSchema =
  SCHEMA_AD_AdminAccountDetail;
const ad_banAccountResponseSchema = z.any();
const ad_unBanAccountResponseSchema = z.any();
export {
  ad_getAdminCurrentAccountDetailResponseSchema,
  approveFreelancerResponseSchema,
  ad_getAllCustomerResponseSchema,
  ad_getAllFreelancerResponseSchema,
  ad_getCustomerDetailResponseSchema,
  ad_getFreelancerDetailResponseSchema,
  ad_getAllFreelancerApproveRequestResponseSchema,
  ad_getAllBusinessApproveRequestResponseSchema,
  ad_getAllBusinessResponseSchema,
  ad_banAccountResponseSchema,
  ad_unBanAccountResponseSchema,
};
