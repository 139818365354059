// PROXY_URL
const workerUrl = "https://ura-ads.phucdat4102.workers.dev";
const corsProxyUrl = workerUrl + "/cors-proxy/";

// ADMIN
export const getOverallAnalyticsEndpoint = "/api/v1/admin/overview";
export const approveFreelancerEndpoint = "/api/v1/auth/verify-freelance";
export const ad_getAllCustomerEndpoint = "/api/v1/admin/all-customer";
export const ad_getAllFreelancerEndpoint = "/api/v1/admin/all-freelancer";
export const ad_getAllBusinessEndpoint = "/api/v1/admin/all-company";
export const ad_getCustomerDetailEndpoint = "/api/v1/admin/detail-customer";
export const ad_getFreelancerDetailEndpoint = "/api/v1/admin/detail-freelancer";
export const ad_getAllFreelancerApproveRequestEndpoint =
  "/api/v1/admin/freelancer-request";
export const ad_getAllBusinessApproveRequestEndpoint =
  "/api/v1/admin/company-request";
export const ad_getAdminCurrentAccountDetailEndpoint = "/api/v1/admin/current";
export const ad_banAccountEndpoint = "/api/v1/account/ban";
export const ad_unBanAccountEndpoint = "/api/v1/account/unban";

// AUTH
export const loginEndpoint = "/api/v1/auth/login/admin";
export const verifyOtpEndpoint = "/api/v1/auth/verify-otp/register-login";
export const resendOtpEndpoint = "/api/v1/auth/resend-otp/register-login";
export const refreshTokenEndpoint = "/api/v1/auth/refresh-token";
export const registerFreelancerAccountEndpoint =
  "/api/v1/auth/register-freelance";

// SERVICE & CATEGORY
export const getAllCategoryEndpoint = "/api/v1/service-category";
export const getCategoryDetailEndpoint = "/api/v1/service-category/detail";
export const getServiceDetailEndpoint = "/api/v1/service-type/detail";
export const addMultipleServiceTypeEndpoint =
  "/api/v1/freelancer/add-multiple-service-type";
export const searchCategoriesAndServicesEndpoint =
  "/api/v1/service-type/search";

// GEOCODE
export const forwardGeocodingEndpoint = "/api/v1/geo-code/forward";
export const reverseGeocodingEndpoint = "/api/v1/geo-code/reverse";

// ORDER
export const createOrderEndpoint = "/api/v1/order";
export const getMatchingOrderDetailEndpoint = "/api/v1/order/customer-latest";
export const cancelOrderEndpoint = "/api/v1/order/customer-order";
export const selectFreelancerForOrderEndpoint = "/api/v1/order/order-price";
export const getOrderDetailEndpoint = "/api/v1/order/detail";
export const getFreelancerIncomingOrdersEndpoint =
  "/api/v1/freelancer/orders/incoming";
export const getMarketplaceOrdersEndpoint = "/api/v1/freelancer/orders";
export const getFreelancerAuctioningOrdersEndpoint =
  "/api/v1/order/freelancer-bidding";
export const getCustomerMatchingOrdersEndpoint =
  "/api/v1/freelancer/customer-bidding";
export const updateOrderServiceStatusEndpoint = "/api/v1/order/service-status";
export const getCustomerOnServiceOrdersEndpoint =
  "/api/v1/order/customer/on-service";
export const getCustomerOnMatchingOrdersEndpoint =
  "/api/v1/order/customer/on-matching";
export const getCustomerCompletedOrdersEndpoint =
  "/api/v1/order/customer/completed";
export const deleteServiceEndpoint = "/api/v1/service-type";
export const deleteCategoryEndpoint = "/api/v1/service-category";
export const createServiceEndpoint = "/api/v1/service-type";
export const createCategoryEndpoint = "/api/v1/service-category";

// SERVICE PROVEN
export const updateBeforeProvenEndpoint = "/api/v1/service-proven/before";
export const updateAfterProvenEndpoint = "/api/v1/service-proven/after";

// PAYMENT SERVICE
export const getAllFeesEndpoint = "/api/v1/payment/fees";
export const createTopUpLinkEndpoint = "/api/v1/payos/payment-link";
export const getWalletDetailEndpoint = "/api/v1/freelancer/wallet";

// RATING
export const customerRatingOrderEndpoint = "/api/v1/order/customer-review";
export const freelancerRatingOrderEndpoint = "/api/v1/order/freelancer-review";

// CHAT
export const getChatAllChatPreviewEndpoint = "/api/v1/account/chat/history";
export const getConversationDetailEndpoint = "/api/v1/account/chat/detail";

// FEEDBACK
export const reportErrorEndpoint =
  process.env.REACT_APP_REPORT_ERRORS_URL ?? "";
export const feedbackEndpoint = process.env.REACT_APP_FEEDBACKS_URL ?? "";
export const moreServiceFeedbacksEndpoint =
  process.env.REACT_APP_MORE_SERVICE_FEEDBACKS_URL ?? "";

// QUIZ
export const getQuizEndpoint = "/api/v1/freelancer/quiz/new-set";
export const getIsAddedSkillsAndDoneTestEndpoint =
  "/api/v1/freelancer/added-skills-and-done-test";

// ACCOUNT
export const getFreelancerPreviewDataEndpoint =
  "/api/v1/freelancer/short-detail";
export const getFreelancerDetailEndpoint = "/api/v1/freelancer/current";
export const getCustomerCurrentAccountDetailEndpoint =
  "/api/v1/account/current-account";
export const updateAccountDetailEndpoint = "/api/v1/account";
export const updatePushNotificationTokenEndpoint =
  "/api/v1/auth/expo-token/update";
export const getAllNotificationsEndpoint = "/api/v1/account/notification/all";

// SIRV
export const sirvBaseUrlTemplate = "https://api.sirv.com/v2";
export const connectSirvUrl = sirvBaseUrlTemplate + "/token";
export const uploadImageUrl = sirvBaseUrlTemplate + "/files/upload";
export const deleteImageUrl = sirvBaseUrlTemplate + "/files/delete";

// FCAPTCHA
export const fCaptchaSiteverifyUrl =
  corsProxyUrl + "https://api.friendlycaptcha.com/api/v1/siteverify";

// GOOGLE
export const googleGetUserInfoUrl =
  corsProxyUrl +
  "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses";
export const googleValidateTokenUrl =
  corsProxyUrl + "https://www.googleapis.com/oauth2/v3/tokeninfo";
// FACEBOOK
export const facebookGetFBAccessTokenUrl =
  corsProxyUrl + "https://graph.facebook.com/v17.0/oauth/access_token";
export const facebookGetFBUserInfoUrl =
  corsProxyUrl +
  "https://graph.facebook.com/me?fields=first_name,last_name,email";
// GITHUB
export const githubGetAccessTokenUrl =
  corsProxyUrl + "https://github.com/login/oauth/access_token";
export const githubValidateTokenUrl =
  corsProxyUrl + "https://api.github.com/authorizations/";
export const githubGetUserInfoUrl =
  corsProxyUrl + "https://api.github.com/user";
