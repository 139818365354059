/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, useRef, useEffect } from "react";
import { CellProps, Column } from "react-table";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
} from "@chakra-ui/react";
import { getCDNUrl } from "@utils/helpers";
import CustomTable from "@presentational/molecules/CustomTable";
import { CommonLoader } from "@presentational/atoms/Loading";
import { I_AD_BusinessApproveRequest } from "@business-layer/services/adminEntities/accounts";
import {
  useAdGetAllBusinessApproveRequest,
  useApproveFreelancer,
} from "@business-layer/business-logic/lib/admin";

const PAGE_SIZE = 5;
export const CompanyApprove = () => {
  const [pageCurrent, setPageCurrent] = useState(0);
  const {
    data: businessList,
    refetch,
    isRefetching,
  } = useAdGetAllBusinessApproveRequest(pageCurrent + 1, PAGE_SIZE);

  useEffect(() => {
    typeof businessList !== "undefined" && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent]);
  const columns: Column<I_AD_BusinessApproveRequest>[] = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "companyName",
        width: "25%",
        Cell: ({ row }) => (
          <Flex alignItems={"center"} whiteSpace={"normal"}>
            <Avatar
              src={getCDNUrl(row.original.account.avatar)}
              name={row.original.companyName || ""}
            />
            <Text fontSize={"0.65rem"} marginLeft={2} textAlign={"start"}>
              {row.original.companyName}
            </Text>
          </Flex>
        ),
      },
      {
        Header: "MST",
        accessor: "taxIdentificationNumber",
      },
      {
        Header: "CEO’s Name",
        accessor: (row) => row.account.fullName,
        width: "15%",
      },
      {
        Header: "Contact",
        accessor: (row) => row.account.phone,
        Cell: ({ row }: CellProps<I_AD_BusinessApproveRequest>) => (
          <Text fontSize={"0.65rem"}>
            (+{row.original.account.countryCode}) {row.original.account.phone}
          </Text>
        ),
      },
      {
        Header: "Identification",
        accessor: "identityNumber",
        width: "25%",
        Cell: ({ row }) => (
          <Box>
            <Text align={"center"} fontSize={"0.65rem"}>
              {row.original.identityNumber}
            </Text>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Box flex={1}>
                <Image
                  src={getCDNUrl(row.original.identityCardImage)}
                  alt={row.original.account.fullName}
                  borderRadius={"sm"}
                  width={100}
                  height={50}
                  cursor="pointer"
                  objectFit={"cover"}
                  onClick={() =>
                    handleImageClick(getCDNUrl(row.original.identityCardImage))
                  }
                />
              </Box>
              <Box w={2}></Box>
              <Box flex={1}>
                <Image
                  src={getCDNUrl(row.original.identityCardImageBack)}
                  alt={row.original.account.fullName}
                  borderRadius={"sm"}
                  width={100}
                  height={50}
                  cursor="pointer"
                  objectFit={"cover"}
                  onClick={() =>
                    handleImageClick(
                      getCDNUrl(row.original.identityCardImageBack)
                    )
                  }
                />
              </Box>
            </Flex>
          </Box>
        ),
      },
      {
        Header: "Address",
        accessor: "address",
        width: "50%",
        Cell: ({ row }) => (
          <Text align={"center"} fontSize={"0.65rem"} whiteSpace={"normal"}>
            {row.original.address.addressLine}
          </Text>
        ),
      },
      {
        Header: "",
        accessor: "id",
        width: "5%",
        Cell: ({ row }) => (
          <Menu>
            <MenuButton
              as={Button}
              background={"transparent"}
              onClick={() => handleMenuOpen(row)}
            >
              <i className="fi fi-br-angle-small-down"></i>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => handleOpenApproveConfirmBox(row.original)}
                display="flex"
                justifyContent="center"
              >
                <Text
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                >
                  Approve Company
                </Text>
              </MenuItem>
              <MenuItem
                onClick={() => handleOpenRefuseConfirmBox(row.original)}
                display="flex"
                justifyContent="center"
              >
                <Text color={"dt_rose"} fontSize={"0.65rem"} fontWeight={700}>
                  Refuse Company
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Ensure dependencies are properly added if needed
  );

  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [state, setState] = useState({
    selectedImage: "",
    selectedRow: null,
    selectedCompany: null,
    actionType: "",
  });
  const toast = useToast();
  const { onApprove } = useApproveFreelancer();

  const handleImageClick = (imageUrl: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedImage: imageUrl,
    }));
    onOpen();
  };

  const handleMenuOpen = (index: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: index === prevState.selectedRow ? null : index,
    }));
  };

  const handleOpenApproveConfirmBox = (Company: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompany: Company,
      actionType: "approve",
    }));
    setIsAlertOpen(true);
  };

  const handleOpenRefuseConfirmBox = (Company: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompany: Company,
      actionType: "refuse",
    }));
    setIsAlertOpen(true);
  };

  const handleConfirmAction = async () => {
    const { actionType, selectedCompany } = state;

    const verified = actionType === "approve";
    const promise = onApprove({
      freelancerId: (selectedCompany as any).id,
      verified,
    });

    const toastConfig = {
      success: {
        title: verified ? "Đã cấp phép thành công" : "Đã từ chối thành công",
        description: verified
          ? "Hãy tới trang Quản lý công ty để kiểm tra nhé"
          : "Buồn ghê, không có công ty thành viên mới rồi",
      },
      error: {
        title: "Mé lỗi rồi!",
        description: "Hãy thử lại sau hoặc liên hệ team dev nhé",
      },
      loading: {
        title: "Đang thực thi",
        description: "Vui lòng chờ 10 năm...",
      },
    };

    toast.promise(promise, toastConfig);

    // CALL AFTER PROMISE SOLVED
    setState((prevState) => ({
      ...prevState,
      selectedCompany: null,
      actionType: "",
    }));
    setIsAlertOpen(false);
    await promise;
    refetch();
  };

  return (
    <Box padding={"1rem"}>
      {businessList && !isRefetching ? (
        <CustomTable
          columns={columns}
          data={businessList.data}
          totalPage={businessList.totalPages}
          pageSize={PAGE_SIZE}
          pageCurrent={pageCurrent}
          onChangePage={(page) => {
            setPageCurrent(page);
          }}
        />
      ) : (
        <CommonLoader />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Identity Card Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={state.selectedImage}
              alt="Full-size"
              width="100%"
              height="auto"
              objectFit={"cover"}
            />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        isCentered={true}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {state.actionType === "approve"
                ? "Approve Company"
                : "Refuse Company"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to {state.actionType} this Company?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme={state.actionType === "approve" ? "green" : "red"}
                onClick={handleConfirmAction}
                ml={3}
              >
                {state.actionType === "approve" ? "Approve" : "Refuse"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
