import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useApproveFreelancerMutation } from "../../fetching/mutations";
import { approveFreelancerPropsType } from "@business-layer/services";

export const useApproveFreelancer = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { mutateAsync, isPending } = useApproveFreelancerMutation();

  const onApprove = async (
    props: Omit<approveFreelancerPropsType, "token">
  ) => {
    try {
      return await mutateAsync({ token, ...props });
    } catch (error) {
      throw error;
    }
  };

  return { onApprove, isLoading: isPending };
};
