import { getApiUrl } from "@business-layer/services/config/url";
import { useRealtimeContext } from "../../context";
import {
  LogLevel,
  HubConnectionBuilder,
  HubConnection,
} from "@microsoft/signalr";
import { useListenError } from "./useListenError";
import { getPhoneNumberFromAsyncStorageFunction } from "../../helpers/getPhoneNumber";
import { useState } from "react";

export function useSocketConnect() {
  const { dispatch } = useRealtimeContext();
  const onListenError = useListenError();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  const handleConnect = async (
    phone?: string
  ): Promise<HubConnection | null> => {
    setIsConnecting(true);
    let phoneNumber: string | null | undefined = phone;
    if (!phone) {
      phoneNumber = getPhoneNumberFromAsyncStorageFunction();
    }
    console.log("Connecting to socket with phone: ", phoneNumber);
    if (!phoneNumber) {
      setIsConnecting(false);
      return null;
    }
    try {
      const socket = new HubConnectionBuilder()
        .withUrl(`${getApiUrl()}/chat-hub?phone=${phoneNumber}`)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      await socket.start();
      console.log("Connect to socket successfully");
      onListenError(socket);
      dispatch({
        type: "SET_SOCKET",
        payload: socket,
      });
      return socket;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsConnecting(false);
    }
  };

  return {
    handleConnect,
    isConnecting,
  };
}
