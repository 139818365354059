import { useGetAdminCurrentAccountFromStorage } from "@business-layer/business-logic/lib/admin";
import { useLogout } from "@business-layer/business-logic/lib/auth";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BASE_IMG_CDN } from "@constants/cdn";
import { APP_URLS } from "@constants/urls";
import SimpleLoader from "@presentational/atoms/Loading/SimpleLoader";
import React from "react";
import { useLocation } from "react-router-dom";
function getGreeting() {
  const date = new Date();
  const hours = date.getHours();

  if (hours < 12) {
    return "Good morning 👋";
  } else if (hours < 18) {
    return "Happy afternoon 🌥️";
  } else {
    return "Sweet evening 🌚";
  }
}
const greeting = getGreeting();
export const MainHeader = () => {
  const adminDetail = useGetAdminCurrentAccountFromStorage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const { onLogout } = useLogout();
  const { pathname } = useLocation();
  const pageName = Object.keys(APP_URLS)
    .find(
      (uk) =>
        pathname.includes(APP_URLS[uk as keyof typeof APP_URLS]) &&
        APP_URLS[uk as keyof typeof APP_URLS] !== "/"
    )
    ?.replaceAll("_", " ")
    .toLowerCase();
  return (
    <Box
      w={"100%"}
      bg={"dt_soft"}
      padding={"1rem"}
      display={"flex"}
      flexDir={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Breadcrumb
          spacing="8px"
          color={"dt_gray"}
          fontSize={"0.75rem"}
          separator={<Text color={"dt_gray"}>/</Text>}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Pages</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href={pathname} textTransform={"capitalize"}>
              {pageName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading
          as={"h1"}
          size={"lg"}
          fontWeight={800}
          color={"dt_primary"}
          textTransform={"capitalize"}
        >
          {pageName}
        </Heading>
      </Box>
      <Box
        bg={"white"}
        borderRadius={999}
        py={"0.5rem"}
        pr={"0.5rem"}
        pl={"3rem"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        boxShadow={"md"}
      >
        {adminDetail ? (
          <>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"flex-end"}
              pr={"0.75rem"}
            >
              <Text color={"dt_gray"}>{greeting}</Text>
              <Text color={"dt_primary"} fontWeight={800} fontSize={"1rem"}>
                {adminDetail.fullName}
              </Text>
            </Box>
            <Avatar
              name="Dan Abrahmov"
              size={"md"}
              src={`${BASE_IMG_CDN}${adminDetail.avatar}`}
            />
            <Button
              bgColor={"transparent"}
              pr={"0.5rem"}
              _hover={{
                bgColor: "transparent",
                color: "dt_rose",
              }}
              color={"dt_primary"}
              onClick={onOpen}
            >
              <i className="fi fi-bs-sign-out-alt"></i>
            </Button>
          </>
        ) : (
          <SimpleLoader />
        )}
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Please confirm
            </AlertDialogHeader>

            <AlertDialogBody>
              Do you want to logout... my Sensei?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  onLogout();
                }}
                ml={3}
              >
                Logout now
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
