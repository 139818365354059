import { TokenSchema } from "@business-layer/services/entities";
import z from "zod";

const loginResponseSchema = z.object({
  message: z.string(),
  token: TokenSchema,
  refreshToken: TokenSchema,
});
const refreshTokenResponseSchema = z.object({
  message: z.string(),
  token: TokenSchema,
  refreshToken: TokenSchema,
});

export { loginResponseSchema, refreshTokenResponseSchema };
