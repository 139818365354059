/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  infoType,
  validationWithIdType,
} from "@business-layer/services/entities";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  ListItem,
  OrderedList,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { validationType } from "@utils/validators/yup";
import { useEffect, useState } from "react";
import { SubValidation, subValidationDataType } from "./SubValidation";

function isValidJsonArray(str: string) {
  try {
    let value = JSON.parse(str);
    let isValid = Array.isArray(value);
    return { isValid, value };
  } catch (e) {
    return { isValid: false, value: null };
  }
}
const DEFAULT_FORM_DATA: formDataType = {
  name: "",
  type: "stepper",
  label: "",
  mask: "",
  defaultValue: 1,
  validationType: "required",
  message: "",
  value: 1,
};
const DEFAULT_SUB_VALIDATION_DATA: subValidationDataType = {
  validationType: "required",
  message: "",
  value: 1,
};

type optionDataType = {
  name: string;
  info: infoType[];
};
type formDataType = {
  // Info
  name: string;
  type: infoType["type"];
  label: string;
  mask: string;
  defaultValue: number;
  // Validation
  validationType: validationType["name"];
  message: string;
  value?: number;
};
type props = {
  onSubmitCallback: (data: {
    validationType: validationType["name"];
    message: string;
    value?: number;
    options: optionDataType[];
  }) => void;
};
export const SpecialItemSelectForm: React.FC<props> = ({
  onSubmitCallback,
}) => {
  const toast = useToast();
  const [subValidationData, setSubValidationData] =
    useState<subValidationDataType>(DEFAULT_SUB_VALIDATION_DATA);
  const [currentSubValidationData, setCurrentSubValidationData] = useState<
    subValidationDataType[]
  >([]);
  const [currentInfoList, setCurrentInfoList] = useState<infoType[]>([]);
  const [currentOptionList, setCurrentOptionList] = useState<optionDataType[]>(
    []
  );
  const [optionsRawArr, setOptionRawArr] = useState<string>("");
  const [formData, setFormData] = useState<formDataType>(DEFAULT_FORM_DATA);
  function onSuccessSubmit() {
    onSubmitCallback({
      ...(formData.validationType !== "required"
        ? {
            validationType: formData.validationType,
            message: formData.message,
            value: formData.value,
          }
        : {
            validationType: formData.validationType,
            message: formData.message,
          }),
      options: currentOptionList,
    });
    setFormData(DEFAULT_FORM_DATA);
    setSubValidationData(DEFAULT_SUB_VALIDATION_DATA);
    setCurrentSubValidationData([]);
    setCurrentInfoList([]);
    setCurrentOptionList([]);
    setOptionRawArr("");
  }

  function handleAddOption() {
    const { name, validationType, message, value, ...restOfFormData } =
      formData;
    setCurrentOptionList([
      ...currentOptionList,
      { name, info: currentInfoList },
    ]);
    setCurrentInfoList([]);
    setFormData({
      ...DEFAULT_FORM_DATA,
      validationType,
      message,
      value,
    });
  }
  function handleAddInfo() {
    const { validationType, message, value, name, ...newInfo } = formData;
    switch (newInfo.type) {
      case "stepper": {
        setCurrentInfoList([
          ...currentInfoList,
          {
            type: newInfo.type,
            label: newInfo.label,
            mask: newInfo.mask,
            defaultValue: newInfo.defaultValue,
            validation: currentSubValidationData.map((v) => ({
              id: "",
              name: v.validationType,
              message: v.message,
              ...(v.validationType === "required" ? {} : { value: v.value }),
            })) as validationWithIdType,
          },
        ]);
        setFormData({
          ...DEFAULT_FORM_DATA,
          validationType,
          message,
          value,
          name,
        });
        setCurrentSubValidationData([]);
        break;
      }
      case "button_group": {
        const { isValid, value } = isValidJsonArray(optionsRawArr);
        if (!isValid) {
          toast({
            status: "error",
            title: "Array of Buttons invalid",
            isClosable: true,
          });
        } else {
          setCurrentInfoList([
            ...currentInfoList,
            {
              type: newInfo.type,
              label: newInfo.label,
              buttons: value,
              validation: currentSubValidationData.map((v) => ({
                id: "",
                name: v.validationType,
                message: v.message,
                ...(v.validationType === "required" ? {} : { value: v.value }),
              })) as validationWithIdType,
            },
          ]);
          setFormData({
            ...DEFAULT_FORM_DATA,
            validationType,
            message,
            value,
            name,
          });
          setCurrentSubValidationData([]);
        }
        break;
      }
    }
  }
  function handleAddSubValidation() {
    setCurrentSubValidationData([
      ...currentSubValidationData,
      subValidationData.validationType !== "required"
        ? {
            validationType: subValidationData.validationType,
            message: subValidationData.message,
            value: subValidationData.value,
          }
        : {
            validationType: subValidationData.validationType,
            message: subValidationData.message,
          },
    ]);
    setSubValidationData(DEFAULT_SUB_VALIDATION_DATA);
  }

  useEffect(() => {
    console.log("currentInfoList change: ", currentInfoList);
  }, [currentInfoList]);
  useEffect(() => {
    console.log("currentOptionList change: ", currentOptionList);
  }, [currentOptionList]);
  return (
    <Box mt={"0.5rem"}>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={"0.5rem"} mb={"1rem"}>
        <GridItem colSpan={2}>
          <Text
            color={"dt_primary"}
            fontSize={"0.65rem"}
            fontWeight={700}
            fontStyle={"italic"}
          >
            Options (Combine name and multiple infos)
          </Text>

          <OrderedList>
            {currentOptionList.map((item, index) => (
              <ListItem key={`info@${item.name}@${index}`}>
                <Flex flexDirection={"row"} alignItems={"center"}>
                  <Box>
                    <Text fontSize={"1rem"}>{item.name}</Text>
                    {item.info.map((inf) => (
                      <Box>
                        {(() => {
                          const { validation, ...others } = inf;
                          return (
                            <>
                              <Text
                                fontSize={"0.65rem"}
                                fontStyle={"italic"}
                                key={inf.label}
                              >
                                {Object.values(others).map(
                                  (val) => ` | ${val}`
                                )}
                              </Text>
                              {validation.map((v, i) => (
                                <Text
                                  fontSize={"0.5rem"}
                                  fontStyle={"italic"}
                                  key={v.message + i}
                                >
                                  {Object.values(v).map((val) => ` | ${val}`)}
                                </Text>
                              ))}
                            </>
                          );
                        })()}
                      </Box>
                    ))}
                  </Box>

                  <Button
                    color={"error"}
                    onClick={() => {
                      setCurrentOptionList(
                        currentOptionList.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                  </Button>
                </Flex>
              </ListItem>
            ))}
          </OrderedList>
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            placeholder="Name"
            fontSize={"0.75rem"}
            fontFamily={"Roboto Flex Variable"}
            borderColor={"dt_primary"}
            color={"dt_primary"}
            bg={"dt_soft"}
            value={formData.name}
            onChange={({ target: { value } }) => {
              setFormData({
                ...formData,
                name: value,
              });
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Button
            type="button"
            border={"solid"}
            borderWidth={"1px"}
            borderColor={"dt_primary"}
            bg={"dt_light"}
            variant={"outline"}
            color={"dt_primary"}
            fontSize={"0.65rem"}
            fontWeight={700}
            textTransform={"capitalize"}
            onClick={handleAddOption}
            w={"100%"}
          >
            Add option
          </Button>
        </GridItem>
        <GridItem colSpan={2}>
          <OrderedList>
            {currentInfoList.map((item, index) => (
              <ListItem key={`info@${item.type}@${index}`}>
                <Flex flexDirection={"row"} alignItems={"center"}>
                  <Box>
                    {(() => {
                      const { validation, ...others } = item;
                      return (
                        <>
                          <Text fontSize={"1rem"}>
                            {Object.values(others).map((val, index) =>
                              index < 1 ? val : ` | ${val}`
                            )}
                          </Text>

                          {validation.map((v, i) => (
                            <Text
                              fontSize={"0.65rem"}
                              fontStyle={"italic"}
                              key={v.message + i}
                            >
                              {Object.values(v).map((val) => ` | ${val}`)}
                            </Text>
                          ))}
                        </>
                      );
                    })()}
                  </Box>
                  <Button
                    color={"error"}
                    onClick={() => {
                      setCurrentInfoList(
                        currentInfoList.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                  </Button>
                </Flex>
              </ListItem>
            ))}
          </OrderedList>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir={"row"} alignItems={"center"} gap={"0.5rem"}>
            <Text
              color={"dt_primary"}
              fontSize={"0.65rem"}
              fontWeight={700}
              fontStyle={"italic"}
            >
              Info
            </Text>
            <Select
              fontSize={"0.75rem"}
              fontFamily={"Roboto Flex Variable"}
              borderColor={"dt_primary"}
              color={"dt_primary"}
              bg={"dt_soft"}
              value={formData.type}
              onChange={({ target: { value } }) => {
                setFormData({
                  ...formData,
                  type: value as infoType["type"],
                });
              }}
            >
              <option value={"stepper" as infoType["type"]}>Stepper</option>
              <option value={"button_group" as infoType["type"]}>
                Button group
              </option>
            </Select>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Input
            placeholder="Label"
            fontSize={"0.75rem"}
            fontFamily={"Roboto Flex Variable"}
            borderColor={"dt_primary"}
            color={"dt_primary"}
            bg={"dt_soft"}
            value={formData.label}
            onChange={({ target: { value } }) => {
              setFormData({
                ...formData,
                label: value,
              });
            }}
          />
        </GridItem>
        {formData.type === "stepper" ? (
          <>
            <GridItem colSpan={1}>
              <Input
                placeholder="Mask (Optional)"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                value={formData.mask}
                onChange={({ target: { value } }) => {
                  setFormData({
                    ...formData,
                    mask: value,
                  });
                }}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <Input
                placeholder="Default value"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                type="number"
                value={formData.defaultValue}
                onChange={({ target: { value } }) => {
                  setFormData({
                    ...formData,
                    defaultValue: Number.parseInt(value),
                  });
                }}
              />
            </GridItem>
          </>
        ) : (
          <>
            <GridItem colSpan={2}>
              <Input
                placeholder="Array of Buttons (JSON Format)"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                value={optionsRawArr}
                onChange={({ target: { value } }) => {
                  setOptionRawArr(value);
                }}
              />
            </GridItem>
          </>
        )}
        <GridItem colSpan={2}>
          <Button
            type="button"
            border={"solid"}
            borderWidth={"1px"}
            borderColor={"dt_primary"}
            bg={"dt_light"}
            variant={"outline"}
            color={"dt_primary"}
            fontSize={"0.65rem"}
            fontWeight={700}
            textTransform={"capitalize"}
            onClick={handleAddInfo}
            w={"100%"}
          >
            Add info
          </Button>
        </GridItem>
        <GridItem colSpan={2}>
          <Text
            color={"dt_primary"}
            fontSize={"0.65rem"}
            fontWeight={700}
            fontStyle={"italic"}
            mb={"0.5rem"}
          >
            Validations
          </Text>{" "}
          <OrderedList>
            {currentSubValidationData.map((item, index) => (
              <ListItem key={`info@${item.validationType}@${index}`}>
                <Flex flexDirection={"row"} alignItems={"center"}>
                  <Text fontSize={"1rem"}>
                    {Object.values(item).map((val, index) =>
                      index < 1 ? val : ` | ${val}`
                    )}
                  </Text>
                  <Button
                    color={"error"}
                    onClick={() => {
                      setCurrentSubValidationData(
                        currentSubValidationData.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                  </Button>
                </Flex>
              </ListItem>
            ))}
          </OrderedList>
          {/* Validation Form */}
          <SubValidation
            formData={subValidationData}
            setFormData={setSubValidationData}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Button
            type="button"
            border={"solid"}
            borderWidth={"1px"}
            borderColor={"dt_primary"}
            bg={"dt_light"}
            variant={"outline"}
            color={"dt_primary"}
            fontSize={"0.65rem"}
            fontWeight={700}
            textTransform={"capitalize"}
            onClick={handleAddSubValidation}
            w={"100%"}
          >
            Add sub validation
          </Button>
        </GridItem>
      </Grid>
      <Divider color={"dt_primary"} />
      <Text
        color={"dt_primary"}
        fontSize={"1rem"}
        fontWeight={700}
        fontStyle={"italic"}
        my={"0.5rem"}
      >
        Requirement Validations
      </Text>
      {/* Validation Form */}
      <Flex flexDir={"row"} gap={"0.5rem"}>
        <Select
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          w={"35%"}
          value={formData.validationType}
          onChange={(e) =>
            setFormData({
              ...formData,
              validationType: e.currentTarget.value as validationType["name"],
            })
          }
        >
          <option value={"required"}>Required</option>
          <option value={"min"}>Min</option>
          <option value={"max"}>Max</option>
        </Select>
        <Input
          w={"65%"}
          placeholder="Message"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          value={formData.message}
          onChange={(e) =>
            setFormData({
              ...formData,
              message: e.target.value,
            })
          }
        />
      </Flex>
      {formData.validationType !== "required" ? (
        <Input
          placeholder="Value"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          type="number"
          mt={"0.5rem"}
          value={formData.value}
          onChange={(e) =>
            setFormData({
              ...formData,
              value: Number.parseInt(e.target.value),
            })
          }
        />
      ) : null}
      {/* Submit button */}
      <Button
        size={"sm"}
        type="button"
        border={"solid"}
        borderWidth={"1px"}
        borderColor={"dt_primary"}
        bg={"dt_soft_primary"}
        color={"white"}
        fontSize={"0.65rem"}
        fontWeight={700}
        mt={"0.5rem"}
        onClick={onSuccessSubmit}
      >
        Add validation
      </Button>
    </Box>
  );
};
