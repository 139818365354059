import { Services } from "@business-layer/services/service";
import {
  addMultipleServiceTypeSchema,
  createCategoryResponseSchema,
  createServiceResponseSchema,
  deleteCategoryResponseSchema,
  deleteServiceResponseSchema,
  getAllCategoryResponseSchema,
  getCategoryDetailResponseSchema,
  getServiceDetailResponseSchema,
  searchCategoriesAndServicesSchema,
} from "./schema";
import {
  addMultipleServiceTypePropsType,
  addMultipleServiceTypeResponseType,
  createCategoryPropsType,
  createCategoryResponseType,
  createServicePropsType,
  createServiceResponseType,
  deleteCategoryPropsType,
  deleteCategoryResponseType,
  deleteServicePropsType,
  deleteServiceResponseType,
  getAllCategoryResponseType,
  getCategoryDetailPropsType,
  getCategoryDetailResponseType,
  getServiceDetailPropsType,
  getServiceDetailResponseType,
  searchCategoriesAndServicesPropsType,
  searchCategoriesAndServicesResponseType,
} from "./type";
import {
  addMultipleServiceTypeEndpoint,
  createCategoryEndpoint,
  createServiceEndpoint,
  deleteCategoryEndpoint,
  deleteServiceEndpoint,
  getAllCategoryEndpoint,
  getCategoryDetailEndpoint,
  getServiceDetailEndpoint,
  searchCategoriesAndServicesEndpoint,
} from "@business-layer/services/config/apis";

const UNAUTHORIZED_MESSAGE = "Unauthorized";

export * from "./type";
export class CategoryService extends Services {
  getAllCategory = async (): Promise<getAllCategoryResponseType> => {
    this.abortController = new AbortController();
    try {
      return await this.fetchApi<
        typeof getAllCategoryResponseSchema,
        getAllCategoryResponseType
      >({
        method: "GET",
        url: getAllCategoryEndpoint,
        schema: getAllCategoryResponseSchema,
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getCategoryDetail = async ({
    categoryId,
    token,
  }: getCategoryDetailPropsType): Promise<getCategoryDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof getCategoryDetailResponseSchema,
          getCategoryDetailResponseType
        >({
          method: "GET",
          url: getCategoryDetailEndpoint,
          schema: getCategoryDetailResponseSchema,
          params: {
            id: categoryId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getServiceDetail = async ({
    serviceId,
    token,
  }: getServiceDetailPropsType): Promise<getServiceDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof getServiceDetailResponseSchema,
          getServiceDetailResponseType
        >({
          method: "GET",
          url: getServiceDetailEndpoint,
          schema: getServiceDetailResponseSchema,
          params: {
            id: serviceId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  addMultipleServiceType = async ({
    token,
    ...data
  }: addMultipleServiceTypePropsType): Promise<addMultipleServiceTypeResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof addMultipleServiceTypeSchema,
          addMultipleServiceTypeResponseType
        >({
          method: "POST",
          url: addMultipleServiceTypeEndpoint,
          schema: addMultipleServiceTypeSchema,
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  searchCategoriesAndServices = async ({
    name,
    token,
  }: searchCategoriesAndServicesPropsType): Promise<searchCategoriesAndServicesResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof searchCategoriesAndServicesSchema,
          searchCategoriesAndServicesResponseType
        >({
          method: "GET",
          url: searchCategoriesAndServicesEndpoint,
          schema: searchCategoriesAndServicesSchema,
          params: {
            name,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  createService = async ({
    token,
    service,
  }: createServicePropsType): Promise<createServiceResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof createServiceResponseSchema,
          createServiceResponseType
        >({
          method: "POST",
          url: createServiceEndpoint,
          schema: createServiceResponseSchema,
          data: {
            ...service,
            isActivated: true,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  createCategory = async ({
    token,
    ...data
  }: createCategoryPropsType): Promise<createCategoryResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof createCategoryResponseSchema,
          createCategoryResponseType
        >({
          method: "POST",
          url: createCategoryEndpoint,
          schema: createCategoryResponseSchema,
          data: {
            ...data,
            isActivated: true,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  deleteService = async ({
    token,
    ...params
  }: deleteServicePropsType): Promise<deleteServiceResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof deleteServiceResponseSchema,
          deleteServiceResponseType
        >({
          method: "DELETE",
          url: deleteServiceEndpoint,
          schema: deleteServiceResponseSchema,
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  deleteCategory = async ({
    token,
    ...params
  }: deleteCategoryPropsType): Promise<deleteCategoryResponseType> => {
    this.abortController = new AbortController();
    try {
      if (!token) {
        throw new Error(UNAUTHORIZED_MESSAGE);
      } else {
        return await this.fetchApi<
          typeof deleteCategoryResponseSchema,
          deleteCategoryResponseType
        >({
          method: "DELETE",
          url: deleteCategoryEndpoint,
          schema: deleteCategoryResponseSchema,
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
