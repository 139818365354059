import { useLogin } from "@business-layer/business-logic/lib/auth";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { formatDate } from "@utils/helpers";
import {
  loginFormSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { useForm } from "react-hook-form";

const { dateMonthYear, time24 } = formatDate(new Date());
type loginForm = {
  email: string;
  password: string;
};
function Login() {
  const { onLogin, isLoading } = useLogin();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<loginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: useYupValidationResolver(loginFormSchema),
  });

  function onSuccessSubmit({ email, password }: loginForm) {
    onLogin({ email, password })
      .then((msg) => {
        toast({
          title: msg,
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }
  return (
    <Grid
      w={"100vw"}
      h={"100vh"}
      bg={"dt_soft"}
      templateColumns={"repeat(2, 1fr)"}
      gap={"1.5rem"}
      p={"3rem"}
    >
      <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
        <Heading
          as={"h6"}
          size={"sm"}
          color={"dt_primary"}
          fontWeight={500}
          mb={"0.5rem"}
          textAlign={"center"}
        >
          MADE BY BLUEBERRY SENSEI
        </Heading>
        <Heading
          as={"h1"}
          size={"xl"}
          color={"dt_primary"}
          textAlign={"center"}
        >
          THE FUCKING APP NAMED "DETOI CENTER" NEED YOU TO LOGIN
        </Heading>
      </Flex>
      <Flex
        backgroundImage={"/assets/imgs/login-bg.jpg"}
        w={"100%"}
        h={"100%"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        borderRadius={"xl"}
        flexDirection={"column"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Text color={"white"}>
          {time24} | {dateMonthYear}
        </Text>
        <Box
          bg="dt_white"
          p="0.75rem"
          borderRadius={"xl"}
          boxShadow={"xl"}
          w={"80%"}
        >
          <Text
            color="dt_primary"
            align={"center"}
            fontWeight={800}
            fontSize={"1rem"}
            mb={4}
          >
            Login to your account
          </Text>
          <form onSubmit={handleSubmit(onSuccessSubmit)}>
            <FormControl
              isInvalid={Boolean(errors.email) || Boolean(errors.password)}
              isDisabled={isLoading}
            >
              <FormLabel
                htmlFor="name"
                fontSize={"0.75rem"}
                fontWeight={700}
                color={"dt_primary"}
                mb={1}
              >
                Email
              </FormLabel>
              <Input
                id="email"
                placeholder="Example@gmail.com"
                fontSize={"0.75rem"}
                {...register("email")}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
              />
              <FormErrorMessage color={"error"}>
                {errors.email && errors.email.message}
              </FormErrorMessage>
              <FormLabel
                htmlFor="password"
                fontSize={"0.75rem"}
                fontWeight={700}
                color={"dt_primary"}
                mb={1}
                mt={3}
              >
                Password
              </FormLabel>
              <Input
                id="password"
                placeholder="At least 6 characters"
                fontSize={"0.75rem"}
                type="password"
                {...register("password")}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
              />
              <FormErrorMessage color={"error"}>
                {errors.password && errors.password.message}
              </FormErrorMessage>

              <Button
                isLoading={isLoading}
                loadingText="Wait a second"
                bg={"dt_soft_primary"}
                color={"dt_white"}
                mt={4}
                mb={2}
                w={"100%"}
                type="submit"
              >
                Login
              </Button>
            </FormControl>
          </form>
        </Box>
        <Text color={"dt_white"}>" The color of the blueberry " </Text>
      </Flex>
    </Grid>
  );
}

export default Login;
