import { getOverallAnalyticsEndpoint } from "../../config/apis";
import { Services } from "../../service";
import { getOverallAnalyticsResponseSchema } from "./schema";
import {
  getOverallAnalyticsParamsType,
  getOverallAnalyticsResponseType,
} from "./type";

export * from "./type";
export class AnalyticsService extends Services {
  getOverallAnalytics = async ({
    token,
    ...data
  }: getOverallAnalyticsParamsType): Promise<getOverallAnalyticsResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getOverallAnalyticsResponseSchema,
          getOverallAnalyticsResponseType
        >({
          method: "POST",
          url: getOverallAnalyticsEndpoint,
          schema: getOverallAnalyticsResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
