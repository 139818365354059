import { z } from 'zod';

export interface IMessagePreview {
  conversationId: string;
  sender: {
    id: string;
    fullName: string;
    avatar: string;
  };
  latestMessage: {
    id: string;
    content: string;
    timeReceived: string;
  };
}
export const MessagePreviewSchema = z.object({
  conversationId: z.string(),
  sender: z.object({
    id: z.string(),
    fullName: z.string(),
    avatar: z.string(),
  }),
  latestMessage: z.object({
    id: z.string(),
    content: z.string(),
    timeReceived: z.string(),
  }),
});

export interface IMessage {
  id: string;
  content: string;
  image: string | null;
  isSystem: boolean;
  timeReceived: string;
  sender: {
    id: string;
    fullName: string;
    avatar: string;
    type: number;
  };
}
export const MessageSchema = z.object({
  id: z.string(),
  content: z.string(),
  image: z.string().nullable(),
  isSystem: z.boolean(),
  timeReceived: z.string(),
  sender: z.object({
    id: z.string(),
    fullName: z.string(),
    avatar: z.string(),
    type: z.number(),
  }),
});
