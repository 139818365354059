function isDateGreaterThan(dateCheck: string, dateTarget: string): boolean {
  if (!dateCheck || !dateTarget) return false;
  const check = new Date(dateCheck);
  const target = new Date(dateTarget);

  return check > target;
}
function formatDate(dateObj: Date | string, isReverdFormat?: boolean) {
  const dateRaw = typeof dateObj === 'string' ? new Date(dateObj) : dateObj;

  let days: string[] = [
    'Chủ Nhật',
    'Thứ 2',
    'Thứ 3',
    'Thứ 4',
    'Thứ 5',
    'Thứ 6',
    'Thứ 7',
  ];

  let dayOfWeek: string = days[dateRaw.getDay()];
  let date: string = dateRaw.getDate().toString().padStart(2, '0');
  let month: string = (dateRaw.getMonth() + 1).toString().padStart(2, '0');
  let year: number = dateRaw.getFullYear();

  let hours: number = dateRaw.getHours();
  let minutes: number = dateRaw.getMinutes();

  const formattedHours: string = hours.toString().padStart(2, '0');
  const formattedMinutes: string = minutes.toString().padStart(2, '0');

  return {
    dateMonthYear: isReverdFormat
      ? `${year}-${month}-${date}`
      : `${date}-${month}-${year}`,
    days: dayOfWeek,
    time24: `${formattedHours}:${formattedMinutes}`,
  };
}
const minutesLeft = (startDate: string, startTime: string): number => {
  const now = new Date();
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const startDateTime = new Date(startDate);
  startDateTime.setHours(startHour, startMinute);

  const diff = (startDateTime.getTime() - now.getTime()) / 60000; // convert milliseconds to minutes

  return Math.round(diff);
};
function timeUntilStart(startDate: string, startTime: string) {
  // Convert start date and time to a JavaScript Date object
  const start = new Date(`${startDate}T${startTime}`);
  start.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());

  // Get the current date and time
  const now = new Date();

  // Calculate the difference in milliseconds
  const diffMillis = start.getTime() - now.getTime();

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(diffMillis / (1000 * 60 * 60));
  const minutes = Math.floor((diffMillis % (1000 * 60 * 60)) / (1000 * 60));

  return {
    hours,
    minutes,
  };
}

/**
 * Chuyển đổi một đối tượng Date thành một chuỗi theo định dạng cụ thể:
 * - Nếu thời gian so với thời điểm hiện tại ít hơn 1 ngày: "5 giờ trước", "7 giờ trước", "10 phút trước"
 * - Còn lại: "15:00, 25 thg3, 2024"
 * @param {Date} date - Đối tượng Date cần chuyển đổi.
 * @returns {string} - Chuỗi được định dạng dựa trên thời gian của đối tượng Date.
 */
function formatDateFromNow(date: Date): string {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();
  const diffInMinutes = Math.round(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.round(diffInMinutes / 60);

  if (diffInHours < 24) {
    if (diffInHours < 1) {
      return `${diffInMinutes} phút trước`;
    } else {
      return `${diffInHours} giờ trước`;
    }
  } else {
    const formattedDate = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}, ${date.getDate()} thg${
      date.getMonth() + 1
    }, ${date.getFullYear()}`;
    return formattedDate;
  }
}

// This function calculates a person's age based on their date of birth
function calcAgeFromDateOfBirth(dob: string | Date) {
  // Convert the date of birth to a JavaScript Date object if it's a string
  const dateOfBirth = typeof dob === 'string' ? new Date(dob) : dob;

  // Get the current date
  const now = new Date();

  // Calculate the difference in years between the current date and the date of birth
  const age = now.getFullYear() - dateOfBirth.getFullYear();

  // If the current month and day are before the date of birth, subtract one year
  if (
    now.getMonth() < dateOfBirth.getMonth() ||
    (now.getMonth() === dateOfBirth.getMonth() &&
      now.getDate() < dateOfBirth.getDate())
  ) {
    return age - 1;
  }
  // Return the calculated age
  return age;
}

export {
  minutesLeft,
  isDateGreaterThan,
  formatDate,
  timeUntilStart,
  formatDateFromNow,
  calcAgeFromDateOfBirth,
};
