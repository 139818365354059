// Importing necessary modules and functions
import { COOKIE_KEYS } from "@business-layer/business-logic/configs";
import { loginResponseType, loginParamsType } from "../../../../../services";
import { useLoginMutation } from "../../fetching/mutation";
import { useAuthContext } from "../context";
import { setCookie } from "@business-layer/business-logic/helper";
import { useGetAdminCurrentAccountDetail } from "@business-layer/business-logic/lib/admin";

export const useLogin = () => {
  const { dispatch } = useAuthContext();
  const loginMutation = useLoginMutation();
  const { onFetching } = useGetAdminCurrentAccountDetail();

  const onLogin = (props: loginParamsType): Promise<string> => {
    return new Promise((resolve, reject) => {
      loginMutation
        .mutateAsync(props)
        .then(async ({ message, token, refreshToken }: loginResponseType) => {
          await onFetching(token.value);
          setCookie(COOKIE_KEYS.ACCESS_TOKEN, token.value, token.expires);
          setCookie(
            COOKIE_KEYS.REFRESH_TOKEN,
            refreshToken.value,
            refreshToken.expires
          );
          dispatch({
            type: "SET_ALL",
            payload: {
              token: token.value,
              refreshToken: refreshToken.value,
            },
          });

          resolve(message);
        })
        .catch((error) => {
          // Rejecting the promise in case of an error
          reject(error);
        });
    });
  };

  return {
    onLogin,
    isLoading: loginMutation.isPending,
  };
};
