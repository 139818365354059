import { useCreateServiceMutation } from "../../fetching/mutations";
import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import {
  createServicePropsType,
  createServiceResponseType,
} from "@business-layer/services";

export const useCreateService = () => {
  const { mutateAsync, isPending } = useCreateServiceMutation();
  const {
    state: { token },
  } = useAuthContext();

  function onCreate({
    service,
  }: Omit<
    createServicePropsType,
    "token"
  >): Promise<createServiceResponseType> {
    return new Promise((resolve, reject) => {
      mutateAsync({ service, token })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return {
    onCreate,
    isLoading: isPending,
  };
};
