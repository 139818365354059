import { useRealtimeContext } from "../context";
import { useSocketConnect } from "./connection";
import { getPhoneNumberFromAsyncStorageFunction } from "../helpers/getPhoneNumber";
import { useEffect } from "react";

export function useListen(
  message: string,
  callbackOnMessage: (...args: any) => void,
  isSkipRemoveListeningOnBlur?: boolean
) {
  const {
    state: { socket },
  } = useRealtimeContext();
  const { handleConnect } = useSocketConnect();
  const callback = callbackOnMessage;
  async function handleListen() {
    if (!socket) {
      try {
        const phoneNumber = getPhoneNumberFromAsyncStorageFunction();
        if (phoneNumber) {
          handleConnect(phoneNumber)
            .then((sk) => {
              if (sk) {
                sk.on(message, callback);
              } else {
                throw new Error(
                  "Cannot connect to realtime hub (reconnecting)"
                );
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.error(
            "No phone number detected, cannot reconnect to realtime hub"
          );
        }
      } catch (error) {
        console.error("Cannot reconnect to realtime hub: ", error);
      }

      return;
    } else {
      socket.on(message, callback);
    }
  }

  useEffect(() => {
    console.log("listening to ", message, " ...");
    handleListen();
    return () => {
      if (!isSkipRemoveListeningOnBlur && socket) {
        console.log("free listen message: ", message);
        socket.off(message, callback);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
