export type TColor =
  | "dt_primary"
  | "dt_soft_primary"
  | "dt_black"
  | "dt_gray"
  | "dt_secondary"
  | "dt_light"
  | "dt_warning"
  | "dt_soft"
  | "dt_white"
  | "dt_green"
  | "dt_rose"
  | "dt_blue"
  | "dt_cyan"
  | "dt_orange"
  | "transparent"
  | "inherit";
export type TColorScheme =
  | "whiteAlpha"
  | "blackAlpha"
  | "gray"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "blue"
  | "cyan"
  | "purple"
  | "pink";
export const COLOR_THEME = {
  dt_primary: "#04235A",
  dt_soft_primary: "#3A4885",
  dt_secondary: "#7289F4",
  dt_gray: "#AAAABC",
  dt_light: "#FFF7D6",
  dt_warning: "#CFA616",
  dt_soft: "#F8F8FF",
  dt_white: "#ffffff",
  dt_black: "#000000",

  dt_green: "#028A0F",
  dt_rose: "#e11d48",
  transparent: "transparent",
  inherit: "inherit",
};
