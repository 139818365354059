import {
  ad_banAccountEndpoint,
  ad_getAdminCurrentAccountDetailEndpoint,
  ad_getAllBusinessApproveRequestEndpoint,
  ad_getAllBusinessEndpoint,
  ad_getAllCustomerEndpoint,
  ad_getAllFreelancerApproveRequestEndpoint,
  ad_getAllFreelancerEndpoint,
  ad_getCustomerDetailEndpoint,
  ad_getFreelancerDetailEndpoint,
  ad_unBanAccountEndpoint,
  approveFreelancerEndpoint,
} from "../../config/apis";
import { Services } from "../../service";
import {
  ad_banAccountResponseSchema,
  ad_getAdminCurrentAccountDetailResponseSchema,
  ad_getAllBusinessApproveRequestResponseSchema,
  ad_getAllBusinessResponseSchema,
  ad_getAllCustomerResponseSchema,
  ad_getAllFreelancerApproveRequestResponseSchema,
  ad_getAllFreelancerResponseSchema,
  ad_getCustomerDetailResponseSchema,
  ad_getFreelancerDetailResponseSchema,
  ad_unBanAccountResponseSchema,
  approveFreelancerResponseSchema,
} from "./schema";
import {
  approveFreelancerPropsType,
  approveFreelancerResponseType,
  ad_getAllCustomerResponseType,
  ad_getAllFreelancerResponseType,
  ad_getCustomerDetailResponseType,
  ad_getFreelancerDetailResponseType,
  ad_getAllBusinessApproveRequestResponseType,
  ad_getAllFreelancerApproveRequestResponseType,
  ad_getAdminCurrentAccountDetailResponseType,
  ad_getAllBusinessResponseType,
  ad_banAccountResponseType,
  ad_unBanAccountResponseType,
} from "./type";

export * from "./type";
export class AdminService extends Services {
  approveFreelancer = async ({
    token,
    ...data
  }: approveFreelancerPropsType): Promise<approveFreelancerResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof approveFreelancerResponseSchema,
          approveFreelancerResponseType
        >({
          method: "POST",
          url: approveFreelancerEndpoint,
          schema: approveFreelancerResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAllCustomer = async (
    token: string | null,
    page: number,
    pageSize: number
  ): Promise<ad_getAllCustomerResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getAllCustomerResponseSchema,
          ad_getAllCustomerResponseType
        >({
          method: "GET",
          url: ad_getAllCustomerEndpoint,
          schema: ad_getAllCustomerResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            pageSize,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAllFreelancer = async (
    token: string | null,
    page: number,
    pageSize: number
  ): Promise<ad_getAllFreelancerResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getAllFreelancerResponseSchema,
          ad_getAllFreelancerResponseType
        >({
          method: "GET",
          url: ad_getAllFreelancerEndpoint,
          schema: ad_getAllFreelancerResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            pageSize,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAllBusiness = async (
    token: string | null,
    page: number,
    pageSize: number
  ): Promise<ad_getAllBusinessResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getAllBusinessResponseSchema,
          ad_getAllBusinessResponseType
        >({
          method: "GET",
          url: ad_getAllBusinessEndpoint,
          schema: ad_getAllBusinessResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            pageSize,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getCustomerDetail = async (
    token: string | null,
    id: string
  ): Promise<ad_getCustomerDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getCustomerDetailResponseSchema,
          ad_getCustomerDetailResponseType
        >({
          method: "GET",
          url: ad_getCustomerDetailEndpoint,
          schema: ad_getCustomerDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getFreelancerDetail = async (
    token: string | null,
    id: string
  ): Promise<ad_getFreelancerDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getFreelancerDetailResponseSchema,
          ad_getFreelancerDetailResponseType
        >({
          method: "GET",
          url: ad_getFreelancerDetailEndpoint,
          schema: ad_getFreelancerDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAllFreelancerApproveRequest = async (
    token: string | null,
    page: number,
    pageSize: number
  ): Promise<ad_getAllFreelancerApproveRequestResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getAllFreelancerApproveRequestResponseSchema,
          ad_getAllFreelancerApproveRequestResponseType
        >({
          method: "GET",
          url: ad_getAllFreelancerApproveRequestEndpoint,
          schema: ad_getAllFreelancerApproveRequestResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            pageSize,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAllBusinessApproveRequest = async (
    token: string | null,
    page: number,
    pageSize: number
  ): Promise<ad_getAllBusinessApproveRequestResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_getAllBusinessApproveRequestResponseSchema,
          ad_getAllBusinessApproveRequestResponseType
        >({
          method: "GET",
          url: ad_getAllBusinessApproveRequestEndpoint,
          schema: ad_getAllBusinessApproveRequestResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            pageSize,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  ad_getAdminCurrentAccountDetail = async (
    token: string | null
  ): Promise<ad_getAdminCurrentAccountDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      return await this.fetchApi<
        typeof ad_getAdminCurrentAccountDetailResponseSchema,
        ad_getAdminCurrentAccountDetailResponseType
      >({
        method: "GET",
        url: ad_getAdminCurrentAccountDetailEndpoint,
        schema: ad_getAdminCurrentAccountDetailResponseSchema,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
    } catch (error) {
      throw this.handleError(error);
    }
  };
  banAccount = async ({
    token,
    id,
  }: {
    token: string | null;
    id: string;
  }): Promise<ad_banAccountResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_banAccountResponseSchema,
          ad_banAccountResponseType
        >({
          method: "PUT",
          url: ad_banAccountEndpoint,
          schema: ad_banAccountResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  unBanAccount = async ({
    token,
    id,
  }: {
    token: string | null;
    id: string;
  }): Promise<ad_unBanAccountResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof ad_unBanAccountResponseSchema,
          ad_unBanAccountResponseType
        >({
          method: "PUT",
          url: ad_unBanAccountEndpoint,
          schema: ad_unBanAccountResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
