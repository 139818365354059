import "./commonLoaderStyles.css";

import { Box, Flex } from "@chakra-ui/react";

export function CommonLoader() {
  return (
    <Flex w={"100%"} h={"100%"} justifyContent={"center"} alignItems={"center"}>
      <Box className="common_loader"></Box>
    </Flex>
  );
}
