/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { CellProps, Column } from "react-table";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import CustomTable from "./CustomTable";
import { CommonLoader } from "@presentational/atoms/Loading";
import { I_AD_BusinessAccountDetail } from "@business-layer/services/adminEntities/accounts";
import {
  useAdGetAllBusiness,
  useBanAccount,
  useUnbanAccount,
} from "@business-layer/business-logic/lib/admin";
import { ad_getAllBusinessResponseType } from "@business-layer/services";

const PAGE_SIZE = 5;

const normalizeString = (str: string) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const ManageCompany = () => {
  const { onBan } = useBanAccount();
  const { onUnBan } = useUnbanAccount();
  const toast = useToast();
  const [pageCurrent, setPageCurrent] = useState(0);
  const {
    data: rawData,
    refetch,
    isRefetching,
  } = useAdGetAllBusiness(pageCurrent + 1, PAGE_SIZE);
  const [companyList, setCompanyList] = useState<
    ad_getAllBusinessResponseType | undefined
  >(undefined);
  useEffect(() => {
    setCompanyList(rawData);
  }, [rawData]);
  useEffect(() => {
    if (typeof rawData !== "undefined") {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [state, setState] = useState({
    selectedRow: null,
    selectedCompany: null as I_AD_BusinessAccountDetail | null,
    actionType: "",
  });
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  const columns: Column<I_AD_BusinessAccountDetail>[] = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "companyName",
        width: "20%",
      },
      {
        Header: "MST",
        accessor: "taxIdentificationNumber",
        width: "5%",
      },
      {
        Header: "CEO’s Name",
        accessor: (row) => row.account.fullName,
        width: "15%",
      },
      {
        Header: "Phone Number",
        accessor: (row) => row.account.phone,
        width: "20%",
        Cell: ({ row }: CellProps<I_AD_BusinessAccountDetail>) => (
          <Text fontSize={"0.65rem"}>
            (+{row.original.account.countryCode}) {row.original.account.phone}
          </Text>
        ),
      },
      {
        Header: "Address",
        accessor: "address",
        width: "35%",
        Cell: ({ row }) => (
          <Text align={"center"} fontSize={"0.65rem"} whiteSpace={"normal"}>
            {row.original.address.addressLine}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: (row) => row.account.isActive,
        width: "10%",
        Cell: ({ row }: CellProps<I_AD_BusinessAccountDetail>) => (
          <Text
            color={row.original.account.isActive ? "dt_primary" : "dt_rose"}
            fontSize={"0.65rem"}
            fontWeight={"bold"}
          >
            <i
              className={
                row.original.account.isActive
                  ? "fi fi-sr-shield-check"
                  : "fi fi-sr-shield-exclamation"
              }
            />{" "}
            {row.original.account.isActive ? "Normal" : "Banned"}
          </Text>
        ),
      },
      {
        Header: "",
        accessor: "id",
        width: "5%",
        Cell: ({ row }) => (
          <Menu>
            <MenuButton
              as={Button}
              background={"transparent"}
              onClick={() => handleMenuOpen(row)}
            >
              <i className="fi fi-br-angle-small-down"></i>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleOpenConfirmBox(
                    row.original,
                    row.original.account.isActive ? "Ban" : "Unban"
                  );
                }}
                display="flex"
                justifyContent="center"
              >
                <Text
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                >
                  {row.original.account.isActive
                    ? "Ban this company"
                    : "Unban this company"}
                </Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleOpenConfirmBox(row.original, "Put");
                }}
                display="flex"
                justifyContent="center"
              >
                <Text
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                >
                  Put a seal
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    [] // Ensure dependencies are properly added if needed
  );

  const filteredCompanyList = useMemo(() => {
    if (!searchQuery) return companyList?.data;
    const normalizedQuery = normalizeString(searchQuery);
    return !companyList
      ? []
      : companyList.data.filter((company) => {
          const fullName = normalizeString(company.account.fullName);
          const id = normalizeString(company.id);
          return (
            fullName.includes(normalizedQuery) || id.includes(normalizedQuery)
          );
        });
  }, [searchQuery, companyList]);

  const handleMenuOpen = (index: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: index === prevState.selectedRow ? null : index,
    }));
  };

  const handleOpenConfirmBox = (
    customer: I_AD_BusinessAccountDetail,
    type: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedCompany: customer,
      actionType: type,
    }));
    setIsAlertOpen(true);
  };

  const handleConfirmAction = async () => {
    const { actionType, selectedCompany } = state;
    if (!selectedCompany || (actionType !== "Ban" && actionType !== "Unban")) {
      return;
    }
    const isBanned = actionType === "Ban";
    const promise = isBanned
      ? onBan(selectedCompany.id)
      : onUnBan(selectedCompany.id);

    const toastConfig = {
      success: {
        title: isBanned
          ? "Cấm tài khoản thành công"
          : "Bỏ cấm tài khoản thành công",
      },
      error: {
        title: "Mé lỗi rồi!",
        description: "Hãy thử lại sau hoặc liên hệ team dev nhé",
      },
      loading: {
        title: "Đang thực thi",
        description: "Vui lòng chờ 10 năm...",
      },
    };

    toast.promise(promise, toastConfig);

    setIsAlertOpen(false);
    setState((prevState) => ({
      ...prevState,
      selectedCompany: null,
    }));

    await promise;
    setCompanyList((prev) =>
      prev
        ? {
            ...prev,
            data: prev.data.map((p) => {
              if (p.id === selectedCompany.id) {
                return {
                  ...p,
                  account: {
                    ...p.account,
                    isActive: !isBanned,
                  },
                };
              }
              return p;
            }),
          }
        : prev
    );
  };

  return (
    <Box>
      {filteredCompanyList && companyList && !isRefetching ? (
        <CustomTable
          columns={columns}
          data={filteredCompanyList}
          totalPage={companyList.totalPages}
          pageSize={PAGE_SIZE}
          pageCurrent={pageCurrent}
          onChangePage={(page) => {
            setPageCurrent(page);
          }}
          children={
            <Flex justifyContent={"space-between"}>
              <InputGroup mb={8} bg="dt_soft">
                <InputLeftElement pointerEvents="none" fontSize={"0.75rem"}>
                  <i className="fi fi-br-search"></i>
                </InputLeftElement>
                <Input
                  placeholder="Search by name or id..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    "::placeholder": {
                      color: "dt_gray",
                    },
                    border: "none",
                    fontSize: "0.75rem",
                  }}
                />
              </InputGroup>
            </Flex>
          }
        />
      ) : (
        <CommonLoader />
      )}

      <AlertDialog
        isOpen={isAlertOpen}
        isCentered={true}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {state.actionType === "Ban" ? "Ban Account Customer" : ""}
              {state.actionType === "Unban" ? "Unban Account Customer" : ""}
              {state.actionType === "Put" ? "Put A Seal" : ""}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to{" "}
              {state.actionType === "Ban" ? "ban this company" : ""}
              {state.actionType === "Unban" ? "unban this company" : ""}
              {state.actionType === "Put" ? "put a seal this company" : ""}?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleConfirmAction} colorScheme="blue" ml={3}>
                {state.actionType === "Ban" ? "Ban" : ""}
                {state.actionType === "Unban" ? "Unban" : ""}
                {state.actionType === "Put" ? "Put" : ""}?
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
