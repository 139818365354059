import { useTable, Column, TableOptions } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Flex,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Empty } from "@presentational/atoms/Loading";

interface CustomTableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  children?: ReactNode;
  pageSize?: number;
  pageCurrent?: number;
  totalPage?: number;
  onChangePage?: (page: number) => void;
}

const CustomTable = <T extends object>({
  columns,
  data,
  children,
  pageSize = 5,
  pageCurrent = 0,
  totalPage = 10,
  onChangePage,
}: CustomTableProps<T>) => {
  const { getTableBodyProps, headerGroups, prepareRow, rows } = useTable<T>({
    columns,
    data,
  } as TableOptions<T>);

  const renderPageButtons = () => {
    const totalPages = totalPage;
    const currentPage = pageCurrent + 1;
    const maxButtons = 3; // Number of page buttons to display before showing "..."

    let buttons: React.ReactNode[] = [];

    if (totalPages <= maxButtons) {
      // If the total number of pages is less than or equal to maxButtons, show all pages
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <Button
            key={i}
            onClick={() => onChangePage && onChangePage(i - 1)}
            bg={pageCurrent === i - 1 ? "dt_primary" : "transparent"}
            color={pageCurrent === i - 1 ? "dt_white" : undefined}
            mx={1}
            fontSize={"0.75rem"}
          >
            {i}
          </Button>
        );
      }
      return buttons;
    }

    // Always show the first page button
    buttons.push(
      <Button
        key={1}
        onClick={() => onChangePage && onChangePage(0)}
        bg={pageCurrent === 0 ? "dt_primary" : "transparent"}
        color={pageCurrent === 0 ? "dt_white" : undefined}
        mx={1}
        fontSize={"0.75rem"}
      >
        1
      </Button>
    );

    if (totalPages === 1) {
      return buttons;
    }

    // Show "..." before the last page button if currentPage > maxButtons
    if (currentPage > maxButtons) {
      buttons.push(
        <Button
          key="ellipsis-start"
          isDisabled={true}
          mx={1}
          bgColor={"transparent"}
          _disabled={{
            color: "dt_primary",
          }}
          fontSize={"0.75rem"}
        >
          . . .
        </Button>
      );
    }

    // Calculate which page buttons to show dynamically based on currentPage
    let start = Math.max(2, currentPage - 1);
    let end = Math.min(currentPage + 1, totalPages - 1);

    // Adjust start and end to ensure maxButtons are displayed if possible
    if (end - start + 1 < maxButtons) {
      if (currentPage <= 2) {
        end = Math.min(start + maxButtons - 2, totalPages - 1);
      } else if (currentPage >= totalPages - 1) {
        start = Math.max(end - (maxButtons - 2), 2);
      }
    }

    // Render the page buttons within the calculated range
    for (let i = start; i <= end; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => onChangePage && onChangePage(i - 1)}
          bg={pageCurrent === i - 1 ? "dt_primary" : "transparent"}
          color={pageCurrent === i - 1 ? "dt_white" : undefined}
          mx={1}
          fontSize={"0.75rem"}
        >
          {i}
        </Button>
      );
    }

    // Show "..." after the first page buttons if there are more pages to show
    if (currentPage + 1 < totalPages) {
      buttons.push(
        <Button
          key="ellipsis-end"
          isDisabled={true}
          mx={1}
          bgColor={"transparent"}
          _disabled={{
            color: "dt_primary",
          }}
          fontSize={"0.75rem"}
        >
          . . .
        </Button>
      );
    }

    // Always show the last page button
    buttons.push(
      <Button
        key={totalPages}
        onClick={() => onChangePage && onChangePage(totalPages - 1)}
        bg={pageCurrent === totalPages - 1 ? "dt_primary" : "transparent"}
        color={pageCurrent === totalPages - 1 ? "dt_white" : undefined}
        mx={1}
        fontSize={"0.75rem"}
      >
        {totalPages}
      </Button>
    );

    return buttons;
  };

  return (
    <TableContainer backgroundColor="dt_white" padding={"1rem"} w={"100%"}>
      {children}
      {data.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Table
            variant="striped"
            sx={{
              "thead th": {
                borderBottom: "2px solid #AAAABC",
                textTransform: "none",
                fontSize: "0.65rem",
                color: "dt_gray",
                textAlign: "center",
              },
              "tbody tr:nth-of-type(odd)": {
                "& > td": {
                  backgroundColor: "#FFFFFF",
                },
              },
              "tbody tr:nth-of-type(even)": {
                "& > td": {
                  backgroundColor: "#F8F8FF",
                },
              },
            }}
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps()}
                      sx={{
                        textTransform: "none",
                        color: "dt_gray",
                        textAlign: "center",
                        fontSize: "0.75rem",
                        width: column.width ?? "auto",
                      }}
                      key={`column@${index}`}
                    >
                      {column.render("Header")}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={`row@${rowIndex}`}>
                    {row.cells.map((cell, cellIndex) => (
                      <Td
                        {...cell.getCellProps()}
                        key={`cell@${cellIndex}`}
                        sx={{
                          textTransform: "none",
                          fontSize: "0.65rem",
                          textAlign: "center",
                          fontWeight: "500",
                          width: cell.column.width ?? "auto",
                          whiteSpace: "normal",
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Box mx="auto">
            <Flex m={4} alignItems="center" gap={4} justifyContent={"center"}>
              <Flex>
                <Tooltip label="First Page">
                  <Button
                    onClick={() => onChangePage && onChangePage(0)}
                    isDisabled={pageCurrent === 0}
                    bgColor={"transparent"}
                    fontSize={"0.75rem"}
                  >
                    <i className="fi fi-sr-angle-double-left"></i>
                  </Button>
                </Tooltip>
                <Tooltip label="Previous Page">
                  <Button
                    onClick={() => {
                      onChangePage && onChangePage(pageCurrent - 1);
                    }}
                    isDisabled={pageCurrent === 0}
                    bgColor={"transparent"}
                    fontSize={"0.75rem"}
                  >
                    <i className="fi fi-sr-angle-left"></i>
                  </Button>
                </Tooltip>
              </Flex>

              {/* Page Numbers Section */}
              {renderPageButtons()}

              <Flex>
                <Tooltip label="Next Page">
                  <Button
                    onClick={() => {
                      onChangePage && onChangePage(pageCurrent + 1);
                    }}
                    isDisabled={pageCurrent === totalPage - 1}
                    bgColor={"transparent"}
                    fontSize={"0.75rem"}
                  >
                    <i className="fi fi-sr-angle-right"></i>
                  </Button>
                </Tooltip>
                <Tooltip label="Last Page">
                  <Button
                    onClick={() => {
                      onChangePage && onChangePage(totalPage - 1);
                    }}
                    isDisabled={pageCurrent === totalPage - 1}
                    bgColor={"transparent"}
                    fontSize={"0.75rem"}
                  >
                    <i className="fi fi-sr-angle-double-right"></i>
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </Box>
        </>
      )}
    </TableContainer>
  );
};

export default CustomTable;
