import {
  FreelancerAccountDetailSchema,
  FreelancerPreviewSchema,
  CustomerAccountDetailSchema,
  MessagePreviewSchema,
  MessageSchema,
} from "@business-layer/services/entities";
import z from "zod";

const getFreelancerPreviewDataResponseSchema = FreelancerPreviewSchema;
const getFreelancerDetailResponseSchema = FreelancerAccountDetailSchema;

const getCustomerCurrentAccountDetailResponseSchema =
  CustomerAccountDetailSchema;

const updateAccountDetailResponseSchema = z.object({
  message: z.string(),
});
const getAllNotificationsResponseSchema = z.array(
  z.object({
    date: z.number(),
    request: z.object({
      content: z.any(),
      identifier: z.any(),
      trigger: z.any(),
    }),
  })
);
const getChatAllChatPreviewResponseSchema = z.array(MessagePreviewSchema);
const getConversationDetailResponseSchema = z.array(MessageSchema);
export {
  getFreelancerPreviewDataResponseSchema,
  getFreelancerDetailResponseSchema,
  getCustomerCurrentAccountDetailResponseSchema,
  updateAccountDetailResponseSchema,
  getAllNotificationsResponseSchema,
  getChatAllChatPreviewResponseSchema,
  getConversationDetailResponseSchema,
};
