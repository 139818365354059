import { IMessage } from '@business-layer/services/entities';
import { GLOBAL_MSG_KEYS } from '../../config';
import { useListen } from '../useListen';

export function useListenChatMessage({
  callback,
}: {
  callback: (message: IMessage) => void;
}) {
  useListen(GLOBAL_MSG_KEYS.LISTEN_CHAT, callback);
}
