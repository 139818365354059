// Importing necessary modules and functions
import { uploadReturnType } from '@business-layer/services';
import { useUploadToBunnyMutation } from '../../fetching/mutation';
import { MIME_TYPES } from '@constants/mimeTypes';
import { BASE_IMG_CDN } from '@constants/cdn';
import { useState } from 'react';

export type mediaUploadType = {
  uri: string;
  path: string;
  name: string;
  type: keyof typeof MIME_TYPES;
};
export const useUpload = () => {
  const uploadMutation = useUploadToBunnyMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const readFileAsBuffer = async (uri: string): Promise<ArrayBuffer | null> => {
    const media = await fetch(uri);
    const fileBlob = await media.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event.target?.result as ArrayBuffer | null);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(fileBlob);
    });
  };

  const onUpload = ({
    uri,
    path,
    name,
    type,
  }: mediaUploadType): Promise<uploadReturnType> => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const extension =
        MIME_TYPES[type]?.split('/')[1] || MIME_TYPES.PNG.split('/')[1];
      const pathname = `${path}/${name}.${extension}`;
      readFileAsBuffer(uri)
        .then((fileBuffer) => {
          if (fileBuffer) {
            return uploadMutation.mutateAsync({
              fileBuffer: fileBuffer,
              name: pathname,
              type: extension,
            });
          } else {
            throw new Error('Lỗi xử lý ảnh!');
          }
        })
        .then((res) => {
          if (!res) {
            resolve({
              path: pathname.replace(BASE_IMG_CDN, ''),
              type: MIME_TYPES[type],
            });
          } else {
            resolve({
              path: res.path.replace(BASE_IMG_CDN, ''),
              type: res.type,
            });
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => setIsLoading(false));
    });
  };

  return {
    onUpload,
    isLoading,
  };
};
