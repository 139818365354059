import { IMessage } from "@business-layer/services/entities";
import { GLOBAL_MSG_KEYS } from "../../config";
import { useInvoker } from "../useInvoker";
import { useListenChatMessage } from "./useListenChatMessage";

export const useChat = (onListenMessage: (message: IMessage) => void) => {
  const { invoke, isLoading } = useInvoker();
  useListenChatMessage({ callback: onListenMessage });

  const onChat = (
    data: {
      id: string;
      sendTo: string;
      isCustomerService?: boolean;
    } & Pick<IMessage, "content" | "image" | "isSystem">
  ): Promise<any> => {
    return new Promise((rs, rj) => {
      console.log("Invoke data: ", data);
      invoke(GLOBAL_MSG_KEYS.CHAT, data)
        .then(({ status, message, data }) => {
          if (status) {
            rs(data);
          } else {
            rj(new Error(message));
          }
        })
        .catch((error) => {
          rj(error);
        });
    });
  };

  return {
    onChat,
    isLoading,
  };
};
