import {
  AuthService,
  loginResponseType,
  loginParamsType,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

// Initialize the AuthService
const authService = new AuthService();

export const useLoginMutation = () => {
  return useMutation<loginResponseType, Error, loginParamsType, unknown>({
    mutationFn: authService.login,
  });
};
