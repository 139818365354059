export const FREELANCER_MSG_KEYS = {
  PROVIDE_QUOTATION: 'SendMessageToCustomer',
  SEND_COORD_TO_CUSTOMER: 'SendMovingStatusToCustomer',
  LISTEN_CUSTOMER_CHOOSE_FOR_MATCHING_ORDER: 'ReceiveConfirmCustomerOrder',
};
export const CUSTOMER_MSG_KEYS = {
  LISTEN_MATCHING: 'ReceiveFreelancerResponse',
  LISTEN_UPDATE_ORDER_STATUS: 'ReceiveFreelancerStatusResponse',
  LISTEN_FREELANCER_COORD_CHANGE: 'ReceiveFreelancerPositionResponse',
};
export const GLOBAL_MSG_KEYS = {
  ON_ERROR: 'ErrorOccurred',
  CHAT: 'SendChatMessageToAccount',
  LISTEN_CHAT: 'ReceiveRealtimeMessageFromAccount',
  LISTEN_NEGOTIATION: 'ReceiveConfirmCustomerOrderPrice',
};
