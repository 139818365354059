export const APP_URLS = {
  BLANK: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CATEGORY_AND_SERVICE: "/category-and-service",
  CATEGORY_DETAIL: "/category-detail",
  ADD_CATEGORY: "/add",
  ADD_SERVICE: "/add",
  ACCOUNTS: "/accounts",
  FREELANCER_APPROVE: "/freelancer-approve",
  COMPANY_APPROVE: "/company-approve",
  CSKH: "/cskh",
};
