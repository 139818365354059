import { Box, Flex } from "@chakra-ui/react";
import { MainHeader } from "@presentational/organisms/header";
import { VerticalTabBar } from "@presentational/organisms/navbar";

type props = {
  children: React.ReactNode;
};
export const MainTemplate: React.FC<props> = ({ children }) => {
  return (
    <Flex
      flexDirection={"row"}
      w={"100vw"}
      minH={"100vh"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      bg={"dt_soft"}
    >
      <Box w={350} h={"100vh"}>
        <VerticalTabBar />
      </Box>
      <Box flexGrow={1} mr={"1rem"} minH={"100vh"} width={"100%"}>
        <MainHeader />
        {children}
      </Box>
    </Flex>
  );
};
