import {
  CategorySchema,
  UIAdditionServiceRequirementSchema,
  UIServiceRequirementSchema,
  ServiceSchema,
  SearchServiceSchema,
} from "@business-layer/services/entities";
import z from "zod";

const getAllCategoryResponseSchema = z.array(CategorySchema);

const getCategoryDetailResponseSchema = CategorySchema.and(
  z.object({
    serviceTypes: z.array(ServiceSchema),
  })
);

const getServiceDetailResponseSchema = ServiceSchema.and(
  z.object({
    requirements: z.array(UIServiceRequirementSchema),
    additionalRequirements: z.array(UIAdditionServiceRequirementSchema),
  })
);

const addMultipleServiceTypeSchema = z.object({
  message: z.string(),
});

const searchCategoriesAndServicesSchema = z.object({
  categories: z.array(CategorySchema),
  services: z.array(SearchServiceSchema),
});

const deleteServiceResponseSchema = z.any();
const deleteCategoryResponseSchema = z.any();
const createServiceResponseSchema = z.any();
const createCategoryResponseSchema = z.any();
export {
  getAllCategoryResponseSchema,
  getCategoryDetailResponseSchema,
  getServiceDetailResponseSchema,
  addMultipleServiceTypeSchema,
  searchCategoriesAndServicesSchema,
  deleteServiceResponseSchema,
  deleteCategoryResponseSchema,
  createServiceResponseSchema,
  createCategoryResponseSchema,
};
