import { useMutation } from "@tanstack/react-query";
import {
  AdminService,
  ad_banAccountResponseType,
  ad_getAdminCurrentAccountDetailResponseType,
  ad_unBanAccountResponseType,
  approveFreelancerPropsType,
  approveFreelancerResponseType,
} from "@business-layer/services";

const service = new AdminService();

export const useApproveFreelancerMutation = () => {
  return useMutation<
    approveFreelancerResponseType,
    Error,
    approveFreelancerPropsType,
    unknown
  >({
    mutationFn: service.approveFreelancer,
  });
};
export const useGetAdminCurrentAccountDetailMutation = () => {
  return useMutation<
    ad_getAdminCurrentAccountDetailResponseType,
    Error,
    string,
    unknown
  >({
    mutationFn: service.ad_getAdminCurrentAccountDetail,
  });
};
export const useBanAccountMutation = () => {
  return useMutation<
    ad_banAccountResponseType,
    Error,
    {
      token: string | null;
      id: string;
    },
    unknown
  >({
    mutationFn: service.banAccount,
  });
};
export const useUnBanAccountMutation = () => {
  return useMutation<
    ad_unBanAccountResponseType,
    Error,
    {
      token: string | null;
      id: string;
    },
    unknown
  >({
    mutationFn: service.unBanAccount,
  });
};
