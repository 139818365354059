import { useGetOverallAnalytics } from "@business-layer/business-logic/lib/analytics";
import { isDevelopment } from "@business-layer/services";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Shipping } from "@presentational/atoms/Loading";
import { COLOR_THEME } from "@presentational/themes/colors";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const origin = window.location.origin;
const REQUEST_DETAIL_URL = [
  `${origin}/freelancer-approve`,
  `${origin}/company-approve`,
  ...(isDevelopment()
    ? [
        "https://docs.google.com/spreadsheets/d/1Wmr6jjGGlajfkPD4btXpmKGHd8ZbrZLFIfN2J2PJTog/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/1UJjDm4Z7iTSCCqY7vTZ9wNbqaaBHlYb5Q38R8fS4G_o/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/12V6TcJ_97tT7ryKRO3mNhSUa2M-yFxGc-ZJJuaxBAv8/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/1SMXRii1MJ0mN1x0MyDIVRnrnqpOihsqPoFblmpQX91E/edit?usp=drive_link",
      ]
    : [
        "https://docs.google.com/spreadsheets/d/1gv0nmgdfoIexW4D0UB-Q7u5r3Cx7-jK91gK2dMLYJxc/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/1-EI1lzDetE-0kVWfFUq4UfH70mq7IcNRnzUYvMpRStg/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/1HCzTFJQSAc5rYkdtXOBjpjk_D2rZ7gVQDIroe0nNt2w/edit?usp=drive_link",
        "https://docs.google.com/spreadsheets/d/1jz7YnIVKHpDL7XoknyZl6_-pS1lmUzMs0jzTfxTGMfg/edit?usp=drive_link",
      ]),
];

export const Dashboard = () => {
  const { data } = useGetOverallAnalytics({
    income: {
      // -> query data cho danh mục lợi nhuận
      month: 5, // -> query data thuộc tháng nào năm nào
      year: 2024,
    },
    discount: {
      // -> query data cho danh mục khuyến mãi (Chưa cài đặt)
      month: 5,
      year: 2024,
    },
    newUsers: {
      // -> Thống kê người dùng
      month: 5,
      year: 2024,
    },
    overview: {
      // Thống kê tổng quan
      month: 5,
      year: 2024,
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [overallAnalyticsData, setOverallAnalyticsData] = useState<{
    [key: string]: { series: ApexOptions["series"]; options: ApexOptions };
  }>({
    userAnalytics: {
      series: [],
      options: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
          },
        },
        colors: [
          COLOR_THEME.dt_primary,
          COLOR_THEME.dt_secondary,
          COLOR_THEME.dt_rose,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "User Count",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
      },
    },
    requestApprove: {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 380,
          events: {
            dataPointSelection: (e, chartContext, config) => {
              const columnIndex = config.dataPointIndex;
              window.open(REQUEST_DETAIL_URL[columnIndex], "_blank");
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        colors: [
          COLOR_THEME.dt_primary,
          COLOR_THEME.dt_soft_primary,
          COLOR_THEME.dt_secondary,
          COLOR_THEME.dt_green,
          COLOR_THEME.dt_warning,
          COLOR_THEME.dt_rose,
        ],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            color: COLOR_THEME.dt_black,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Freelancer Request",
            "Company Request",
            "Feedbacks",
            "More Service",
            "Report Errors",
            "Complains",
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    },
    categoryAndService: {
      series: [],
      options: {
        chart: {
          width: 380,
          type: "polarArea",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            shadeTo: "dark",
            shadeIntensity: 1,
          },
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + "%";
            },
          },
        },
      },
    },
    orderAnalystic: {
      series: [
        {
          name: "Đơn đã tạo",
          data: [],
        },
        {
          name: "Đơn thành công",
          data: [],
        },
        {
          name: "Đơn bị huỷ",
          data: [],
        },
        {
          name: "Số khiếu nại",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },

          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: [
          COLOR_THEME.dt_primary,
          COLOR_THEME.dt_green,
          COLOR_THEME.dt_warning,
          COLOR_THEME.dt_rose,
        ],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Order Analystic",
          align: "left",
          offsetY: 20,
          style: {
            color: COLOR_THEME.dt_primary,
            fontSize: "1rem",
            fontWeight: 700,
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Dec",
          ],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          min: 0,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    },
  });
  useEffect(() => {
    if (data) {
      // User analytics
      const dataClone = { ...overallAnalyticsData };
      dataClone.userAnalytics.series = data.newUsers.map(
        ({ name, statistics }) => ({
          name,
          data: statistics.map((s) => s.usageCount),
        })
      );

      // Request Approve
      dataClone.requestApprove.series = [
        {
          data: [
            data.needResolve.freelancerRequestCount,
            data.needResolve.companyRequestCount,
            data.feedback.feedbackCount,
            data.feedback.serviceRequestCount,
            data.feedback.systemErrorCount,
            data.needResolve.unresolvedReportCount,
          ],
        },
      ];

      // Category & Service
      let categoryAndServiceSeries: any[] = [];
      let categoryAndServiceLabel: any[] = [];
      data.popularServices.statistics
        .slice(0, 10)
        .forEach(({ percentage, serviceName }) => {
          categoryAndServiceSeries.push(percentage);
          categoryAndServiceLabel.push(serviceName);
        });
      dataClone.categoryAndService.series = categoryAndServiceSeries;
      dataClone.categoryAndService.options.labels = categoryAndServiceLabel;

      // Order
      let maxValue = 0;
      let createdCountInYear: any[] = [];
      let completedCountInYear: any[] = [];
      let canceledCountInYear: any[] = [];
      let complainsCountInYear: any[] = [];
      data.orderStatistic.forEach(
        ({ createdCount, completedCount, canceledCount, complainsCount }) => {
          [createdCount, completedCount, canceledCount, complainsCount].forEach(
            (v) => v > maxValue && (maxValue = v)
          );
          createdCountInYear.push(createdCount);
          completedCountInYear.push(completedCount);
          canceledCountInYear.push(canceledCount);
          complainsCountInYear.push(complainsCount);
        }
      );
      dataClone.orderAnalystic.series = [
        {
          name: "Đơn đã tạo",
          data: createdCountInYear,
        },
        {
          name: "Đơn thành công",
          data: completedCountInYear,
        },
        {
          name: "Đơn bị huỷ",
          data: canceledCountInYear,
        },
        {
          name: "Số khiếu nại",
          data: complainsCountInYear,
        },
      ];
      (dataClone.orderAnalystic.options.yaxis as any).max = maxValue + 10;

      // Set new analytics data
      setOverallAnalyticsData(dataClone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Flex
      flexDir={"row"}
      gap={"0.75rem"}
      p={"1rem"}
      height={`calc(100vh - 7rem)`}
      flexWrap={"wrap"}
    >
      <Box
        bgColor={"dt_white"}
        borderRadius={"0.75rem"}
        padding={"1rem"}
        width={"65%"}
        height={"50%"}
        boxShadow={"md"}
      >
        <Text
          color={"dt_primary"}
          fontSize={"1rem"}
          fontWeight={700}
          mb={"0.5rem"}
        >
          User Analytics
        </Text>{" "}
        {Array.isArray(overallAnalyticsData.categoryAndService.series) &&
        overallAnalyticsData.categoryAndService.series.length > 0 ? (
          <ReactApexChart
            {...overallAnalyticsData.userAnalytics}
            type="bar"
            height={"90%"}
            width={"100%"}
          />
        ) : (
          <Shipping />
        )}
      </Box>
      <Box
        bgColor={"dt_white"}
        borderRadius={"0.75rem"}
        padding={"1rem"}
        width={"calc(35% - 0.75rem)"}
        height={"50%"}
        boxShadow={"md"}
      >
        <Text
          color={"dt_primary"}
          fontSize={"1rem"}
          fontWeight={700}
          mb={"0.5rem"}
        >
          Request Approve
        </Text>

        {Array.isArray(overallAnalyticsData.categoryAndService.series) &&
        overallAnalyticsData.categoryAndService.series.length > 0 ? (
          <ReactApexChart
            {...overallAnalyticsData.requestApprove}
            type="bar"
            height={"90%"}
            width={"100%"}
          />
        ) : (
          <Shipping />
        )}
      </Box>
      <Box
        bgColor={"dt_white"}
        borderRadius={"0.75rem"}
        padding={"1rem"}
        width={"calc(40% - 0.75rem)"}
        height={"50%"}
        boxShadow={"md"}
      >
        <Text
          color={"dt_primary"}
          fontSize={"1rem"}
          fontWeight={700}
          mb={"0.5rem"}
        >
          Category & Service
        </Text>

        {Array.isArray(overallAnalyticsData.categoryAndService.series) &&
        overallAnalyticsData.categoryAndService.series.length > 0 ? (
          <ReactApexChart
            {...overallAnalyticsData.categoryAndService}
            type="pie"
            height={"90%"}
            width={"100%"}
          />
        ) : (
          <Shipping />
        )}
      </Box>
      <Box
        bgColor={"dt_white"}
        borderRadius={"0.75rem"}
        padding={"1rem"}
        width={"60%"}
        height={"50%"}
        boxShadow={"md"}
      >
        {Array.isArray(overallAnalyticsData.categoryAndService.series) &&
        overallAnalyticsData.categoryAndService.series.length > 0 ? (
          <ReactApexChart
            {...overallAnalyticsData.orderAnalystic}
            type="line"
            height={"100%"}
            width={"100%"}
          />
        ) : (
          <Shipping />
        )}
      </Box>
    </Flex>
  );
};
