import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useAd_getAllFreelancerApproveRequestQuery } from "../../fetching/query";

export const useAdGetAllFreelancerApproveRequest = (
  page: number,
  pageSize: number
) => {
  const {
    state: { token },
  } = useAuthContext();
  const { data, refetch, isRefetching } =
    useAd_getAllFreelancerApproveRequestQuery(token, page, pageSize);
  return {
    data,
    isRefetching,
    refetch,
  };
};
