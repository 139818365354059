import {
  useDeleteService,
  useGetCategoryDetail,
} from "@business-layer/business-logic/lib/category";
import { addressRequireOptionType } from "@business-layer/services/entities";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ADDRESS_TYPE } from "@constants/addressType";
import { APP_URLS } from "@constants/urls";
import { getCDNUrl } from "@utils/helpers";
import { useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function getAddressTypeGoodName(type: addressRequireOptionType) {
  return ADDRESS_TYPE[type];
}
function getAddressTypeTagColor(type: addressRequireOptionType) {
  switch (type) {
    case "none":
      return "dt_gray";
    case "destination":
      return "dt_warning";
    case "shipping":
      return "dt_secondary";
  }
}

function CategoryDetail() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const {
    data: categoryDetail,
    isRefetching,
    refetch,
  } = useGetCategoryDetail(id ?? "");
  const navigate = useNavigate();
  const { onDelete } = useDeleteService();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();
  const deleteTargetRef = useRef<string | null>(null);

  function handleDeleteService() {
    if (deleteTargetRef.current) {
      const deletePromise = onDelete({ id: deleteTargetRef.current });

      toast.promise(deletePromise, {
        success: (_) => {
          refetch();
          return { title: "Success", description: "Service has been deleted" };
        },
        error: (error) => {
          console.error(error);
          return { title: "Fail", description: "Cannot delete service" };
        },
        loading: { title: "Deleting...", description: "Please wait" },
      });
    }
  }
  return (
    <Box w={"100%"} padding={"1rem"}>
      {categoryDetail && !isRefetching ? (
        <>
          <Flex
            bg={"white"}
            padding={"1rem"}
            flexDir={"row"}
            gap={"1rem"}
            borderRadius={"xl"}
            boxShadow={"md"}
          >
            <Box h={200}>
              <Image
                src={getCDNUrl(categoryDetail.image)}
                alt={categoryDetail.name}
                borderRadius="lg"
                h={"100%"}
                aspectRatio={1}
                w={"auto"}
              />
            </Box>
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Box>
                <Heading as={"h1"} size={"md"} color={"dt_primary"}>
                  {categoryDetail.name}
                </Heading>
                <Text color={"dt_primary"}>{categoryDetail.description}</Text>
              </Box>
              <Flex
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={"0.75rem"}
              >
                <Button
                  bg="dt_soft"
                  color={"dt_primary"}
                  px={"1rem"}
                  boxShadow={"md"}
                  _hover={{
                    bg: "dt_primary",
                    color: "dt_white",
                  }}
                >
                  <i
                    className="fi fi-sr-select"
                    style={{ fontSize: "0.75rem" }}
                  ></i>
                  <Text fontSize={"0.75rem"} fontWeight={800} ml={"0.3rem"}>
                    Edit
                  </Text>
                </Button>
                <Button
                  bg="dt_soft"
                  color={"dt_primary"}
                  px={"1rem"}
                  boxShadow={"base"}
                  onClick={() => navigate(pathname + APP_URLS.ADD_SERVICE)}
                  _hover={{
                    bg: "dt_primary",
                    color: "dt_white",
                  }}
                >
                  <Text fontSize={"0.75rem"} fontWeight={800}>
                    + Add service
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Text
            color={"dt_primary"}
            fontWeight={700}
            fontSize={"0.75rem"}
            my={"0.75rem"}
          >
            Service list
          </Text>
          <Grid
            w={"100%"}
            templateColumns={{ xl: "repeat(3, 1fr)", "2xl": "repeat(4, 1fr)" }}
            gap={3}
          >
            {categoryDetail.serviceTypes.map((c) => (
              <GridItem w="100%" key={c.id}>
                <Card
                  maxW="sm"
                  height={"100%"}
                  cursor={"pointer"}
                  // onClick={() =>
                  //   navigate(`${APP_URLS.CATEGORY_DETAIL}?id=${c.id}`)
                  // }
                >
                  <CardBody>
                    <Flex
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                      h={"100%"}
                    >
                      <Box flexGrow={1}>
                        <Image
                          src={getCDNUrl(c.image)}
                          alt="Green double couch with wooden legs"
                          borderRadius="lg"
                        />
                        <Stack mt="3" spacing="1">
                          <Heading
                            size="sm"
                            fontWeight={"800"}
                            color={"dt_primary"}
                          >
                            {c.name}
                          </Heading>
                          <Text color={"dt_primary"} align={"justify"}>
                            {c.description}
                          </Text>
                        </Stack>
                      </Box>
                      <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Tag
                          bg={getAddressTypeTagColor(c.addressRequireOption)}
                          borderRadius={"md"}
                          w={"fit-content"}
                          color={"dt_white"}
                          fontSize={"0.65rem"}
                        >
                          {getAddressTypeGoodName(c.addressRequireOption)}
                        </Tag>
                        <Button
                          color={"dt_rose"}
                          px={0}
                          justifyContent={"center"}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteTargetRef.current = c.id;
                            onOpen();
                          }}
                          _hover={{
                            transform: "scale(1.1)",
                          }}
                        >
                          <i className="fi fi-sr-trash" />
                        </Button>
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Flex
            bg={"white"}
            padding={"1rem"}
            flexDir={"row"}
            gap={"1rem"}
            borderRadius={"xl"}
            boxShadow={"md"}
          >
            <Skeleton w={200} h={200} startColor="dt_gray" endColor="dt_soft" />
          </Flex>
        </>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          deleteTargetRef.current = null;
          onClose();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Service
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  deleteTargetRef.current = null;
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleDeleteService();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default CategoryDetail;
