import { z } from 'zod';

export interface IServiceStatus {
  id: string;
  name: string;
  icon: string;
}

export const ServiceStatusSchema = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
});
