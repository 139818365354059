import { IOption } from "./option";
import { validationType } from "@utils/validators/yup";
import { z } from "zod";

export type validationWithIdType = ({ id: string } & validationType)[];
const validationWithIdSchema = z.array(
  z.union([
    z.object({
      id: z.string(),
      name: z.literal("required"),
      value: z.null(),
      message: z.string(),
    }),
    z.object({
      id: z.string(),
      name: z.literal("min"),
      value: z.number(),
      message: z.string(),
    }),
    z.object({
      id: z.string(),
      name: z.literal("max"),
      value: z.number(),
      message: z.string(),
    }),
  ])
);

export type infoType =
  | {
      type: "stepper";
      label: string;
      mask?: string;
      defaultValue: number;
      validation: validationWithIdType;
    }
  | {
      type: "button_group";
      label: string;
      buttons: string[]; // min 1 element
      validation: validationWithIdType;
    };
// inputMethod
type inputMethodType =
  | { name: "input" }
  | { name: "image_picker" }
  | {
      name: "select";
      options: IOption[]; // min 1 element
    }
  | {
      name: "special_item_select";
      options: {
        name: string;
        info: infoType[]; // min 1 element
      }[];
    }
  | {
      name: "add_to_list";
    };

// const InputMethodSchema = z.union([
//   z.object({
//     name: z.literal('input'),
//   }),
//   z.object({
//     name: z.literal('select'),
//     options: z.array(OptionSchema),
//     // .min(1)
//   }),
//   z.object({
//     name: z.literal('special_item_select'),
//     options: z.array(
//       z.object({
//         name: z.string(),
//         info: z.array(
//           z.union([
//             z.object({
//               type: z.literal('stepper'),
//               label: z.string(),
//               mask: z.string().optional(),
//               defaultValue: z.number(),
//               validation: validationWithIdSchema,
//             }),
//             z.object({
//               type: z.literal('button_group'),
//               label: z.string(),
//               buttons: z.array(z.string()),
//               // .min(1)
//               validation: validationWithIdSchema,
//             }),
//           ])
//         ),
//         // .min(1),
//       })
//     ),
//     // .min(1),
//   }),
//   z.object({
//     name: z.literal('add_to_list'),
//   }),
// ]);
const InputMethodSchema = z.any();
export type variableDataType = "number" | "text" | "money";
export interface IUIServiceRequirement {
  id: string;
  inputMethod: {
    dataType: variableDataType;
    method: inputMethodType;
    validation: validationWithIdType;
  };
  label: string;
  labelIcon: string | null;
  placeholder: string;
  key: string;
}
export const UIServiceRequirementSchema = z.object({
  id: z.string(),
  inputMethod: z.object({
    dataType: z.enum(["number", "text"]),
    method: InputMethodSchema,
    validation: validationWithIdSchema,
  }),
  label: z.string(),
  labelIcon: z.string().nullable(),
  placeholder: z.string(),
  key: z.string(),
});

export interface IUIAdditionServiceRequirement {
  id: string;
  icon: string;
  label: string;
  autoSelect: boolean;
  key: string;
}
export const UIAdditionServiceRequirementSchema = z.object({
  id: z.string(),
  icon: z.string(),
  label: z.string(),
  autoSelect: z.boolean(),
  key: z.string(),
});
