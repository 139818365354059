import { AddressSchema, IAddress } from './address';
import { IService, ServiceSchema } from './service';
import { z } from 'zod';
import { IServiceStatus, ServiceStatusSchema } from './serviceStatus';

export interface IOrderElement {
  name: string;
  dataType: string;
  value: string;
}
export const OrderElementSchema = z.object({
  name: z.string(),
  dataType: z.string(),
  value: z.string(),
});

export interface IPostOrderFixedContent {
  address: Omit<IAddress, 'id'>[];
  startTime: string;
  startDate: string;
}
export const PostOrderFixedContentSchema = z.object({
  address: AddressSchema.omit({ id: true }),
  startTime: z.string(),
  startDate: z.string(),
});

export interface IPostOrderServiceContent {
  serviceTypeId: string;
  note: string;
  additionalNote: string;
  requirement: {
    icon: string | null;
    key: string;
    value: string[];
    label: string;
  }[];
  additionalRequirement: {
    icon: string | null;
    key: string;
    value: string;
    label: string;
  }[];
}
export const PostOrderServiceContentSchema = z.object({
  serviceTypeId: z.string(),
  note: z.string(),
  additionalNote: z.string(),
  requirement: z.array(
    z.object({
      icon: z.string().nullable(),
      key: z.string(),
      value: z.array(z.string()),
      label: z.string(),
    })
  ),
  additionalRequirement: z.array(
    z.object({
      icon: z.string().nullable(),
      key: z.string(),
      value: z.string(),
      label: z.string(),
    })
  ),
});

export interface IOrderDetail {
  id: string;
  address: IAddress[];
  estimatedPrice: number;
  recommendPrice: number;
  startTime: string;
  startDate: string;
  finishTime: string | null;
  finishDate: string | null;
  isCustomerRated: boolean;
  quotedFreelancerCount: number;
  freelancer: {
    id: string;
    fullName: string;
    avatar: string;
    rating: number;
  } | null;
  serviceStatus: string;
  serviceStatusList: IServiceStatus[];
  serviceTypes: IService[];
  services: IPostOrderServiceContent & { id: string };
}
export const OrderDetailSchema = z.object({
  id: z.string(),
  address: z.array(AddressSchema),
  estimatedPrice: z.number().nullable(),
  recommendPrice: z.number(),
  startTime: z.string(),
  startDate: z.string(),
  finishTime: z.string().nullable(),
  finishDate: z.string().nullable(),
  isCustomerRated: z.boolean().optional(),
  quotedFreelancerCount: z.number().optional(),
  freelancer: z
    .object({
      id: z.string(),
      fullName: z.string(),
      avatar: z.string(),
      rating: z.number(),
    })
    .nullable(),
  serviceStatus: z.string(),
  serviceStatusList: z.array(ServiceStatusSchema),
  serviceTypes: z.array(ServiceSchema).nullable(),
  services: PostOrderServiceContentSchema.merge(z.object({ id: z.string() })),
});

export interface IAuctionOrderDetail extends IOrderDetail {
  previewPrice: number;
}

export const AuctionOrderDetailSchema = OrderDetailSchema.merge(
  z.object({
    previewPrice: z.number(),
  })
);
