import "./simpleLoaderStyles.css";
function SimpleLoader() {
  return (
    <div className="simple-dot-spinner">
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
      <div className="simple-dot-spinner__dot"></div>
    </div>
  );
}

export default SimpleLoader;
