import { z } from "zod";
import { IService, ServiceSchema } from "./service";

export interface ICategory {
  id: string;
  name: string;
  image: string;
  description: string;
  keys: string[];
  serviceTypeCount: number;
}
export const CategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  description: z.string(),
  keys: z.array(z.string()),
  serviceTypeCount: z.number(),
});

export interface ICategoryDetail extends ICategory {
  serviceTypes: IService[];
}
export const CategoryDetailSchema = CategorySchema.extend({
  serviceTypes: z.array(ServiceSchema),
});
