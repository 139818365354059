import { useMutation } from '@tanstack/react-query';
import {
  CDNService,
  deleteFileFromBunnyCDNReturnType,
} from '@business-layer/services/lib';
import {
  uploadParamsType,
  uploadReturnType,
} from '@business-layer/services/lib';

const service = new CDNService();

export const useUploadToSirvMutation = () => {
  return useMutation<uploadReturnType, Error, uploadParamsType, unknown>({
    mutationFn: service.uploadFileToSirv,
  });
};
export const useUploadToBunnyMutation = () => {
  return useMutation<uploadReturnType, Error, uploadParamsType, unknown>({
    mutationFn: service.uploadFileToBunny,
  });
};
export const useDeleteFromBunnyMutation = () => {
  return useMutation<deleteFileFromBunnyCDNReturnType, Error, string, unknown>({
    mutationFn: service.deleteFileFromBunny,
  });
};
