import { AddressSchema, IAddress } from './address';
import { IService, ServiceSchema } from './service';
import { IServiceProven, ServiceProvenSchema } from './serviceProven';
import { ISkill, SkillSchema } from './skill';
import { z } from 'zod';

export interface IAccount {
  id: string;
  countryCode: string;
  phone: string;
}
export const AccountSchema = z.object({
  id: z.string(),
  countryCode: z.string(),
  phone: z.string(),
});

export interface IAccountDetail {
  id: string;
  avatar: string;
  fullName: string;
  dateOfBirth: string | null;
  countryCode: string;
  phone: string;
  role: string;
}
export const AccountDetailSchema = z.object({
  id: z.string(),
  avatar: z.string(),
  fullName: z.string(),
  dateOfBirth: z.string().nullable(),
  countryCode: z.string(),
  phone: z.string(),
  role: z.string(),
});

export interface ICustomerAccountDetail extends IAccountDetail {}
export const CustomerAccountDetailSchema = AccountDetailSchema.merge(
  z.object({})
);

export interface IFreelancerPreview {
  id: string;
  fullName: string;
  avatar: string;
  balance: number;
}
export const FreelancerPreviewSchema = z.object({
  id: z.string(),
  fullName: z.string(),
  avatar: z.string(),
  balance: z.number(),
});

export interface IFreelancerAccountDetail {
  accountId: string;
  account: IAccountDetail;
  address: IAddress;
  rating: number;
  totalReviewCount: number;
  balance: number;
  orderCount: number;
  loveCount: number;
  positiveReviewCount: number;
  identityNumber: string;
  isTeam: boolean;
  teamMemberCount: number;
  skills: ISkill[];
  freelancerFeasibleServices: IService[];
  description: string;
  serviceProven: IServiceProven[];
  isVerified: boolean;
}
export const FreelancerAccountDetailSchema = z.object({
  accountId: z.string(),
  account: AccountDetailSchema,
  address: AddressSchema,
  rating: z.number(),
  totalReviewCount: z.number(),
  balance: z.number(),
  orderCount: z.number(),
  loveCount: z.number(),
  positiveReviewCount: z.number(),
  identityNumber: z.string(),
  isTeam: z.boolean(),
  teamMemberCount: z.number(),
  skills: z.array(SkillSchema),
  freelancerFeasibleServices: z.array(ServiceSchema),
  description: z.string(),
  serviceProven: z.array(ServiceProvenSchema),
  isVerified: z.boolean(),
});

export interface IMatchingFreelancerAccountDetail {
  accountId: string;
  account: IAccountDetail;
  address: IAddress;
  rating: number;
  totalReviewCount: number;
  balance: number;
  orderCount: number;
  loveCount: number;
  positiveReviewCount: number;
  identityNumber: string;
  isTeam: boolean;
  teamMemberCount: number;
  skills: ISkill[];
  description: string;
  serviceProven: IServiceProven[];
  previewPrice: number;
  reviews: {
    avt: string;
    content: string;
    customerId: string;
    name: string;
    ratingPoint: number;
    reviewDate: string;
  }[];
}
export const MatchingFreelancerAccountDetailSchema = z.object({
  accountId: z.string(),
  account: AccountDetailSchema,
  address: AddressSchema,
  rating: z.number(),
  totalReviewCount: z.number(),
  balance: z.number(),
  orderCount: z.number(),
  loveCount: z.number(),
  positiveReviewCount: z.number(),
  identityNumber: z.string(),
  isTeam: z.boolean(),
  teamMemberCount: z.number(),
  skills: z.array(SkillSchema),
  description: z.string(),
  serviceProven: z.array(ServiceProvenSchema),
  previewPrice: z.number(),
  reviews: z.array(
    z.object({
      avt: z.string(),
      content: z.string(),
      customerId: z.string(),
      name: z.string(),
      ratingPoint: z.number(),
      reviewDate: z.string(),
    })
  ),
});
