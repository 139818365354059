import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useAd_getAllBusinessApproveRequestQuery } from "../../fetching/query";

export const useAdGetAllBusinessApproveRequest = (
  page: number,
  pageSize: number
) => {
  const {
    state: { token },
  } = useAuthContext();
  const { data, refetch, isRefetching } =
    useAd_getAllBusinessApproveRequestQuery(token, page, pageSize);
  return {
    data,
    isRefetching,
    refetch,
  };
};
