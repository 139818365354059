import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export const CustomLink: React.FC<LinkProps & { to: string }> = ({
  children,
  to,
  ...props
}) => (
  <ChakraLink to={to} as={ReactRouterLink} textDecoration={"none"} {...props}>
    {children}
  </ChakraLink>
);
