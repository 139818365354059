import { useGetAllCategoriesQuery } from "../../fetching/query";

export const useGetAllCategories = () => {
  const { data, refetch, isRefetching } = useGetAllCategoriesQuery();
  return {
    data,
    isRefetching,
    refetch,
  };
};
