import * as y from "yup";

export const loginFormSchema = y.object({
  email: y
    .string()
    .typeError("Invalid type")
    .required("Email required")
    .email("Email invalid"),
  password: y
    .string()
    .typeError("Invalid type")
    .min(6, "At least 6 characters"),
});
