import { useState } from "react";
import { useRealtimeContext } from "../context";
import { invokeResponseType } from "./type";
import { useSocketConnect } from "./connection";
import { getPhoneNumberFromAsyncStorageFunction } from "../helpers/getPhoneNumber";

export function useInvoker() {
  const {
    state: { socket },
  } = useRealtimeContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleConnect } = useSocketConnect();

  const invoke = async (
    message: string,
    data: any
  ): Promise<invokeResponseType> => {
    setIsLoading(true);
    if (!socket) {
      const phoneNumber = getPhoneNumberFromAsyncStorageFunction();
      if (phoneNumber) {
        try {
          const sk = await handleConnect(phoneNumber);
          if (sk) {
            return await sk.invoke(message, data);
          } else {
            throw new Error("Cannot connect to realtime hub (reconnecting)");
          }
        } catch (error) {
          throw error;
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        console.error("Cannot reconnect to socket in invoker");
        throw new Error("Socket is not connected");
      }
    } else {
      try {
        return await socket.invoke(message, data);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    invoke,
    isLoading,
  };
}
