import { Box, GridItem, Skeleton, SkeletonText } from "@chakra-ui/react";

type props = {
  col: number;
};
export const CategoryLoading: React.FC<props> = ({ col }) => {
  return (
    <>
      {Array(col)
        .fill(0)
        .map((_, index) => (
          <GridItem w="100%" key={`skeleton@${index}`}>
            <Box
              w="100%"
              p={"1rem"}
              bg={"white"}
              h={370}
              boxShadow={"lg"}
              borderRadius={"lg"}
            >
              <Skeleton
                height={"180px"}
                width={"100%"}
                borderRadius={"md"}
                startColor="dt_gray"
                endColor="dt_soft"
              />
              <SkeletonText
                mt="2"
                noOfLines={1}
                spacing="1"
                skeletonHeight="2"
                startColor="dt_gray"
                endColor="dt_soft"
              />
              <SkeletonText
                mt="2"
                noOfLines={3}
                spacing="2"
                skeletonHeight="2"
                startColor="dt_gray"
                endColor="dt_soft"
              />
            </Box>
          </GridItem>
        ))}
    </>
  );
};
