import { useQuery } from "@tanstack/react-query";
import { QUERY_N_MUTATION_KEYS } from "@business-layer/business-logic/configs";
import { AdminService } from "@business-layer/services";

const service = new AdminService();

export const useAd_getAllCustomerQuery = (
  token: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ALL_CUSTOMER,
      { token, page, pageSize },
    ],
    queryFn: () => service.ad_getAllCustomer(token, page, pageSize),
  });
};
export const useAd_getAllFreelancerQuery = (
  token: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ALL_FREELANCER,
      { token, page, pageSize },
    ],
    queryFn: () => service.ad_getAllFreelancer(token, page, pageSize),
  });
};
export const useAd_getAllBusinessQuery = (
  token: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ALL_BUSINESS,
      { token, page, pageSize },
    ],
    queryFn: () => service.ad_getAllBusiness(token, page, pageSize),
  });
};
export const useAd_getCustomerDetailQuery = (
  token: string | null,
  id: string
) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.AD_GET_DETAIL_CUSTOMER, { token, id }],
    queryFn: () => service.ad_getCustomerDetail(token, id),
  });
};
export const useAd_getFreelancerDetailQuery = (
  token: string | null,
  id: string
) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.AD_GET_DETAIL_FREELANCER, { token, id }],
    queryFn: () => service.ad_getFreelancerDetail(token, id),
  });
};
export const useAd_getAllFreelancerApproveRequestQuery = (
  token: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ALL_FREELANCER_APPROVE_REQUEST,
      token,
    ],
    queryFn: () =>
      service.ad_getAllFreelancerApproveRequest(token, page, pageSize),
  });
};
export const useAd_getAllBusinessApproveRequestQuery = (
  token: string | null,
  page: number,
  pageSize: number
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ALL_BUSINESS_APPROVE_REQUEST,
      token,
    ],
    queryFn: () =>
      service.ad_getAllBusinessApproveRequest(token, page, pageSize),
  });
};
export const useAd_getAdminCurrentAccountDetailQuery = (
  token: string | null
) => {
  return useQuery({
    queryKey: [
      QUERY_N_MUTATION_KEYS.AD_GET_ADMIN_CURRENT_ACCOUNT_DETAIL,
      token,
    ],
    queryFn: () => service.ad_getAdminCurrentAccountDetail(token),
  });
};
