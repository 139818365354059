import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useGetCategoryDetailQuery } from "../../fetching/query";
import { useGetCategoryDetailMutation } from "../../fetching/mutations";

export const useGetCategoryDetail = (categoryId: string) => {
  const { state } = useAuthContext();
  const { data, refetch, isRefetching } = useGetCategoryDetailQuery({
    token: state.token,
    categoryId,
  });
  return {
    data,
    isRefetching,
    refetch,
  };
};
export const useGetCategoryDetailByFunction = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { mutateAsync, isPending } = useGetCategoryDetailMutation();

  async function getDetail(categoryId: string) {
    try {
      return await mutateAsync({
        token: token,
        categoryId,
      });
    } catch (error) {
      throw error;
    }
  }

  return {
    getDetail,
    isLoading: isPending,
  };
};
