import { useDeleteCategoryMutation } from "../../fetching/mutations";
import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import {
  deleteCategoryPropsType,
  deleteCategoryResponseType,
} from "@business-layer/services";

export const useDeleteCategory = () => {
  const { mutateAsync, isPending } = useDeleteCategoryMutation();
  const {
    state: { token },
  } = useAuthContext();

  function onDelete({
    id,
  }: Omit<
    deleteCategoryPropsType,
    "token"
  >): Promise<deleteCategoryResponseType> {
    return new Promise((resolve, reject) => {
      mutateAsync({ id, token })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return {
    onDelete,
    isLoading: isPending,
  };
};
