import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useGetConversationDetailMutation } from "../../fetching/mutations";
import { getConversationDetailPropsType } from "@business-layer/services";

export const useGetConversationDetail = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { mutateAsync, isPending } = useGetConversationDetailMutation();

  async function onGet(props: Omit<getConversationDetailPropsType, "token">) {
    try {
      const res = await mutateAsync({ ...props, token });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return { onGet, isLoading: isPending };
};
