import { useCreateCategoryMutation } from "../../fetching/mutations";
import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import {
  createCategoryPropsType,
  createCategoryResponseType,
} from "@business-layer/services";

export const useCreateCategory = () => {
  const { mutateAsync, isPending } = useCreateCategoryMutation();
  const {
    state: { token },
  } = useAuthContext();

  function onCreate(
    data: Omit<createCategoryPropsType, "token">
  ): Promise<createCategoryResponseType> {
    return new Promise((resolve, reject) => {
      mutateAsync({ ...data, token })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return {
    onCreate,
    isLoading: isPending,
  };
};
