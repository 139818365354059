import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useAd_getAllBusinessQuery } from "../../fetching/query";

export const useAdGetAllBusiness = (page: number, pageSize: number) => {
  const {
    state: { token },
  } = useAuthContext();
  const { data, refetch, isRefetching } = useAd_getAllBusinessQuery(
    token,
    page,
    pageSize
  );
  return {
    data,
    isRefetching,
    refetch,
  };
};
