import { HubConnection } from "@microsoft/signalr";
import { GLOBAL_MSG_KEYS } from "../../config";
import { useCallback, useEffect } from "react";
import { useRealtimeContext } from "../../context";

type resType = {
  notificationType: string;
  title: string;
  body: string;
};
export function useListenError() {
  const {
    state: { socket },
  } = useRealtimeContext();
  useEffect(() => {
    return () => {
      socket && socket.off(GLOBAL_MSG_KEYS.ON_ERROR);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onListeningError = useCallback((socket: HubConnection) => {
    if (socket) {
      socket.on(GLOBAL_MSG_KEYS.ON_ERROR, (error: resType) => {
        console.error(error);
      });
    }
  }, []);
  return onListeningError;
}
