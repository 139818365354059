// Importing necessary libraries and modules
import React, { useEffect, useReducer } from "react";
import { AuthContext } from "../context/authContext";
import { authReducer } from "../context/reducer";
import { getCookie } from "@business-layer/business-logic/helper";
import { COOKIE_KEYS } from "@business-layer/business-logic/configs";

type AuthProviderType = {
  children: React.ReactNode;
};
export const AuthContextProvider: React.FC<AuthProviderType> = ({
  children,
}) => {
  // Using the reducer to manage the authentication state
  const initialState = {
    token: null,
    refreshToken: null,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const token = getCookie(COOKIE_KEYS.ACCESS_TOKEN);
    if (token) {
      dispatch({
        type: "SET_TOKEN",
        payload: token,
      });
    }
  }, []);

  // Returning the context provider with the current state and dispatch function
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
