import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";
import { getLocalStorage } from "@business-layer/business-logic/helper";

export const useGetAdminCurrentAccountFromStorage = (): {
  id: string;
  avatar: string;
  countryCode: string;
  phone: string;
  dateOfBirth: string;
  fullName: string;
  gender: string;
} | null => {
  return getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_ACCOUNT);
};
