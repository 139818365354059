import { APP_URLS } from "./constants/urls";
import { MainTemplate } from "./presentational/templates/MainTemplate";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { CategoryAndService } from "./pages/CategoryAndService";
import { Navigate } from "react-router-dom";
import { FreelancerApprove } from "./pages/FreelancerApprove";
import CategoryDetail from "./pages/CategoryDetail";
import Login from "./pages/Login";
import { useIsLogged } from "@business-layer/business-logic/lib/auth";
import { AddCategory } from "./pages/AddCategory";
import { AddService } from "./pages/AddService";
import { CompanyApprove } from "./pages/CompanyApprove";
import { Accounts } from "./pages/Accounts";
import { CSKH } from "./pages/CSKH";
import React from "react";

type routeType = {
  path: string;
  slug?: string;
  Component: () => JSX.Element;
  childrenRoute?: routeType[];
};
function Router() {
  const isLogged = useIsLogged();
  function createMainRoute({
    path,
    slug,
    Component,
    childrenRoute,
  }: routeType): JSX.Element {
    const ComponentCombined = isLogged ? (
      <MainTemplate>
        <Component />
      </MainTemplate>
    ) : (
      <Navigate to={APP_URLS.LOGIN} />
    );
    return (
      <>
        <Route path={path} element={ComponentCombined} />
        {slug ? (
          <>
            <Route path={path + slug} element={ComponentCombined} />
            {childrenRoute
              ? childrenRoute.map((child) => (
                  <React.Fragment key={path + slug + child.path}>
                    {createMainRoute({
                      ...child,
                      path: path + slug + child.path,
                    })}
                  </React.Fragment>
                ))
              : null}
          </>
        ) : childrenRoute ? (
          childrenRoute.map((child) => (
            <React.Fragment key={path + child.path}>
              {createMainRoute({ ...child, path: path + child.path })}
            </React.Fragment>
          ))
        ) : null}
      </>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={APP_URLS.BLANK}
          element={<Navigate to={APP_URLS.DASHBOARD} />}
        />
        {createMainRoute({ path: APP_URLS.DASHBOARD, Component: Dashboard })}
        {createMainRoute({
          path: APP_URLS.CATEGORY_AND_SERVICE,
          Component: CategoryAndService,
          childrenRoute: [
            {
              path: APP_URLS.CATEGORY_DETAIL,
              slug: "/:id",
              Component: CategoryDetail,
              childrenRoute: [
                {
                  path: APP_URLS.ADD_SERVICE,
                  Component: AddService,
                },
              ],
            },
            {
              path: APP_URLS.ADD_CATEGORY,
              Component: AddCategory,
            },
          ],
        })}
        {createMainRoute({
          path: APP_URLS.ACCOUNTS,
          Component: Accounts,
        })}
        {createMainRoute({
          path: APP_URLS.FREELANCER_APPROVE,
          Component: FreelancerApprove,
        })}
        {createMainRoute({
          path: APP_URLS.COMPANY_APPROVE,
          Component: CompanyApprove,
        })}
        {createMainRoute({
          path: APP_URLS.CSKH,
          Component: CSKH,
        })}
        <Route
          path={APP_URLS.LOGIN}
          element={isLogged ? <Navigate to={APP_URLS.DASHBOARD} /> : <Login />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
