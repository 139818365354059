import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useGetOverallAnalyticsQuery } from "../../fetching/query";
import { getOverallAnalyticsParamsType } from "@business-layer/services";

export const useGetOverallAnalytics = (
  params: Omit<getOverallAnalyticsParamsType, "token">
) => {
  const {
    state: { token },
  } = useAuthContext();
  const { data, refetch, isRefetching } = useGetOverallAnalyticsQuery({
    token,
    ...params,
  });
  return { data, isRefetching, refetch };
};
