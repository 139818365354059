import { z } from 'zod';

export interface IAnswer {
  id: string;
  answer: string;
  isCorrect: boolean;
}
export const AnswerSchema = z.object({
  id: z.string(),
  answer: z.string(),
  isCorrect: z.boolean(),
});

export interface IQuestion {
  id: string;
  question: string;
  point: number;
  answers: IAnswer[];
}
export const QuestionSchema = z.object({
  id: z.string(),
  question: z.string(),
  point: z.number(),
  answers: z.array(AnswerSchema),
});

export interface ITest {
  id: string;
  totalTime: number;
  totalQuestion: number;
  quizQuestions: IQuestion[];
  freelancerId: string;
}
export const TestSchema = z.object({
  id: z.string(),
  totalTime: z.number(),
  totalQuestion: z.number(),
  quizQuestions: z.array(QuestionSchema),
  freelancerId: z.string(),
});
