import { setLocalStorage } from "@business-layer/business-logic/helper";
import { useGetAdminCurrentAccountDetailMutation } from "../../fetching/mutations";
import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";

export const useGetAdminCurrentAccountDetail = () => {
  const { mutateAsync, isPending } = useGetAdminCurrentAccountDetailMutation();

  async function onFetching(token: string) {
    try {
      const accountDetail = await mutateAsync(token);
      const formattedAccountDetail = {
        id: accountDetail.id,
        avatar: accountDetail.account.avatar,
        countryCode: accountDetail.account.countryCode,
        phone: accountDetail.account.phone,
        dateOfBirth: accountDetail.account.dateOfBirth,
        fullName: accountDetail.account.fullName,
        gender: accountDetail.account.gender,
      };
      setLocalStorage(
        LOCAL_STORAGE_KEYS.CURRENT_ACCOUNT,
        formattedAccountDetail
      );
      return formattedAccountDetail;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return {
    onFetching,
    isLoading: isPending,
  };
};
