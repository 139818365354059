import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useGetAllChatPreviewQuery } from "../../fetching/query";

export const useGetAllChatPreview = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { data, refetch, isRefetching } = useGetAllChatPreviewQuery(token);
  return {
    data,
    isRefetching,
    refetch,
  };
};
