import { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";
import { client } from "../config/axios";
import { getCookie } from "@business-layer/business-logic/helper/secureStorage";
import { COOKIE_KEYS } from "@business-layer/business-logic/configs";
import { AuthService } from "../lib";
import { IToken } from "../entities";
import { activeMiddlewares } from "../config/middlewares";

let isRefreshing = false;
export function enableRefreshTokenMiddleware(
  onLogout: () => void,
  setToken: (token: IToken) => void,
  setRefreshToken: (token: IToken) => void
) {
  activeMiddlewares.refresh_token &&
    client.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        if (
          error.response?.status === 401 &&
          (error.response?.data as any)?.message === "Invalid credential"
        ) {
          console.log("Prepare to refresh token");
          if (isRefreshing) {
            console.log("Waiting...");
            setTimeout(async () => {
              const token = await getCookie(COOKIE_KEYS.ACCESS_TOKEN);
              const newConfig = { ...error.config };
              if (newConfig.headers) {
                newConfig.headers.Authorization = `Bearer ${token}`;
              } else {
                newConfig["headers"] = {
                  Authorization: `Bearer ${token}`,
                } as AxiosRequestHeaders;
              }
              return client(newConfig);
            }, 5000);
          } else {
            console.log("Refreshing token...");
            isRefreshing = true;
            const refreshToken = await getCookie(COOKIE_KEYS.REFRESH_TOKEN);
            if (refreshToken) {
              try {
                console.log("Refreshing token");
                const res = await new AuthService().refreshToken(refreshToken);
                if (res.token) {
                  console.log("Refresh token success");
                  setToken(res.token);
                  setRefreshToken(res.refreshToken);
                  const newConfig = { ...error.config };
                  if (newConfig.headers) {
                    newConfig.headers.Authorization = `Bearer ${res.token.value}`;
                  } else {
                    newConfig["headers"] = {
                      Authorization: `Bearer ${res.token.value}`,
                    } as AxiosRequestHeaders;
                  }
                  isRefreshing = false;
                  return client(newConfig);
                }
              } catch (err) {
                onLogout();
                isRefreshing = false;
                return Promise.reject(err);
              }
            } else {
              console.log("Refresh token not found! Logging out...");
              onLogout();
              isRefreshing = false;
              return Promise.reject(error);
            }
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
}
