/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCreateCategory } from "@business-layer/business-logic/lib/category";
import { useDelete, useUpload } from "@business-layer/business-logic/lib/sirv";
import { ICategory } from "@business-layer/services/entities";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Select,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ADDRESS_TYPE } from "@constants/addressType";
import { APP_URLS } from "@constants/urls";
import { convertViToEn } from "@utils/helpers";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export function AddCategory() {
  const cancelAlertRef = useRef<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ICategory & { key: string[] }>({
    defaultValues: {
      id: "",
      name: "",
      image: "",
      description: "",
      key: [],
    },
  });
  const { onCreate, isLoading } = useCreateCategory();
  const [keyVal, setKeyVal] = useState<string>("");
  const pickImageRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const keyWatcher = watch("key");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onUpload, isLoading: isUploading } = useUpload();
  const { onDelete } = useDelete();
  const navigate = useNavigate();
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  function onShowAddAlert() {
    onOpen();
  }
  async function handleAddNewCategory(data: ICategory & { key: string[] }) {
    if (!selectedImage) {
      return;
    }

    const { path } = await onUpload({
      uri: selectedImage.toString(),
      name: convertViToEn(data.name),
      path: `/services/${convertViToEn(data.name)}`,
      type: "JPEG",
    });
    try {
      await onCreate({
        ...data,
        image: path,
      });
      toast({
        status: "success",
        title: "Create service success",
        isClosable: true,
      });
      navigate(APP_URLS.CATEGORY_AND_SERVICE);
    } catch (error) {
      onDelete(path);
      toast({
        status: "error",
        title: "loi roi",
        isClosable: true,
      });
    }
  }
  return (
    <Flex
      flexDir={"row"}
      gap={"1rem"}
      bg={"white"}
      borderRadius={"xl"}
      boxShadow={"md"}
      p={"1rem"}
    >
      <Box w={"60%"}>
        <Text
          color={"dt_primary"}
          fontSize={"1rem"}
          fontWeight={700}
          mb={"0.5rem"}
        >
          General information
        </Text>
        <Flex flexDirection={"row"} gap={"1rem"} w={"100%"}>
          <Button
            type="button"
            w={"12.5rem"}
            h={"12.5rem"}
            position={"relative"}
            bg={"dt_soft"}
            onClick={() => {
              pickImageRef.current && pickImageRef.current.click();
            }}
          >
            <input
              ref={pickImageRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{
                display: "none",
                pointerEvents: "none",
              }}
            />

            {selectedImage ? (
              <Image
                src={selectedImage.toString()}
                alt="Selected"
                position={"absolute"}
                top={0}
                left={0}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
            ) : (
              <>
                <Box
                  position={"absolute"}
                  top={0}
                  left={0}
                  w={"100%"}
                  h={"100%"}
                  className="custom-dash"
                />
                <Image src="/assets/imgs/gallery.png" w={"5rem"} h={"5rem"} />
              </>
            )}
          </Button>

          <Flex flexGrow={1} flexDirection={"column"} gap={"0.5rem"}>
            <Input
              placeholder="Category name"
              fontSize={"0.75rem"}
              {...register("name")}
              fontFamily={"Roboto Flex Variable"}
              borderColor={"dt_primary"}
              color={"dt_primary"}
              bg={"dt_soft"}
            />
            <Textarea
              id="description"
              placeholder="Description"
              fontSize={"0.75rem"}
              {...register("description")}
              fontFamily={"Roboto Flex Variable"}
              borderColor={"dt_primary"}
              color={"dt_primary"}
              bg={"dt_soft"}
              resize={"none"}
            />
            <Flex h={"1rem"} flexDirection={"row"} alignItems={"center"}>
              {keyWatcher.map((k, index) => (
                <Flex
                  h={"100%"}
                  flexDirection={"row"}
                  gap={"0.5rem"}
                  alignItems={"center"}
                  key={`${k}@${index}`}
                >
                  <Tag
                    bg={"dt_gray"}
                    color={"white"}
                    borderRadius={99}
                    fontSize={"0.5rem"}
                    size={"sm"}
                  >
                    {k}
                  </Tag>
                  <Button
                    color={"error"}
                    fontSize={"0.75rem"}
                    minW={0}
                    px={0}
                    mr={"0.5rem"}
                    justifyContent={"flex-start"}
                    textAlign={"left"}
                    onClick={() => {
                      setValue(
                        "key",
                        keyWatcher.filter((key) => key !== k)
                      );
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                  </Button>
                </Flex>
              ))}
            </Flex>
            <Flex flexDir={"row"} gap={"0.5rem"}>
              <Input
                id="key"
                placeholder="Keywords"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                value={keyVal}
                onChange={(e) => setKeyVal(e.target.value)}
              />
              <Button
                type="button"
                border={"solid"}
                borderWidth={"1px"}
                borderColor={"dt_primary"}
                bg={"dt_light"}
                color={"dt_primary"}
                fontSize={"0.65rem"}
                onClick={() => {
                  setValue("key", [...keyWatcher, keyVal]);
                  setKeyVal("");
                }}
              >
                Add key
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex w={"40%"} flexDir={"column"}>
        <Grid flexGrow={1} mt={"0.5rem"} placeItems={"flex-end"}>
          <Button
            type="button"
            border={"solid"}
            borderWidth={"1px"}
            borderColor={"dt_primary"}
            bg={"dt_soft_primary"}
            color={"dt_white"}
            fontSize={"0.65rem"}
            fontWeight={700}
            onClick={onShowAddAlert}
            isLoading={isUploading || isLoading}
          >
            Confirm Create Service
          </Button>
        </Grid>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelAlertRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Create Service
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? Service will be active at once and customer can
              order this service.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  handleSubmit(handleAddNewCategory)();
                }}
                ml={3}
              >
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
