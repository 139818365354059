import { z } from 'zod';
import { CategorySchema, ICategory } from './category';
import { IService, ServiceSchema } from './service';

export interface ISearchService extends IService {
  serviceCategory: ICategory;
}
export const SearchServiceSchema = ServiceSchema.merge(
  z.object({
    serviceCategory: CategorySchema,
  })
);
