import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { APP_URLS } from "@constants/urls";
import { CustomLink } from "@presentational/atoms/Link";
import { useLocation } from "react-router-dom";

const TABS = [
  {
    to: APP_URLS.DASHBOARD,
    label: "Dashboard",
    icon: "fi-sr-browser",
  },
  {
    to: APP_URLS.CATEGORY_AND_SERVICE,
    label: "Category & Service",
    icon: "fi-sr-chart-tree",
  },
  {
    to: APP_URLS.FREELANCER_APPROVE,
    label: "Freelancer Approve",
    icon: "fi-sr-mode-portrait",
  },
  {
    to: APP_URLS.COMPANY_APPROVE,
    label: "Company Approve",
    icon: "fi-sr-building",
  },
  {
    to: APP_URLS.ACCOUNTS,
    label: "Accounts",
    icon: "fi-sr-portrait",
  },
  {
    to: APP_URLS.CSKH,
    label: "CSKH",
    icon: "fi-sr-headset",
  },
];

export function VerticalTabBar() {
  const { pathname } = useLocation();
  return (
    <>
      <Box w={250} maxW={400} h={"100vh"}></Box>
      <Box
        w={250}
        maxW={400}
        h={"100vh"}
        bg={"white"}
        py={"1rem"}
        px={"1rem"}
        shadow={"revert"}
        boxShadow={"lg"}
        position={"fixed"}
        top={0}
        left={0}
      >
        <Image
          objectFit={"cover"}
          w={"100%"}
          aspectRatio={2}
          h={"auto"}
          src="/assets/imgs/logo.png"
          alt="Detoi Việt Nam - Có việc gì khó, cứ để tôi lo"
        />
        <Divider mt={"1rem"} mb={"2rem"} />
        {TABS.map((t) => (
          <CustomLink
            to={t.to}
            key={t.to}
            color={pathname.includes(t.to) ? "dt_primary" : "dt_gray"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"0.5rem"}
            w={"100%"}
          >
            <Flex flexDirection={"row"} alignItems={"center"} gap={"0.5rem"}>
              <i className={`fi ${t.icon}`} style={{ fontSize: "0.7rem" }}></i>
              <Text fontSize={"0.7rem"} fontWeight={"700"}>
                {t.label}
              </Text>
            </Flex>
            <Box
              bg={pathname.includes(t.to) ? "dt_primary" : "white"}
              h={"1rem"}
              w={"2px"}
            />
          </CustomLink>
        ))}
      </Box>
    </>
  );
}
