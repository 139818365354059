import { Box, Flex, Input, Select } from "@chakra-ui/react";
import { validationType } from "@utils/validators/yup";

export type subValidationDataType = {
  validationType: validationType["name"];
  message: string;
  value?: number;
};
export function SubValidation({
  formData,
  setFormData,
}: {
  formData: subValidationDataType;
  setFormData: React.Dispatch<React.SetStateAction<subValidationDataType>>;
}) {
  return (
    <Box>
      <Flex flexDir={"row"} gap={"0.5rem"}>
        <Select
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          w={"35%"}
          value={formData.validationType}
          onChange={(e) =>
            setFormData({
              ...formData,
              validationType: e.target.value as validationType["name"],
            })
          }
        >
          <option value={"required"}>Required</option>
          <option value={"min"}>Min</option>
          <option value={"max"}>Max</option>
        </Select>
        <Input
          w={"65%"}
          placeholder="Message"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          value={formData.message}
          onChange={(e) =>
            setFormData({
              ...formData,
              message: e.target.value,
            })
          }
        />
      </Flex>
      {formData.validationType !== "required" ? (
        <Input
          placeholder="Value"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          type="number"
          mt={"0.5rem"}
          value={formData.value}
          onChange={(e) =>
            setFormData({
              ...formData,
              value: Number.parseInt(e.target.value),
            })
          }
        />
      ) : null}
    </Box>
  );
}
