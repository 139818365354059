/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IServiceDetail,
  IUIServiceRequirement,
  variableDataType,
} from "@business-layer/services/entities";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  Grid,
  Image,
  Input,
  ListItem,
  OrderedList,
  Select,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import "./additionStyle.scss";
import { useRef, useState } from "react";
import { ADDRESS_TYPE } from "@constants/addressType";
import { InputValidationForm } from "./InputValidationForm";
import { SelectValidationForm } from "./SelectValidationForm";
import { SpecialItemSelectForm } from "./SpecialItemSelectForm";
import { AVAILABLE_FA_ICON } from "@constants/availableFAIcon";
import { useDelete, useUpload } from "@business-layer/business-logic/lib/sirv";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateService,
  useGetCategoryDetail,
} from "@business-layer/business-logic/lib/category";
import { convertViToEn } from "@utils/helpers";
import { APP_URLS } from "@constants/urls";
import { ImagePickerValidationForm } from "./ImagePickerValidationForm";

const DATA_TYPE: { [key in variableDataType]: string } = {
  text: "String",
  number: "Number",
  money: "Currency",
};

export function AddService() {
  const [
    additionalRequirementLabelInputVal,
    setAdditionalRequirementLabelInputVal,
  ] = useState<string>("");
  const [additionalRequirementIcon, setAdditionalRequirementIcon] =
    useState<string>("");
  const [keyVal, setKeyVal] = useState<string>("");
  const [requirementDataForm, setRequirementDataForm] = useState<{
    label: string;
    placeholder: string;
    icon: string;
  }>({
    label: "",
    placeholder: "",
    icon: "",
  });
  const pickImageRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [inputMethod, setInputMethod] = useState<
    IUIServiceRequirement["inputMethod"]
  >({
    dataType: "text",
    method: {
      name: "input",
    },
    validation: [],
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Omit<IServiceDetail, "id">>({
    defaultValues: {
      name: "",
      description: "",
      image: "",
      requirements: [],
      additionalRequirements: [],
      key: [],
    },
  });
  const { pathname } = useLocation();
  const pathNameArr = pathname.split("/");
  const categoryId = pathNameArr[pathNameArr.length - 2];
  const { data: categoryDetail } = useGetCategoryDetail(categoryId);
  const requirementsWatcher = watch("requirements");
  const additionalRequirementsWatcher = watch("additionalRequirements");
  const keyWatcher = watch("key");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSelectAdditionIconOpen,
    onOpen: onSelectAdditionIconOpen,
    onClose: onSelectAdditionIconClose,
  } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const { onCreate, isLoading } = useCreateService();
  const cancelAlertRef = useRef<any>();
  const { onUpload, isLoading: isUploading } = useUpload();
  const { onDelete } = useDelete();
  const navigate = useNavigate();
  function onShowAddAlert() {
    onOpenAlert();
  }
  async function handleAddNewService(data: Omit<IServiceDetail, "id">) {
    if (!selectedImage || !categoryDetail) {
      return;
    }

    const { path } = await onUpload({
      uri: selectedImage.toString(),
      name: convertViToEn(data.name),
      path: `/services/${convertViToEn(categoryDetail.name)}`,
      type: "JPEG",
    });
    try {
      const res = await onCreate({
        service: {
          id: "",
          ...data,
          image: path,
          serviceCategoryId: categoryId,
        },
      });
      console.log(res);
      toast({
        status: "success",
        title: "Create service success",
        isClosable: true,
      });
      navigate(
        APP_URLS.CATEGORY_AND_SERVICE +
          APP_URLS.CATEGORY_DETAIL +
          "/" +
          categoryId
      );
    } catch (error) {
      onDelete(path);
      toast({
        status: "error",
        title: "loi roi",
        isClosable: true,
      });
    }
  }
  const handleSelectIcon = () => {
    onOpen();
  };
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box p={"1rem"} bg={"dt_soft"} w={"100%"}>
      <FormControl isInvalid={Object.values(errors).some((e) => Boolean(e))}>
        <Stack spacing={2}>
          <Flex
            flexDir={"row"}
            gap={"1rem"}
            bg={"white"}
            borderRadius={"xl"}
            boxShadow={"md"}
            p={"1rem"}
          >
            <Box w={"60%"}>
              <Text
                color={"dt_primary"}
                fontSize={"1rem"}
                fontWeight={700}
                mb={"0.5rem"}
              >
                General information
              </Text>
              <Flex flexDirection={"row"} gap={"1rem"} w={"100%"}>
                <Button
                  type="button"
                  w={"12.5rem"}
                  h={"12.5rem"}
                  position={"relative"}
                  bg={"dt_soft"}
                  onClick={() => {
                    pickImageRef.current && pickImageRef.current.click();
                  }}
                >
                  <input
                    ref={pickImageRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{
                      display: "none",
                      pointerEvents: "none",
                    }}
                  />

                  {selectedImage ? (
                    <Image
                      src={selectedImage.toString()}
                      alt="Selected"
                      position={"absolute"}
                      top={0}
                      left={0}
                      w={"100%"}
                      h={"100%"}
                      objectFit={"cover"}
                    />
                  ) : (
                    <>
                      <Box
                        position={"absolute"}
                        top={0}
                        left={0}
                        w={"100%"}
                        h={"100%"}
                        className="custom-dash"
                      />
                      <Image
                        src="/assets/imgs/gallery.png"
                        w={"5rem"}
                        h={"5rem"}
                      />
                    </>
                  )}
                </Button>

                <Flex flexGrow={1} flexDirection={"column"} gap={"0.5rem"}>
                  <Input
                    id="name"
                    placeholder="Service name"
                    fontSize={"0.75rem"}
                    {...register("name")}
                    fontFamily={"Roboto Flex Variable"}
                    borderColor={"dt_primary"}
                    color={"dt_primary"}
                    bg={"dt_soft"}
                  />
                  <Textarea
                    id="description"
                    placeholder="Description"
                    fontSize={"0.75rem"}
                    {...register("description")}
                    fontFamily={"Roboto Flex Variable"}
                    borderColor={"dt_primary"}
                    color={"dt_primary"}
                    bg={"dt_soft"}
                    resize={"none"}
                  />
                  <Flex h={"1rem"} flexDirection={"row"} alignItems={"center"}>
                    {keyWatcher.map((k, index) => (
                      <Flex
                        h={"100%"}
                        flexDirection={"row"}
                        gap={"0.5rem"}
                        alignItems={"center"}
                        key={`${k}@${index}`}
                      >
                        <Tag
                          bg={"dt_gray"}
                          color={"white"}
                          borderRadius={99}
                          fontSize={"0.5rem"}
                          size={"sm"}
                        >
                          {k}
                        </Tag>
                        <Button
                          color={"error"}
                          fontSize={"0.75rem"}
                          minW={0}
                          px={0}
                          mr={"0.5rem"}
                          justifyContent={"flex-start"}
                          textAlign={"left"}
                          onClick={() => {
                            setValue(
                              "key",
                              keyWatcher.filter((key) => key !== k)
                            );
                          }}
                        >
                          <i className="fi fi-sr-trash" />
                        </Button>
                      </Flex>
                    ))}
                  </Flex>
                  <Flex flexDir={"row"} gap={"0.5rem"}>
                    <Input
                      id="key"
                      placeholder="Keywords"
                      fontSize={"0.75rem"}
                      fontFamily={"Roboto Flex Variable"}
                      borderColor={"dt_primary"}
                      color={"dt_primary"}
                      bg={"dt_soft"}
                      value={keyVal}
                      onChange={(e) => setKeyVal(e.target.value)}
                    />
                    <Button
                      type="button"
                      border={"solid"}
                      borderWidth={"1px"}
                      borderColor={"dt_primary"}
                      bg={"dt_light"}
                      color={"dt_primary"}
                      fontSize={"0.65rem"}
                      onClick={() => {
                        setValue("key", [...keyWatcher, keyVal]);
                        setKeyVal("");
                      }}
                    >
                      Add key
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Flex w={"40%"} flexDir={"column"}>
              <Text
                color={"dt_primary"}
                fontSize={"1rem"}
                fontWeight={700}
                mb={"0.5rem"}
              >
                Setting
              </Text>

              <Flex flexDirection={"column"} gap={"0.5rem"}>
                <Input
                  id="basePrice"
                  placeholder="Base Price"
                  fontSize={"0.75rem"}
                  {...register("basePrice")}
                  fontFamily={"Roboto Flex Variable"}
                  borderColor={"dt_primary"}
                  color={"dt_primary"}
                  bg={"dt_soft"}
                />
                <Select
                  id="addressRequireOption"
                  placeholder="Select address type"
                  fontSize={"0.75rem"}
                  {...register("addressRequireOption")}
                  fontFamily={"Roboto Flex Variable"}
                  borderColor={"dt_primary"}
                  color={"dt_primary"}
                  bg={"dt_soft"}
                >
                  {Object.entries(ADDRESS_TYPE).map(([key, val]) => (
                    <option value={key} key={key}>
                      {val}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Grid flexGrow={1} mt={"0.5rem"} placeItems={"flex-end"}>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={"primary.min"}
                  color={"white"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  onClick={onShowAddAlert}
                  isLoading={isLoading || isUploading}
                >
                  Confirm Create Service
                </Button>
              </Grid>
            </Flex>
          </Flex>
          {requirementsWatcher.length > 0 ? (
            <Box
              gap={"1rem"}
              bg={"white"}
              borderRadius={"xl"}
              boxShadow={"md"}
              p={"1rem"}
            >
              <OrderedList>
                {requirementsWatcher.map((item, index) => (
                  <ListItem key={`info@${item.label}@${index}`}>
                    <Flex flexDirection={"row"} alignItems={"center"}>
                      <Text fontSize={"1rem"}>
                        {item.label} | {item.placeholder} | {item.labelIcon} |{" "}
                        {item.inputMethod.dataType} |{" "}
                        {item.inputMethod.method.name}
                      </Text>
                      <Button
                        color={"error"}
                        onClick={() => {
                          setValue(
                            "requirements",
                            requirementsWatcher.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        <i className="fi fi-sr-trash" />
                      </Button>
                    </Flex>
                  </ListItem>
                ))}
              </OrderedList>
            </Box>
          ) : null}

          <Flex
            flexDir={"row"}
            bg={"white"}
            borderRadius={"xl"}
            boxShadow={"md"}
            p={"1rem"}
          >
            {/* Left site of requirement box */}
            <Flex
              w={"50%"}
              borderRight={"solid"}
              borderRightWidth={"1px"}
              borderRightColor={"dt_primary"}
              flexDir={"column"}
              gap={"0.5rem"}
              pr={"1.5rem"}
            >
              <Text color={"dt_primary"} fontSize={"1rem"} fontWeight={700}>
                Requirements
              </Text>{" "}
              <Input
                placeholder="Label"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                value={requirementDataForm.label}
                onChange={({ target: { value } }) => {
                  setRequirementDataForm({
                    ...requirementDataForm,
                    label: value,
                  });
                }}
              />
              <Input
                placeholder="Placeholder"
                fontSize={"0.75rem"}
                fontFamily={"Roboto Flex Variable"}
                borderColor={"dt_primary"}
                color={"dt_primary"}
                bg={"dt_soft"}
                value={requirementDataForm.placeholder}
                onChange={({ target: { value } }) => {
                  setRequirementDataForm({
                    ...requirementDataForm,
                    placeholder: value,
                  });
                }}
              />
              <Flex flexDir={"row"} gap={"0.5rem"}>
                <Select
                  placeholder="Select data type"
                  fontSize={"0.75rem"}
                  fontFamily={"Roboto Flex Variable"}
                  borderColor={"dt_primary"}
                  color={"dt_primary"}
                  bg={"dt_soft"}
                  value={inputMethod.dataType}
                  onChange={({ target: { value } }) => {
                    setInputMethod({
                      ...inputMethod,
                      dataType: value as variableDataType,
                    });
                  }}
                >
                  {Object.entries(DATA_TYPE).map(([key, val]) => (
                    <option value={key} key={key}>
                      {val}
                    </option>
                  ))}
                </Select>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={"dt_light"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  onClick={handleSelectIcon}
                >
                  Select Icon
                </Button>
              </Flex>
              {requirementDataForm.icon ? (
                <Flex
                  flexDir={"row"}
                  alignItems={"center"}
                  gap={"0.5rem"}
                  fontSize={"0.75rem"}
                  fontWeight={700}
                  color={"dt_primary"}
                >
                  Label icon:{" "}
                  <i
                    className={`fa-solid ${requirementDataForm.icon}`}
                    style={{
                      fontSize: "1rem",
                      position: "relative",
                      bottom: "1px",
                    }}
                  />
                  <Button
                    bg={"error"}
                    color={"white"}
                    size={"sm"}
                    fontSize={"0.65rem"}
                    onClick={() => {
                      setRequirementDataForm({
                        ...requirementDataForm,
                        icon: "",
                      });
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                    <Text color={"white"} fontWeight={700} ml={1}>
                      Delete
                    </Text>
                  </Button>
                </Flex>
              ) : null}
              <Button
                type="button"
                border={"solid"}
                borderWidth={"1px"}
                borderColor={"dt_primary"}
                bg={"primary.min"}
                color={"white"}
                fontSize={"0.65rem"}
                fontWeight={700}
                onClick={() => {
                  setValue("requirements", [
                    ...requirementsWatcher,
                    {
                      id: "",
                      key: "",
                      label: requirementDataForm.label,
                      labelIcon: requirementDataForm.icon,
                      placeholder: requirementDataForm.placeholder,
                      inputMethod,
                    },
                  ]);
                  setRequirementDataForm({
                    label: "",
                    placeholder: "",
                    icon: "",
                  });
                  setInputMethod({
                    dataType: "text",
                    method: {
                      name: "input",
                    },
                    validation: [],
                  });
                }}
              >
                Confirm add requirement
              </Button>
            </Flex>
            {/* Right site of requirement box */}
            <Box w={"50%"} pl={"1.5rem"}>
              <Text
                color={"dt_primary"}
                fontSize={"1rem"}
                fontWeight={700}
                mb={"0.5rem"}
              >
                Type of requirement
              </Text>
              {/* List of requirement input method type button */}
              <Flex flexDir={"row"} gap={"0.5rem"}>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={
                    inputMethod.method.name === "input" ? "dt_light" : "dt_soft"
                  }
                  variant={"outline"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  textTransform={"capitalize"}
                  onClick={() =>
                    setInputMethod({
                      ...inputMethod,
                      method: {
                        name: "input",
                      },
                      validation: [],
                    })
                  }
                >
                  Input
                </Button>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={
                    inputMethod.method.name === "select"
                      ? "dt_light"
                      : "dt_soft"
                  }
                  variant={"outline"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  textTransform={"capitalize"}
                  onClick={() =>
                    setInputMethod({
                      ...inputMethod,
                      method: {
                        name: "select",
                        options: [],
                      },
                      validation: [],
                    })
                  }
                >
                  Select
                </Button>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={
                    inputMethod.method.name === "special_item_select"
                      ? "dt_light"
                      : "dt_soft"
                  }
                  variant={"outline"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  textTransform={"capitalize"}
                  onClick={() =>
                    setInputMethod({
                      ...inputMethod,
                      method: {
                        name: "special_item_select",
                        options: [],
                      },
                      validation: [],
                    })
                  }
                >
                  Special Item Select
                </Button>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={
                    inputMethod.method.name === "add_to_list"
                      ? "dt_light"
                      : "dt_soft"
                  }
                  variant={"outline"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  textTransform={"capitalize"}
                  onClick={() =>
                    setInputMethod({
                      ...inputMethod,
                      method: {
                        name: "add_to_list",
                      },
                      validation: [],
                    })
                  }
                >
                  Add To List
                </Button>
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={
                    inputMethod.method.name === "image_picker"
                      ? "dt_light"
                      : "dt_soft"
                  }
                  variant={"outline"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  fontWeight={700}
                  textTransform={"capitalize"}
                  onClick={() =>
                    setInputMethod({
                      ...inputMethod,
                      method: {
                        name: "image_picker",
                      },
                      validation: [],
                    })
                  }
                >
                  Image Picker
                </Button>
              </Flex>
              {/* Show the requirement input method that added */}
              {(() => {
                const { dataType, method, validation } = inputMethod;
                return (
                  <>
                    <Text fontSize={"1rem"} mt={"0.5rem"}>
                      Requirement setting: {method.name} | {dataType}
                    </Text>
                    <OrderedList>
                      {validation.map((v, index) => (
                        <ListItem key={`${v.id}@${index}`}>
                          <Flex flexDirection={"row"} alignItems={"center"}>
                            <Text fontSize={"1rem"}>
                              {Object.values(v).map((val, index) =>
                                index < 2 ? val : ` | ${val}`
                              )}
                            </Text>
                            <Button
                              color={"error"}
                              onClick={() => {
                                const newInputMethod = inputMethod.validation;
                                newInputMethod.splice(index, 1);
                                setInputMethod({
                                  ...inputMethod,
                                  validation: newInputMethod,
                                });
                              }}
                            >
                              <i className="fi fi-sr-trash" />
                            </Button>
                          </Flex>
                        </ListItem>
                      ))}
                    </OrderedList>
                  </>
                );
              })()}

              {/* Add requirement input method form */}
              {inputMethod.method.name === "input" ? (
                <InputValidationForm
                  onSubmitCallback={({ validationType, message, value }) => {
                    setInputMethod({
                      ...inputMethod,
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                  }}
                />
              ) : inputMethod.method.name === "select" ? (
                <SelectValidationForm
                  onSubmitCallback={({
                    validationType,
                    message,
                    value,
                    options,
                  }) => {
                    setInputMethod({
                      ...inputMethod,
                      ...(inputMethod.method.name === "select"
                        ? {
                            method: {
                              ...inputMethod.method,
                              options: options.map((o) => ({
                                id: "",
                                name: o,
                                description: "",
                              })),
                            },
                          }
                        : {}),
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                  }}
                />
              ) : inputMethod.method.name === "special_item_select" ? (
                <SpecialItemSelectForm
                  onSubmitCallback={({
                    validationType,
                    message,
                    value,
                    options,
                  }) => {
                    console.log("IN____________________");
                    console.log({
                      validationType,
                      message,
                      value,
                      options,
                    });
                    console.log("OUT____________________");
                    console.log({
                      dataType: inputMethod.dataType,
                      method: {
                        name: "special_item_select",
                        options,
                      },
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                    setInputMethod({
                      dataType: inputMethod.dataType,
                      method: {
                        name: "special_item_select",
                        options,
                      },
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                  }}
                />
              ) : inputMethod.method.name === "add_to_list" ? (
                <InputValidationForm
                  onSubmitCallback={({ validationType, message, value }) => {
                    setInputMethod({
                      ...inputMethod,
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                  }}
                />
              ) : inputMethod.method.name === "image_picker" ? (
                <ImagePickerValidationForm
                  onSubmitCallback={({ validationType, message, value }) => {
                    setInputMethod({
                      ...inputMethod,
                      validation: [
                        ...inputMethod.validation,
                        {
                          id: "",
                          name: validationType as any,
                          message,
                          ...(value ? { value } : {}),
                        },
                      ],
                    });
                  }}
                />
              ) : null}
            </Box>
          </Flex>
          <Flex
            flexDir={"row"}
            bg={"white"}
            borderRadius={"xl"}
            boxShadow={"md"}
            p={"1rem"}
            gap={"1.5rem"}
          >
            <Flex w={"60%"} flexDir={"column"} gap={"0.5rem"}>
              <Text color={"dt_primary"} fontSize={"1rem"} fontWeight={700}>
                Additional Requirements
              </Text>
              <Flex flexDir={"row"} gap={"0.5rem"}>
                <Input
                  placeholder="Label"
                  fontSize={"0.75rem"}
                  fontFamily={"Roboto Flex Variable"}
                  borderColor={"dt_primary"}
                  color={"dt_primary"}
                  bg={"dt_soft"}
                  value={additionalRequirementLabelInputVal}
                  onChange={({ target: { value } }) => {
                    setAdditionalRequirementLabelInputVal(value);
                  }}
                />
                <Button
                  type="button"
                  border={"solid"}
                  borderWidth={"1px"}
                  borderColor={"dt_primary"}
                  bg={"dt_light"}
                  color={"dt_primary"}
                  fontSize={"0.65rem"}
                  onClick={() => onSelectAdditionIconOpen()}
                >
                  Select Icon
                </Button>
              </Flex>
              {additionalRequirementIcon ? (
                <Flex
                  flexDir={"row"}
                  alignItems={"center"}
                  gap={"0.5rem"}
                  fontSize={"0.75rem"}
                  fontWeight={700}
                  color={"dt_primary"}
                >
                  Label icon:{" "}
                  <i
                    className={`fa-solid ${additionalRequirementIcon}`}
                    style={{
                      fontSize: "1rem",
                      position: "relative",
                      bottom: "1px",
                    }}
                  />
                  <Button
                    bg={"error"}
                    color={"white"}
                    size={"sm"}
                    fontSize={"0.65rem"}
                    onClick={() => {
                      setAdditionalRequirementIcon("");
                    }}
                  >
                    <i className="fi fi-sr-trash" />
                    <Text color={"white"} fontWeight={700} ml={1}>
                      Delete
                    </Text>
                  </Button>
                </Flex>
              ) : null}
              <Button
                type="button"
                border={"solid"}
                borderWidth={"1px"}
                borderColor={"dt_primary"}
                bg={"primary.min"}
                color={"white"}
                fontSize={"0.65rem"}
                fontWeight={700}
                onClick={() => {
                  setValue("additionalRequirements", [
                    ...additionalRequirementsWatcher,
                    {
                      id: "",
                      key: "",
                      icon: additionalRequirementIcon,
                      label: additionalRequirementLabelInputVal,
                      autoSelect: false,
                    },
                  ]);
                  setAdditionalRequirementIcon("");
                  setAdditionalRequirementLabelInputVal("");
                }}
              >
                Add
              </Button>
            </Flex>
            <Box w={"40%"}>
              <OrderedList>
                {additionalRequirementsWatcher.map((item, index) => (
                  <ListItem key={`info@${item.label}@${index}`}>
                    <Flex flexDirection={"row"} alignItems={"center"}>
                      <Box>
                        <Text fontSize={"0.65rem"} fontStyle={"italic"}>
                          <i className={`fa-solid ${item.icon}`} />
                          {item.label}
                        </Text>
                      </Box>
                      <Button
                        color={"error"}
                        onClick={() => {
                          setValue(
                            "additionalRequirements",
                            additionalRequirementsWatcher.filter(
                              (_, i) => i !== index
                            )
                          );
                        }}
                      >
                        <i className="fi fi-sr-trash" />
                      </Button>
                    </Flex>
                  </ListItem>
                ))}
              </OrderedList>
            </Box>
          </Flex>
        </Stack>
      </FormControl>
      <Drawer onClose={onClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Select icon`}</DrawerHeader>
          <DrawerBody>
            {AVAILABLE_FA_ICON.map((ico) => (
              <Tooltip
                label={ico.name}
                hasArrow
                bg={"primary.min"}
                color={"white"}
              >
                <Button
                  onClick={() => {
                    setRequirementDataForm({
                      ...requirementDataForm,
                      icon: ico.name,
                    });
                    onClose();
                  }}
                  bg={"white"}
                  _hover={{ backgroundColor: "dt_gray" }}
                >
                  {ico.Component}
                </Button>
              </Tooltip>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        onClose={onSelectAdditionIconClose}
        isOpen={isSelectAdditionIconOpen}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Select icon`}</DrawerHeader>
          <DrawerBody>
            {AVAILABLE_FA_ICON.map((ico) => (
              <Tooltip
                label={ico.name}
                hasArrow
                bg={"primary.min"}
                color={"white"}
              >
                <Button
                  onClick={() => {
                    setAdditionalRequirementIcon(ico.name);
                    onSelectAdditionIconClose();
                  }}
                  bg={"white"}
                  _hover={{ backgroundColor: "dt_gray" }}
                >
                  {ico.Component}
                </Button>
              </Tooltip>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelAlertRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Create Service
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? Service will be active at once and customer can
              order this service.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseAlert();
                  handleSubmit(handleAddNewService)();
                }}
                ml={3}
              >
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
