import {
  useGetAllChatPreview,
  useGetConversationDetail,
} from "@business-layer/business-logic/lib/account";
import { useGetAdminCurrentAccountFromStorage } from "@business-layer/business-logic/lib/admin";
import { useChat } from "@business-layer/business-logic/realtime";
import { IMessage, IMessagePreview } from "@business-layer/services/entities";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { BASE_IMG_CDN } from "@constants/cdn";
import { CommonLoader, Shipping } from "@presentational/atoms/Loading";
import SimpleLoader from "@presentational/atoms/Loading/SimpleLoader";
import { COLOR_THEME } from "@presentational/themes/colors";
import { formatDateFromNow } from "@utils/helpers";
import {
  chatFormSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const CSKH = () => {
  const adminAccount = useGetAdminCurrentAccountFromStorage();
  const { data } = useGetAllChatPreview();
  const { handleSubmit, register, reset } = useForm<{ chatBox: string }>({
    defaultValues: {
      chatBox: "",
    },
    resolver: useYupValidationResolver(chatFormSchema),
  });
  const [newChatFromUserAccount, setNewChatFromUserAccount] = useState<
    IMessage | undefined
  >(undefined);
  const [currentChat, setCurrentChat] = useState<IMessagePreview | null>(null);
  const [conversationDetail, setConversationDetail] = useState<
    IMessage[] | null
  >(null);
  const { onGet, isLoading } = useGetConversationDetail();
  const { onChat: onSendChatMessage, isLoading: isSendingChat } = useChat(
    (message: IMessage) => {
      setNewChatFromUserAccount(message);
    }
  );

  useEffect(() => {
    if (
      newChatFromUserAccount &&
      currentChat &&
      newChatFromUserAccount.sender.id === currentChat.conversationId
    ) {
      setConversationDetail([
        ...(conversationDetail ?? []),
        newChatFromUserAccount,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChatFromUserAccount]);

  useEffect(() => {
    if (currentChat) {
      onGet({
        receiverId: currentChat.conversationId,
        page: 1,
        pageSize: 200,
      }).then((res) => {
        console.log(res);
        setConversationDetail(res.reverse());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  function onChat({ chatBox }: { chatBox: string }) {
    if (currentChat && adminAccount) {
      console.log(chatBox);
      onSendChatMessage({
        id: adminAccount.id,
        content: chatBox,
        sendTo: currentChat.conversationId,
        isCustomerService: true,
        image: null,
        isSystem: false,
      });
      setConversationDetail([
        ...(conversationDetail ?? []),
        {
          id: `adminSent@${Math.random()}`,
          content: chatBox,
          image: null,
          isSystem: false,
          sender: {
            id: adminAccount.id,
            avatar: adminAccount.avatar,
            fullName: adminAccount.fullName,
            type: 2,
          },
          timeReceived: `${new Date().toISOString()}`,
        },
      ]);
      reset();
    }
  }

  return (
    <Flex
      flexDir={"row"}
      gap={"2rem"}
      ml={"1rem"}
      padding={"1rem"}
      height={`calc(100vh - 7rem)`}
      bgColor={"dt_white"}
      boxShadow={"md"}
      borderRadius={"0.75rem"}
    >
      {Array.isArray(data) ? (
        <>
          <Flex w={"30%"} h={"100%"} flexDir={"column"} gap={"0.5rem"}>
            <Box
              w={"100%"}
              h={"2.5rem"}
              borderRadius={"0.5rem"}
              bgColor={"dt_soft"}
              boxShadow={"base"}
              position={"relative"}
              mb={"0.5rem"}
            >
              <Input
                w={"100%"}
                h={"100%"}
                bgColor={"dt_transparent"}
                border={"none"}
                paddingLeft={"2.2rem"}
                placeholder="Search by name or id..."
                fontSize={"0.75rem"}
                fontWeight={"500"}
                fontFamily={"Roboto Flex Variable"}
              />
              <Box
                position={"absolute"}
                top={"50%"}
                left={"0.75rem"}
                transform={"auto"}
                translateY={"-45%"}
                color={"dt_gray"}
              >
                <i className="fi fi-br-search"></i>
              </Box>
            </Box>
            {data.map((d) => (
              <Button
                key={d.conversationId}
                onClick={() => setCurrentChat(d)}
                backgroundColor={"transparent"}
                display={"flex"}
                flexDir={"row"}
                w={"100%"}
                gap={"0.5rem"}
                alignItems={"center"}
                justifyContent={"start"}
                padding={0}
                textAlign={"left"}
                transition={"ease-in-out"}
                transitionDuration={"0.3s"}
                transitionProperty={"all"}
                height={"auto"}
                style={
                  currentChat?.conversationId === d.conversationId
                    ? {
                        backgroundColor: COLOR_THEME.dt_soft,
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                      }
                    : {}
                }
              >
                <Image
                  src={`${BASE_IMG_CDN}${d.sender.avatar}`}
                  w={"55px"}
                  h={"auto"}
                  aspectRatio={1}
                  borderRadius={"0.5rem"}
                  objectFit={"cover"}
                />
                <Box w={"calc(100% - 55px - 0.5rem)"}>
                  <Text
                    fontSize={"0.75rem"}
                    fontWeight={700}
                    noOfLines={1}
                    textOverflow={"ellipsis"}
                  >
                    {d.sender.fullName}
                  </Text>
                  <Text
                    fontSize={"0.65rem"}
                    fontWeight={500}
                    noOfLines={1}
                    textOverflow={"ellipsis"}
                  >
                    {d.latestMessage.content}
                  </Text>
                  <Text
                    fontSize={"0.6rem"}
                    fontWeight={500}
                    noOfLines={1}
                    textOverflow={"ellipsis"}
                  >
                    {formatDateFromNow(new Date(d.latestMessage.timeReceived))}
                  </Text>
                </Box>
              </Button>
            ))}
          </Flex>
          <Flex flexGrow={1} h={"100%"} flexDir={"column"} gap={"0.5rem"}>
            {isLoading ? (
              <Shipping />
            ) : conversationDetail === null ? (
              <Flex
                flex={1}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={"column"}
              >
                <Image src="/assets/imgs/support.png" w={"120px"} h={"120px"} />
                <Text fontSize={"1rem"} fontWeight={800} mt={"0.25rem"}>
                  CHOOSE CUSTOMER TO CONTACT WITH
                </Text>
                <Text
                  fontSize={"0.75rem"}
                  fontWeight={500}
                  mt={"0.25rem"}
                  align={"center"}
                >
                  Important: Help’s Employee can contact with ONLY 1 user at the
                  same time
                </Text>
              </Flex>
            ) : (
              <form
                style={{ width: "100%", height: "100%" }}
                onSubmit={handleSubmit(onChat)}
              >
                <Flex
                  flexDir={"column"}
                  position={"relative"}
                  w={"100%"}
                  h={"100%"}
                >
                  <Flex
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    alignItems={"start"}
                    pb={"0.75rem"}
                    borderBottomWidth={"1px"}
                    borderBottomColor={"dt_gray"}
                    bgColor={"dt_white"}
                    w={"100%"}
                    flexShrink={1}
                  >
                    <Box>
                      <Text fontSize={"1rem"} fontWeight={800}>
                        {currentChat?.sender.fullName}
                      </Text>
                      <Text fontSize={"0.75rem"} fontWeight={500}>
                        Khách hàng
                      </Text>
                    </Box>
                    <IconButton
                      icon={<i className="fi fi-sr-user"></i>}
                      onClick={() => {}}
                      aria-label={"Xem chi tiết người dùng"}
                    />
                  </Flex>
                  <Flex
                    flexDir={"column"}
                    justifyContent={"flex-end"}
                    flexGrow={1}
                    overflowX={"hidden"}
                    overflowY={"auto"}
                    py={"1rem"}
                  >
                    {conversationDetail.map(
                      ({
                        id,
                        content,
                        image,
                        isSystem,
                        sender,
                        timeReceived,
                      }) => {
                        if (sender.type !== 2) {
                          return (
                            <Flex
                              w={"100%"}
                              flexDir={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              key={id}
                              mb={"0.5rem"}
                            >
                              <Image
                                src={`${BASE_IMG_CDN}${sender.avatar}`}
                                w={"40px"}
                                h={"40px"}
                                borderRadius={"99px"}
                                objectFit={"cover"}
                              />
                              <Box ml={"0.5rem"} maxWidth={"50%"}>
                                <Box
                                  bgColor={"dt_soft"}
                                  padding={"0.75rem"}
                                  borderRadius={"0.75rem"}
                                  borderBottomLeftRadius={0}
                                  boxShadow={"base"}
                                  mb={"0.25rem"}
                                >
                                  <Text
                                    fontSize={"0.65rem"}
                                    fontWeight={500}
                                    color={"dt_black"}
                                  >
                                    {content}
                                  </Text>
                                </Box>

                                <Box>
                                  <Text fontSize={"0.6rem"} fontWeight={500}>
                                    {formatDateFromNow(new Date(timeReceived))}
                                  </Text>
                                </Box>
                              </Box>
                            </Flex>
                          );
                        } else {
                          return (
                            <Flex
                              w={"100%"}
                              flexDir={"row"}
                              justifyContent={"flex-end"}
                              alignItems={"flex-start"}
                              key={id}
                              mb={"0.5rem"}
                            >
                              <Box mr={"0.5rem"} maxWidth={"50%"}>
                                <Box
                                  bgColor={"dt_soft_primary"}
                                  padding={"0.75rem"}
                                  borderRadius={"0.75rem"}
                                  borderBottomRightRadius={0}
                                  boxShadow={"base"}
                                  mb={"0.25rem"}
                                >
                                  <Text
                                    fontSize={"0.65rem"}
                                    fontWeight={500}
                                    color={"dt_white"}
                                  >
                                    {content}
                                  </Text>
                                </Box>

                                <Text
                                  fontSize={"0.6rem"}
                                  fontWeight={500}
                                  textAlign={"right"}
                                >
                                  {formatDateFromNow(new Date(timeReceived))}
                                </Text>
                              </Box>
                              <Image
                                src={`${BASE_IMG_CDN}${sender.avatar}`}
                                w={"40px"}
                                h={"40px"}
                                borderRadius={"99px"}
                              />
                            </Flex>
                          );
                        }
                      }
                    )}
                  </Flex>
                  <Box
                    flexShrink={1}
                    position={"relative"}
                    borderRadius={"0.5rem"}
                    bgColor={"dt_soft"}
                    w={"100%"}
                    h={"50px"}
                    boxShadow={"md"}
                  >
                    <Input
                      w={"100%"}
                      h={"100%"}
                      border={"none"}
                      zIndex={0}
                      bgColor={"transparent"}
                      placeholder="Your message..."
                      pl={"1rem"}
                      pr={"2.5rem"}
                      fontSize={"0.75rem"}
                      fontWeight={"500"}
                      fontFamily={"Roboto Flex Variable"}
                      autoComplete="off"
                      autoCorrect="off"
                      {...register("chatBox")}
                    />
                    <IconButton
                      icon={
                        isSendingChat ? (
                          <SimpleLoader />
                        ) : (
                          <i className="fi fi-bs-paper-plane"></i>
                        )
                      }
                      position={"absolute"}
                      top={"50%"}
                      zIndex={1}
                      transform={"auto"}
                      translateY={"-50%"}
                      right={0}
                      color={"dt_primary"}
                      aria-label="send"
                      padding={0}
                      bgColor={"transparent"}
                      cursor={"pointer"}
                      type="submit"
                      _hover={{
                        bgColor: "transparent",
                      }}
                    />
                  </Box>
                </Flex>
              </form>
            )}
          </Flex>
        </>
      ) : (
        <CommonLoader />
      )}
    </Flex>
  );
};
