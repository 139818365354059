import {
  useDeleteCategory,
  useGetAllCategories,
} from "../../business-layer/src/business-logic/lib/category";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { CategoryLoading } from "./CategoryLoading";
import { getCDNUrl } from "@utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_URLS } from "@constants/urls";

export const CategoryAndService = () => {
  const { data: categories, isRefetching, refetch } = useGetAllCategories();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { onDelete } = useDeleteCategory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();
  const deleteTargetRef = useRef<string | null>(null);

  function handleDeleteCategory() {
    if (deleteTargetRef.current) {
      const deletePromise = onDelete({ id: deleteTargetRef.current });

      toast.promise(deletePromise, {
        success: (_) => {
          refetch();
          return { title: "Success", description: "Service has been deleted" };
        },
        error: (error) => {
          console.error(error);
          return { title: "Fail", description: "Cannot delete service" };
        },
        loading: { title: "Deleting...", description: "Please wait" },
      });
    }
  }
  return (
    <Box w={"100%"} padding={"1rem"}>
      <Box
        w={"100%"}
        bg={"dt_white"}
        borderRadius={"0.5rem"}
        py={"0.5rem"}
        px={"1rem"}
        boxShadow={"md"}
        mb={"0.75rem"}
      >
        <Button
          bg={"dt_light"}
          px={"1rem"}
          boxShadow={"base"}
          onClick={() => navigate(`${pathname}${APP_URLS.ADD_CATEGORY}`)}
        >
          <Text color={"dt_primary"} fontSize={"0.75rem"} fontWeight={800}>
            + ADD
          </Text>
        </Button>
      </Box>
      <Grid
        w={"100%"}
        templateColumns={{ xl: "repeat(3, 1fr)", "2xl": "repeat(4, 1fr)" }}
        gap={3}
      >
        {categories && !isRefetching ? (
          categories.map((c) => (
            <GridItem w="100%" key={c.id}>
              <Card
                maxW="sm"
                height={"100%"}
                cursor={"pointer"}
                onClick={() =>
                  navigate(`${pathname}${APP_URLS.CATEGORY_DETAIL}/${c.id}`)
                }
              >
                <CardBody>
                  <Flex
                    flexDir={"column"}
                    justifyContent={"space-between"}
                    h={"100%"}
                  >
                    <Box>
                      <Image
                        src={getCDNUrl(c.image)}
                        alt="Green double couch with wooden legs"
                        borderRadius="lg"
                      />
                      <Stack mt="3" spacing="1">
                        <Flex alignItems={"center"}>
                          <Heading
                            size="sm"
                            fontWeight={"800"}
                            color={"dt_primary"}
                          >
                            {c.name}
                          </Heading>
                          <Text
                            fontWeight={"500"}
                            color={"dt_gray"}
                            align={"justify"}
                            ml={"0.3rem"}
                            mt={"2px"}
                          >
                            ( {c.serviceTypeCount} dịch vụ )
                          </Text>
                        </Flex>

                        <Flex gap={"0.3rem"} flexWrap={"wrap"}>
                          {c.keys.map((key, index) => (
                            <Box
                              key={key + index}
                              bgColor={"dt_soft"}
                              borderRadius={999}
                              px={"0.875rem"}
                              py={"0.25rem"}
                            >
                              <Text
                                fontWeight={"500"}
                                fontSize={"0.6rem"}
                                color={"dt_primary"}
                                align={"justify"}
                              >
                                Game
                              </Text>
                            </Box>
                          ))}
                        </Flex>
                        <Text color={"dt_primary"} align={"justify"}>
                          {c.description}
                        </Text>
                      </Stack>
                    </Box>
                    <Flex gap={"0.5rem"}>
                      <Button
                        color={"dt_rose"}
                        bg={"dt_soft"}
                        mt={2}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteTargetRef.current = c.id;
                          onOpen();
                        }}
                        _hover={{
                          opacity: 0.8,
                        }}
                      >
                        <i className="fi fi-sr-trash" />
                      </Button>
                      <Button
                        color={"dt_white"}
                        bg={"dt_primary"}
                        mt={2}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        _hover={{
                          opacity: 0.8,
                        }}
                      >
                        <i className="fi fi-ss-select" />
                      </Button>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
          ))
        ) : (
          <CategoryLoading col={3} />
        )}
      </Grid>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          deleteTargetRef.current = null;
          onClose();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Category
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  deleteTargetRef.current = null;
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleDeleteCategory();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
