import {
  getChatAllChatPreviewEndpoint,
  getConversationDetailEndpoint,
  getCustomerCurrentAccountDetailEndpoint,
  getFreelancerDetailEndpoint,
  getFreelancerPreviewDataEndpoint,
  updateAccountDetailEndpoint,
} from "../../config/apis";
import { Services } from "../../service";
import {
  getChatAllChatPreviewResponseSchema,
  getConversationDetailResponseSchema,
  getCustomerCurrentAccountDetailResponseSchema,
  getFreelancerDetailResponseSchema,
  getFreelancerPreviewDataResponseSchema,
  updateAccountDetailResponseSchema,
} from "./schema";
import {
  getFreelancerDetailPropsType,
  getFreelancerPreviewDataPropsType,
  getFreelancerPreviewDataResponseType,
  getFreelancerDetailResponseType,
  getCustomerCurrentAccountDetailPropsType,
  getCustomerCurrentAccountDetailResponseType,
  updateAccountDetailPropsType,
  updateAccountDetailResponseType,
  getChatAllChatPreviewPropsType,
  getChatAllChatPreviewResponseType,
  getConversationDetailPropsType,
  getConversationDetailResponseType,
} from "./type";

export * from "./type";
export class AccountService extends Services {
  getFreelancerPreviewData = async ({
    token,
  }: getFreelancerPreviewDataPropsType): Promise<getFreelancerPreviewDataResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getFreelancerPreviewDataResponseSchema,
          getFreelancerPreviewDataResponseType
        >({
          method: "GET",
          url: getFreelancerPreviewDataEndpoint,
          schema: getFreelancerPreviewDataResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getFreelancerDetail = async ({
    token,
  }: getFreelancerDetailPropsType): Promise<getFreelancerDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getFreelancerDetailResponseSchema,
          getFreelancerDetailResponseType
        >({
          method: "GET",
          url: getFreelancerDetailEndpoint,
          schema: getFreelancerDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getCustomerCurrentAccountDetail = async ({
    token,
  }: getCustomerCurrentAccountDetailPropsType): Promise<getCustomerCurrentAccountDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getCustomerCurrentAccountDetailResponseSchema,
          getCustomerCurrentAccountDetailResponseType
        >({
          method: "GET",
          url: getCustomerCurrentAccountDetailEndpoint,
          schema: getCustomerCurrentAccountDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  updateAccountDetail = async ({
    token,
    ...data
  }: updateAccountDetailPropsType): Promise<updateAccountDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof updateAccountDetailResponseSchema,
          updateAccountDetailResponseType
        >({
          method: "PUT",
          url: updateAccountDetailEndpoint,
          schema: updateAccountDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getAllChatPreview = async ({
    token,
  }: getChatAllChatPreviewPropsType): Promise<getChatAllChatPreviewResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getChatAllChatPreviewResponseSchema,
          getChatAllChatPreviewResponseType
        >({
          method: "GET",
          url: getChatAllChatPreviewEndpoint,
          schema: getChatAllChatPreviewResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  getConversationDetail = async ({
    token,
    ...params
  }: getConversationDetailPropsType): Promise<getConversationDetailResponseType> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof getConversationDetailResponseSchema,
          getConversationDetailResponseType
        >({
          method: "GET",
          url: getConversationDetailEndpoint,
          schema: getConversationDetailResponseSchema,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
