const cdns = {
  sirv: `https://${process.env.REACT_APP_SIRV_USER}.sirv.com/`,
  bunny: `https://${process.env.REACT_APP_BUNNY_USER}.b-cdn.net/`,
};

/**
 * Config using CDN here
 */
const ACTIVE_CDN: keyof typeof cdns = "bunny";

export const BASE_IMG_CDN = cdns[ACTIVE_CDN];
