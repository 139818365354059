// Importing necessary modules and functions
import { deleteFileFromBunnyCDNReturnType } from '@business-layer/services';
import { useDeleteFromBunnyMutation } from '../../fetching/mutation';

export const useDelete = () => {
  const mutation = useDeleteFromBunnyMutation();

  const onDelete = (
    pathName: string
  ): Promise<deleteFileFromBunnyCDNReturnType> => {
    return new Promise((resolve, reject) => {
      mutation
        .mutateAsync(pathName)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    onDelete,
    isLoading: mutation.isPending,
  };
};
