import { useMutation } from "@tanstack/react-query";
import {
  AccountService,
  getConversationDetailPropsType,
  getConversationDetailResponseType,
  getCustomerCurrentAccountDetailPropsType,
  getCustomerCurrentAccountDetailResponseType,
  getFreelancerDetailPropsType,
  getFreelancerDetailResponseType,
  updateAccountDetailPropsType,
  updateAccountDetailResponseType,
} from "@business-layer/services";

const service = new AccountService();

export const useUpdateAccountDetailMutation = () => {
  return useMutation<
    updateAccountDetailResponseType,
    Error,
    updateAccountDetailPropsType,
    unknown
  >({
    mutationFn: service.updateAccountDetail,
  });
};
export const useFetchFreelancerPersonalDetailMutation = () => {
  return useMutation<
    getFreelancerDetailResponseType,
    Error,
    getFreelancerDetailPropsType,
    unknown
  >({
    mutationFn: service.getFreelancerDetail,
  });
};
export const useFetchCustomerPersonalDetailMutation = () => {
  return useMutation<
    getCustomerCurrentAccountDetailResponseType,
    Error,
    getCustomerCurrentAccountDetailPropsType,
    unknown
  >({
    mutationFn: service.getCustomerCurrentAccountDetail,
  });
};

export const useGetConversationDetailMutation = () => {
  return useMutation<
    getConversationDetailResponseType,
    Error,
    getConversationDetailPropsType,
    unknown
  >({
    mutationFn: service.getConversationDetail,
  });
};
