import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { validationType } from "@utils/validators/yup";
import { useState } from "react";

const DEFAULT_FORM_DATA: formDataType = {
  validationType: "required",
  message: "",
  options: [],
};
function isValidJsonArray(str: string) {
  try {
    let value = JSON.parse(str);
    let isValid = Array.isArray(value);
    return { isValid, value };
  } catch (e) {
    return { isValid: false, value: null };
  }
}

type formDataType = {
  validationType: validationType["name"];
  message: string;
  value?: string;
  options: any[];
};
type props = {
  onSubmitCallback: (data: formDataType) => void;
};
export const SelectValidationForm: React.FC<props> = ({ onSubmitCallback }) => {
  const [optionsRawArr, setOptionRawArr] = useState<string>("");
  const [formData, setFormData] = useState<formDataType>(DEFAULT_FORM_DATA);
  const toast = useToast();
  function onSuccessSubmit() {
    const { isValid, value } = isValidJsonArray(optionsRawArr);
    if (isValid) {
      onSubmitCallback({
        ...formData,
        options: value,
      });
    } else {
      toast({
        title: "Options is not a valid arr in JSON format",
        status: "error",
        isClosable: true,
      });
    }
    // onSubmitCallback(
    //   formData.validationType !== "required"
    //     ? formData
    //     : { validationType: formData.validationType, message: formData.message }
    // );
    setFormData(DEFAULT_FORM_DATA);
    setOptionRawArr("");
  }
  return (
    <Box mt={"0.5rem"}>
      <Text
        color={"dt_primary"}
        fontSize={"0.65rem"}
        fontWeight={700}
        fontStyle={"italic"}
        mb={"0.5rem"}
      >
        Options
      </Text>
      <Input
        placeholder="Array of options (JSON Format)"
        fontSize={"0.75rem"}
        fontFamily={"Roboto Flex Variable"}
        borderColor={"dt_primary"}
        color={"dt_primary"}
        bg={"dt_soft"}
        mb={"0.5rem"}
        value={optionsRawArr}
        onChange={({ target: { value } }) => {
          setOptionRawArr(value);
        }}
      />
      <Text
        color={"dt_primary"}
        fontSize={"0.65rem"}
        fontWeight={700}
        fontStyle={"italic"}
        mb={"0.5rem"}
      >
        Validations
      </Text>
      <Flex flexDir={"row"} gap={"0.5rem"}>
        <Select
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          w={"35%"}
          value={formData.validationType}
          onChange={(e) =>
            setFormData({
              ...formData,
              validationType: e.currentTarget.value as validationType["name"],
            })
          }
        >
          <option value={"required"}>Required</option>
          <option value={"min"}>Min</option>
          <option value={"max"}>Max</option>
        </Select>
        <Input
          w={"65%"}
          placeholder="Message"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          value={formData.message}
          onChange={(e) =>
            setFormData({
              ...formData,
              message: e.target.value,
            })
          }
        />
      </Flex>
      {formData.validationType !== "required" ? (
        <Input
          placeholder="Value"
          fontSize={"0.75rem"}
          fontFamily={"Roboto Flex Variable"}
          borderColor={"dt_primary"}
          color={"dt_primary"}
          bg={"dt_soft"}
          type="number"
          mt={"0.5rem"}
          value={formData.value}
          onChange={(e) =>
            setFormData({
              ...formData,
              value: e.target.value,
            })
          }
        />
      ) : null}
      <Button
        size={"sm"}
        type="button"
        border={"solid"}
        borderWidth={"1px"}
        borderColor={"dt_primary"}
        bg={"dt_soft_primary"}
        color={"white"}
        fontSize={"0.65rem"}
        fontWeight={700}
        mt={"0.5rem"}
        onClick={onSuccessSubmit}
      >
        Add validation
      </Button>
    </Box>
  );
};
