import z from "zod";

const getOverallAnalyticsResponseSchema = z.object({
  feedback: z.object({
    feedbackCount: z.number(),
    serviceRequestCount: z.number(),
    systemErrorCount: z.number(),
  }),
  needResolve: z.object({
    unresolvedReportCount: z.number(),
    freelancerRequestCount: z.number(),
    companyRequestCount: z.number(),
  }),
  overview: z.object({
    totalProfit: z.number(),
    totalDiscountCost: z.number(),
    totalRevenue: z.number(),
    successOrderCount: z.number(),
    failedOrderCount: z.number(),
  }),
  overviewLastMonth: z.object({
    profitDifferent: z.number(),
    discountCostDifferent: z.number(),
    revenueDifferent: z.number(),
    successOrderCountDifferent: z.number(),
    failedOrderCountDifferent: z.number(),
  }),
  popularServices: z.object({
    statistics: z.array(
      z.object({
        serviceId: z.string(),
        serviceName: z.string(),
        percentage: z.number(),
      })
    ),
  }),
  profitOverview: z.array(
    z.object({
      date: z.string(),
      profit: z.number(),
    })
  ),
  newUsers: z.array(
    z.object({
      name: z.string(),
      statistics: z.array(
        z.object({
          monthNum: z.number(),
          month: z.null(),
          usageCount: z.number(),
        })
      ),
    })
  ),
  orderStatistic: z.array(
    z.object({
      monthNum: z.number(),
      month: z.string().nullable(),
      createdCount: z.number(),
      completedCount: z.number(),
      canceledCount: z.number(),
      complainsCount: z.number(),
    })
  ),
});

export { getOverallAnalyticsResponseSchema };
