import {
  IUIAdditionServiceRequirement,
  IUIServiceRequirement,
  UIAdditionServiceRequirementSchema,
  UIServiceRequirementSchema,
} from "./serviceRequirement";
import { z } from "zod";

export type addressRequireOptionType = "none" | "destination" | "shipping";

export interface IService {
  id: string;
  name: string;
  description: string;
  image: string;
  basePrice: number;
  // New
  addressRequireOption: addressRequireOptionType;
}
export const ServiceSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  image: z.string(),
  basePrice: z.number(),
  // New
  addressRequireOption: z.union([
    z.literal("none"),
    z.literal("destination"),
    z.literal("shipping"),
  ]),
});

export interface IServiceDetail extends IService {
  requirements: IUIServiceRequirement[];
  additionalRequirements: IUIAdditionServiceRequirement[];
  key: string[];
}
export const ServiceDetailSchema = ServiceSchema.extend({
  requirements: z.array(UIServiceRequirementSchema),
  additionalRequirements: z.array(UIAdditionServiceRequirementSchema),
  key: z.array(z.string()),
});
