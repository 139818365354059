import {
  AuctionOrderDetailSchema,
  MatchingFreelancerAccountDetailSchema,
  OrderDetailSchema,
} from '@business-layer/services/entities';
import z from 'zod';

const createOrderResponseTypeSchema = z.object({
  message: z.string(),
  id: z.string(),
});
const getMatchingOrderDetailResponseSchema = OrderDetailSchema;
const cancelOrderResponseSchema = z.object({
  message: z.string(),
});
const selectFreelancerForOrderResponseSchema = z.any();
const getOrderDetailResponseSchema = OrderDetailSchema;
const getFreelancerIncomingOrdersResponseSchema = z.array(OrderDetailSchema);

const getMarketplaceOrdersSchema = z.array(OrderDetailSchema);
const getFreelancerAuctioningOrdersSchema = z.array(AuctionOrderDetailSchema);
const getCustomerMatchingOrdersResponseSchema = z.array(
  MatchingFreelancerAccountDetailSchema
);
const updateOrderServiceStatusResponseSchema = z.object({
  message: z.string(),
});
const getCustomerOnServiceOrdersResponseSchema = z.array(OrderDetailSchema);
const getCustomerOnMatchingOrdersResponseSchema = z.array(OrderDetailSchema);
const getCustomerCompletedOrdersResponseSchema = z.array(OrderDetailSchema);

export {
  createOrderResponseTypeSchema,
  getMatchingOrderDetailResponseSchema,
  cancelOrderResponseSchema,
  selectFreelancerForOrderResponseSchema,
  getOrderDetailResponseSchema,
  getFreelancerIncomingOrdersResponseSchema,
  getMarketplaceOrdersSchema,
  getFreelancerAuctioningOrdersSchema,
  getCustomerMatchingOrdersResponseSchema,
  updateOrderServiceStatusResponseSchema,
  getCustomerOnServiceOrdersResponseSchema,
  getCustomerOnMatchingOrdersResponseSchema,
  getCustomerCompletedOrdersResponseSchema,
};
