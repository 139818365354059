import { AxiosError, AxiosResponse } from "axios";
import { client } from "../config/axios";
import { activeMiddlewares } from "../config/middlewares";

export function enableDelayDevResponseMiddleware() {
  activeMiddlewares.delay_dev_response &&
    client.interceptors.response.use(
      (response: AxiosResponse) => {
        if (process.env.NODE_ENV === "development") {
          let timeoutId: NodeJS.Timeout;
          return new Promise((resolve, reject) => {
            timeoutId = setTimeout(() => resolve(response), 0);
            return () => clearTimeout(timeoutId);
          });
        } else {
          return response;
        }
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
}
