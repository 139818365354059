import "./presentational/styles/global.scss";
import MainRouter from "./Router";
import { ChakraProvider } from "@chakra-ui/react";
import MainTheme from "./presentational/styles/theme";
import {
  useLoadToken,
  useLogout,
} from "@business-layer/business-logic/lib/auth";
import { useEffect, useState } from "react";
import { Empty } from "@presentational/atoms/Loading";
import { useGetAdminCurrentAccountDetail } from "@business-layer/business-logic/lib/admin";
import { useSocketConnect } from "@business-layer/business-logic/realtime";
import {
  enableDdosFirewallMiddleware,
  enableDelayDevResponseMiddleware,
  enableRefreshTokenMiddleware,
} from "@business-layer/services";
import { useAccessToken } from "@business-layer/business-logic/lib/auth/process/hooks/useAccessToken";
import { useHandleRefreshToken } from "@business-layer/business-logic/lib/auth/process/hooks/useHandleRefreshToken";

const LOADING_DURATION = 2000;

function App() {
  const { handleLoadToken } = useLoadToken();
  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const [isLoadingEnd, setIsLoadingEnd] = useState<boolean>(false);
  const { onFetching } = useGetAdminCurrentAccountDetail();
  const { handleConnect } = useSocketConnect();
  const { onLogout } = useLogout();
  const { setToken } = useAccessToken();
  const { setRefreshToken } = useHandleRefreshToken();

  async function loadAdminData() {
    const { token } = handleLoadToken();
    if (token) {
      try {
        const { countryCode, phone } = await onFetching(token);
        await handleConnect(`${countryCode}${phone}`);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  }

  useEffect(() => {
    enableDdosFirewallMiddleware();
    enableDelayDevResponseMiddleware();
    enableRefreshTokenMiddleware(onLogout, setToken, setRefreshToken);
    setTimeout(() => setIsLoadingEnd(true), LOADING_DURATION);
    Promise.all([loadAdminData()]).finally(() => setIsAppReady(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ChakraProvider
      theme={MainTheme}
      toastOptions={{ defaultOptions: { position: "top", size: "base" } }}
    >
      {isAppReady && isLoadingEnd ? (
        <MainRouter />
      ) : (
        <div className="w-screen h-screen d-grid place-items-center">
          <Empty label="Đang tải trang..." />
        </div>
      )}
    </ChakraProvider>
  );
}

export default App;
