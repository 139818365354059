import { AuthProvider, AuthProviderType } from "../lib/auth/process/provider";
import {
  CategoryProvider,
  categoryProviderType,
} from "../lib/category/process/provider";
import {
  RealtimeProvider,
  realtimeProviderType,
} from "../realtime/provider/RealtimeProvider";

export * from "./socialConfig";
export * from "./constants";

/**
 * MUTATION CONFIG FOR REACT-QUERY
 */
export const mutationConfig = {
  MUTATION_RETRY: 0,
  USE_QUERY_RETRY: 1,
};

/**
 * If you add 1 more module to providerConfig, you must
 * add to moduleKeyList and providerList as well
 */
export type moduleKeyList = "auth" | "category" | "realtime";
export type providerList = React.FC<
  AuthProviderType | categoryProviderType | realtimeProviderType
>;
export const providerConfig: {
  key: moduleKeyList;
  provider: providerList;
}[] = [
  {
    key: "realtime",
    provider: RealtimeProvider,
  },
  {
    key: "category",
    provider: CategoryProvider,
  },
  {
    key: "auth",
    provider: AuthProvider,
  },
];
