import { useQuery } from "@tanstack/react-query";
import { QUERY_N_MUTATION_KEYS } from "@business-layer/business-logic/configs";
import {
  AccountService,
  getConversationDetailPropsType,
} from "@business-layer/services";

const service = new AccountService();

export const useGetFreelancerPreviewDataQuery = (token: string | null) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_FREELANCER_PREVIEW_DATA, token],
    queryFn: () => service.getFreelancerPreviewData({ token }),
  });
};
export const useGetFreelancerDetailQuery = (token: string | null) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_FREELANCER_DETAIL, token],
    queryFn: () => service.getFreelancerDetail({ token }),
  });
};
export const useGetCustomerAccountDetailQuery = (token: string | null) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_CUSTOMER_ACCOUNT_DETAIL, token],
    queryFn: () => service.getCustomerCurrentAccountDetail({ token }),
  });
};
export const useGetAllChatPreviewQuery = (token: string | null) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_ALL_CHAT_PREVIEW, token],
    queryFn: () => service.getAllChatPreview({ token }),
  });
};
export const useGetConversationDetailQuery = (
  props: getConversationDetailPropsType
) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_CONVERSATION_DETAIL, props],
    queryFn: () => service.getConversationDetail(props),
  });
};
