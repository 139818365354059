import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useUnBanAccountMutation } from "../../fetching/mutations";

export const useUnbanAccount = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { mutateAsync, isPending } = useUnBanAccountMutation();

  const onUnBan = async (id: string) => {
    try {
      return await mutateAsync({ token, id });
    } catch (error) {
      throw error;
    }
  };

  return { onUnBan, isLoading: isPending };
};
