import { useAuthContext } from "@business-layer/business-logic/lib/auth/process/context";
import { useBanAccountMutation } from "../../fetching/mutations";

export const useBanAccount = () => {
  const {
    state: { token },
  } = useAuthContext();
  const { mutateAsync, isPending } = useBanAccountMutation();

  const onBan = async (id: string) => {
    try {
      return await mutateAsync({ token, id });
    } catch (error) {
      throw error;
    }
  };

  return { onBan, isLoading: isPending };
};
