import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/roboto-flex";
import { COLOR_THEME } from "@presentational/themes/colors";

export default extendTheme({
  colors: COLOR_THEME,
  fonts: {
    heading: `Roboto Flex Variable`,
    body: `Roboto Flex Variable`,
  },
});
