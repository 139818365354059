import { useMutation } from "@tanstack/react-query";
import {
  CategoryService,
  addMultipleServiceTypePropsType,
  addMultipleServiceTypeResponseType,
  createCategoryPropsType,
  createCategoryResponseType,
  createServicePropsType,
  createServiceResponseType,
  deleteCategoryPropsType,
  deleteCategoryResponseType,
  deleteServicePropsType,
  deleteServiceResponseType,
  getCategoryDetailPropsType,
  getCategoryDetailResponseType,
  searchCategoriesAndServicesPropsType,
  searchCategoriesAndServicesResponseType,
} from "@business-layer/services";

const service = new CategoryService();

export const useAddMultipleServiceTypeMutation = () => {
  return useMutation<
    addMultipleServiceTypeResponseType,
    Error,
    addMultipleServiceTypePropsType,
    unknown
  >({
    mutationFn: service.addMultipleServiceType,
  });
};

export const useGetCategoryDetailMutation = () => {
  return useMutation<
    getCategoryDetailResponseType,
    Error,
    getCategoryDetailPropsType,
    unknown
  >({
    mutationFn: service.getCategoryDetail,
  });
};

export const useSearchCategoriesAndServicesMutation = () => {
  return useMutation<
    searchCategoriesAndServicesResponseType,
    Error,
    searchCategoriesAndServicesPropsType,
    unknown
  >({
    mutationFn: service.searchCategoriesAndServices,
  });
};

export const useDeleteServiceMutation = () => {
  return useMutation<
    deleteServiceResponseType,
    Error,
    deleteServicePropsType,
    unknown
  >({
    mutationFn: service.deleteService,
  });
};

export const useDeleteCategoryMutation = () => {
  return useMutation<
    deleteCategoryResponseType,
    Error,
    deleteCategoryPropsType,
    unknown
  >({
    mutationFn: service.deleteCategory,
  });
};

export const useCreateServiceMutation = () => {
  return useMutation<
    createServiceResponseType,
    Error,
    createServicePropsType,
    unknown
  >({
    mutationFn: service.createService,
  });
};

export const useCreateCategoryMutation = () => {
  return useMutation<
    createCategoryResponseType,
    Error,
    createCategoryPropsType,
    unknown
  >({
    mutationFn: service.createCategory,
  });
};
