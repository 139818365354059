import { useQuery } from "@tanstack/react-query";
import { QUERY_N_MUTATION_KEYS } from "@business-layer/business-logic/configs";
import {
  AnalyticsService,
  getOverallAnalyticsParamsType,
} from "@business-layer/services";

const service = new AnalyticsService();

export const useGetOverallAnalyticsQuery = (
  params: getOverallAnalyticsParamsType
) => {
  return useQuery({
    queryKey: [QUERY_N_MUTATION_KEYS.GET_OVERALL_ANALYTICS, params],
    queryFn: () => service.getOverallAnalytics(params),
  });
};
