export enum QUERY_N_MUTATION_KEYS {
  GET_ALL_CATEGORIES,
  GET_ALL_SERVICES_OF_CATEGORY,
  GET_SERVICE_DETAIL,
  GET_MATCHING_ORDER_DETAIL,
  GET_ORDER_DETAIL,
  GET_FREELANCER_PREVIEW_DATA,
  GET_FREELANCER_DETAIL,
  GET_CUSTOMER_ACCOUNT_DETAIL,
  GET_FREELANCER_INCOMING_ORDERS,
  GET_MARKETPLACE_ORDERS,
  GET_FREELANCER_AUCTIONING_ORDER,
  GET_FREELANCER_PROVIDED_QUOTATION,
  GET_NEW_QUIZ,
  GET_CUSTOMER_ON_SERVICE_ORDERS,
  GET_CUSTOMER_ON_MATCHING_ORDERS,
  GET_CUSTOMER_COMPLETE_ORDERS,
  GET_ALL_FEES,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_CHAT_PREVIEW,
  GET_CONVERSATION_DETAIL,
  GET_OVERALL_ANALYTICS,
  AD_GET_ALL_CUSTOMER,
  AD_GET_ALL_FREELANCER,
  AD_GET_ALL_BUSINESS,
  AD_GET_DETAIL_CUSTOMER,
  AD_GET_DETAIL_FREELANCER,
  AD_GET_ALL_FREELANCER_APPROVE_REQUEST,
  AD_GET_ALL_BUSINESS_APPROVE_REQUEST,
  AD_GET_ADMIN_CURRENT_ACCOUNT_DETAIL,
}
export const COOKIE_KEYS = {
  ACCESS_TOKEN: `e83b3aa199d91a73636f95cb059d683e2bf82cf1`,
  REFRESH_TOKEN: `82b5c8f159d3f831ce2c75eb6aaf84063bc2e39b`,
};
export const LOCAL_STORAGE_KEYS = {
  CURRENT_ACCOUNT: `14d0ef6b2eyh3y3yfsds4d1a82d4a698`,
};
